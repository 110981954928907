import React, { useState, useMemo, useEffect } from 'react';
import { DefaultLayout } from '../../../../../components/layouts';
import { Table } from 'antd';
import { useModels, useQuery } from '../../../../../hooks';
import { ViewLoading } from '../../../../../components';

const CilindrosCliente = () => {

  const q = useQuery();
  const idC = q.get("idConcreto");
  const idE = q.get("idEvento");
  const idP = q.get("idProyecto");

  const idConcretoCilindroMemo = useMemo(() => ({ idConcreto: idC, eliminado: null }), [idC]);
  const idConcretoMemo = useMemo(() => ({ id: idC }), [idC]);
  const idProyectoMemo = useMemo(() => ({ id: idP }), [idP]);
	// const idEventoMemo = useMemo(() => ({ id: idE }), [idE]);

  const requestParams = useMemo(() => ({
    name: `cilindro`,
    extraParams: idConcretoCilindroMemo,
    ordenar: 'numeroEspecimen-asc',
    expand: 'usuarioMuestra'
  }), [idConcretoCilindroMemo])

  const [request, setRequest] = useState({})

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    // eslint-disable-next-line no-unused-vars
    modelsPage,
    // eslint-disable-next-line no-unused-vars
    refreshModels
  ] = useModels(request);

  //Eventos
	/* const [
		eventos,
	] = useModels({
		name: 'evento',
		ordenar: 'id-asc',
		limite: -1,
		extraParams: idEventoMemo
	});
 */
	//Proyectos
	const [
		proyectos,
	] = useModels({
		name: 'proyecto',
		ordenar: 'id-asc',
		limite: -1,
		extraParams: idProyectoMemo
	});

  //Concretos
	const [
		concretos,
	] = useModels({
		name: 'concreto',
		ordenar: 'id-asc',
		expand: 'equipo',
		limite: -1,
		extraParams: idConcretoMemo
	});

  const breadcrumb = [
    {
			name: 'Proyectos',
			to: '/proyectos'
		},
		{
			name: proyectos[0]?.nombre,
			to: `/proyectos`
		},
		{
			name: concretos[0]?.folio,
			to: `/proyectos/muestras?idProyecto=${idP}`
		},
		{
			name: 'Cilindros',
			to: `cilindros?idConcreto=${idC}&idEvento=${idE}&idProyecto=${idP}`
		}
  ]

  const columns = [
    {
      title: 'Folio Muestra',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Diametro/Altura',
      key: 'diametroAltura',
      dataIndex: 'diametroAltura',
      render: (_,row) => row?.diametroAltura === 1 ? '10×20' : '15×30'
    },
    {
      title: 'Edad',
			key: 'edad',
			dataIndex: 'edad'
    },
    {
      title: 'Molde',
			key: 'molde',
			dataIndex: 'molde',

    }
  ];

  useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [models, requestParams])

  useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams])

  if (modelsLoading) return <ViewLoading />

  return (
    <>
    <DefaultLayout
      title={'Cilindros de Concreto '+concretos[0]?.folio}
      breadcrumbItems={breadcrumb}
    >
      <Table
        columns={columns}
        dataSource={models}
        loading={modelsLoading}
        rowKey='id'
        scroll={{ x: 'max-content' }}
        size="small"
      />
    </DefaultLayout>
    </>
  );
}

export default CilindrosCliente;