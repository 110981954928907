import React from 'react'
import { Select } from 'antd'

const UsuariosSelect = ({
    setUsuarioValue,
    usuarioValue,
    isDisabled = false,
    isLoading,
    dataUsuario,
    defaultValor
}) => {
    const { Option } = Select;

    const handleChange = (value) => {
        setUsuarioValue(value)
    }

    return (
        <Select
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={isLoading}
            disabled={isDisabled}
            placeholder="Seleccione una usuario"
            style={{ widt: '100%' }}
            onChange={handleChange}
            value={usuarioValue}
            defaultValue={defaultValor}
            filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
        >
            {dataUsuario?.map( item => (
                <Option key={item?.nombre} value={item?.id}>{item?.nombre}</Option>
            ))}
        </Select>
    )
}

export default UsuariosSelect