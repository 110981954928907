import React, { useState } from 'react';
import { DefaultLayout } from '../../components/layouts';
import { Calendar, Badge, Col, Select, Row, Spin } from 'antd';
import { useModels } from '../../hooks';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { useHistory } from 'react-router-dom';

const hoy = new Date();

const PruebasPorFecha = () => {

  const history = useHistory();
  
  const [ request, setRequest ] = useState({});
  const [ mesCalendario, setMesCalendario ] = useState(hoy.getMonth());
  const [ anioCalendario, setAnioCalendario ] = useState(hoy.getFullYear());
  const [ primerDia, setPrimerDia ] = useState(hoy);

  const [
    cilindros,
    cilindrosLoading
  ] = useModels(request);

  const breadcrumb = [
    {
      name: "Pruebas por Fecha",
      to: "/pruebas-por-fecha"
    }
  ];

  const getListData = React.useCallback((value) => {
    let listData = [];
  
    for (let i = 0, l = cilindros.length; i < l; i++) {
      if(moment.utc(cilindros[i]?.fechaPruebaLaboratorio).local().format('YYYY-MM-DD') === value.format('YYYY-MM-DD')){
        listData.push(
          {
            type: 'error',
            content: `${cilindros[i]?.folio}`,
          },
        );
      }
    }
  
    return listData || [];
  }, [cilindros]);

  const dateCellRender = React.useCallback((value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }, [getListData]);

  const requestMes = (fecha) => {
    setPrimerDia(fecha.startOf('month').format('YYYY-MM-DD HH:mm:ss'))
    setMesCalendario(fecha.month());
    setAnioCalendario(fecha.year());
    setRequest({
      name: 'cilindro',
      limite: -1,
      ordenar: 'folio-desc',
      extraParams: {
        estado: 'REGISTRADO',
        inicio: fecha.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        fin: fecha.endOf('month').format('YYYY-MM-DD HH:mm:ss')
      }
    })
  }

  React.useEffect(() => {
    setRequest({
      name: 'cilindro',
      limite: -1,
      ordenar: 'folio-desc',
      extraParams: {
        estado: 'REGISTRADO',
        inicio: moment.utc().local().startOf('month').subtract(6, 'days').format('YYYY-MM-DD HH:mm:ss'),
        fin: moment.utc().local().endOf('month').add(6, 'days').format('YYYY-MM-DD HH:mm:ss')
      }
    });
    return () => {
      setRequest({});
    }
  }, [])

  return (
    <>
      <DefaultLayout
        title='Pruebas por Fecha'
        breadcrumbItems={breadcrumb}
      >
        <Spin
          spinning={cilindrosLoading}
          size='large'
          tip='Obteniendo Cargas...'
        >
          <Calendar
            locale={locale}
            dateCellRender={dateCellRender}
            value={moment(primerDia)}
            onPanelChange={(v) => requestMes(v)}
            onSelect={(v) => history.push(`pruebas-por-fecha/pruebas-dia?fecha=${v}&tab=1`)}
            headerRender={({ value, type, onChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];
    
              const current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current.month(i);
                months.push(localeData.monthsShort(current));
              }
    
              for (let i = start; i < end; i++) {
                monthOptions.push(
                  <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                  </Select.Option>,
                );
              }
    
              const year = value.year();
              const options = [];
              for (let i = 2022; i < year; i ++) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>,
                );
              }
              return (
                <div style={{ padding: 8 }}>
                  <Row gutter={8}>
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        value={anioCalendario}
                        onChange={newYear => {
                          const now = value.clone().year(newYear);
                          onChange(now);
                          setAnioCalendario(now.year());
                        }}
                      >
                        {options}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        value={mesCalendario}
                        onChange={newMonth => {
                          const now = value.clone().month(newMonth);
                          onChange(now);
                          setMesCalendario(now.month());
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </Col>
                  </Row>
                </div>
              );
            }}
          />
        </Spin>
        {/* <Pruebas
          fechas={fechaVencimiento}
        /> */}
      </DefaultLayout>
    </>
  )
}

export default PruebasPorFecha;