const area = [
    {
        id: 0,
        name: "PRUEBAS DE MATERIALES"
    },
    {
        id: 2,
        name: "GEOTECNIA"
    },
    {
        id: 4,
        name: "CONTROL DE CALIDAD"
    },
    {
        id: 6,
        name: "PRUEBAS NO DESTRUCTIVAS"
    },
    {
        id: 8,
        name: "TOPOGRAFÍA"
    },
];

export default area;