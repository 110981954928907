import React from "react";
import { useAlert, useAuth } from "../hooks";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import { Alert } from "antd";

const AppRouting = () => {

  const { open, severity, message } = useAlert();
  const { session, sessionLoading  } = useAuth();

  const AlertMemo = React.useMemo(
    () => open && <Alert type={severity} message={message} closable showIcon />,
    [open, severity, message]
  );

  if (sessionLoading) return null;

  return (
    <>
      {AlertMemo}
      {session ? <PrivateRouter /> : <PublicRouter />}
    </>
  );
};

export default AppRouting;