import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DefaultLayout from "../../components/layouts/DefaultLayout";
import { Modal, Row, Col, Form, Input, Table, Button, Divider, Select, DatePicker, Typography, message, InputNumber, Radio } from 'antd';
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { area } from '../../utilities';
import { useHistory } from 'react-router-dom';
import { useModel, useModels, useQuery, useAuth } from '../../hooks';
import StatusResponse from '../../services/statusResponse';
import { ViewLoading, UsuariosSelect, PlazaSelect } from '../../components';

const ProyectoForm = () => {

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const { Title } = Typography;
  const { user } = useAuth();
  const history = useHistory();

  const q = useQuery();
  const id = q.get("id");
  const editing = !!id;
  const idTecnicoParamsMemo = useMemo(() => ({ rol: 'Técnico Campo' }), [])
  const idClienteParamsMemo = useMemo(() => ({ rol: 'Cliente' }), [])

  const [saveLoading, setSaveLoading] = useState(false);
  const [idUsuarioSeleccionado, setIdUsuarioSeleccionado] = useState(null);
  const [declaracionValue, setDeclaracionValue] = useState(null);
  const [tecnicosTabla, setTecnicosTabla] = useState([])
  const [clienteValue, setClienteValue] = useState('')
  const [plazaValue, setPlazaValue] = useState('')
  const [eliminado, setEliminado] = useState([]);


  //Clientes
  const [
    clientes,
    clientesLoading,
    // eslint-disable-next-line no-unused-vars
    clientesError, clientesPage, refreshClientes
  ] = useModels({
    name: 'usuario',
    extraParams: idClienteParamsMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  //Tecnicos
  const [
    tecnicos,
    tecnicosLoading,
    // eslint-disable-next-line no-unused-vars
    tecnicosError, tecnicosPage, refreshTecnicos
  ] = useModels({
    name: 'usuario',
    extraParams: idTecnicoParamsMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  //Plazas
  const [
    plazas,
    plazasLoading,
    // eslint-disable-next-line no-unused-vars
    plazasError, plazasPage, refreshPlazas
  ] = useModels({
    name: 'plaza',
    ordenar: 'id-asc',
    limite: -1,
  });

  const {
    model,
    modelLoading,
  } = useModel({
    name: "proyecto",
    expand: 'usuarios.usuario',
    id: id,
  })

  const breadcrumb = [
    {
      name: "Proyectos",
      to: "/proyectos"
    },
    {
      name: editing ? "Editando " + model?.nombre : "Crear Nuevo Poyecto",
      to: editing ? `proyecto?id=${id}` : 'nuevo-proyecto'
    },
  ]

  const columnsUser = [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => (
        <Button
          danger
          icon={<CloseOutlined />}
          key={item}
          onClick={() => {
            eliminarTecnico(item)
          }}
        />
      )
    },
    {
      title: 'Usuarios Agregados',
      key: 'usuario',
      dataIndex: 'usuario',
      render: (_, value) => value?.nombre
    },
  ];

  const insertarEnArreglo = () => {

    if (!idUsuarioSeleccionado) {
      message.warning('Selecciona un técnico')
      return
    }
    const _tecnico = tecnicos.find(tecnico => tecnico.id === idUsuarioSeleccionado)
    const _datos = [...tecnicosTabla];

    // Validar que no sea le mismo.
    let existe = false;
    for (let i = 0; i < _datos.length; i++) {
      if (_datos[i]?.id === _tecnico.id) {
        existe = true;
        break;
      }
    }

    if (existe) {
      message.warning('Ya existe este registro')
      return
    }

    _datos.push(_tecnico);
    setTecnicosTabla(_datos)
  };

  const eliminarTecnico = (item) => {
    const _tecnico = tecnicosTabla.find(tecnico => tecnico.id === item?.id)
    setEliminado([...eliminado, { 'idUsuario': _tecnico.id }])
    let _tecnicosTabla = [...tecnicosTabla]

    for (let i = 0; i < _tecnicosTabla.length; i++) {
      if (_tecnicosTabla[i]?.id === _tecnico.id) {
        _tecnicosTabla.splice(i, 1)
        break
      }
    }
    setTecnicosTabla(_tecnicosTabla)
  }

  const setFormData = useCallback((data) => {
    form.setFieldsValue({
      idCliente: data?.idCliente,
      idPlaza: data?.idPlaza,
      nombre: data?.nombre,
      numeroProyecto: data?.numeroProyecto,
      area: data?.area,
      direccion: data?.direccion,
      fechaInicio: data?.fechaInicio,
      fechaFin: data?.fechaFin,
      secuencialConcreto: data?.secuencialConcreto,
      secuencialSuelo: data?.secuencialSuelo,
      secuencialAgregado: data?.secuencialAgregado,
      secuencialMaterial: data?.secuencialMaterial,
      secuencialPavimento: data?.secuencialPavimento,
      folioEvento: data?.folioEvento,
      observaciones: data?.observaciones,
      usuariosProyecto: data?.usuariosProyecto,
      declaracionconformidad: data?.declaracionconformidad,
      incertidumbreresistencia: data?.incertidumbreresistencia,
      incertidumbrerevenimiento: data?.incertidumbrerevenimiento,
      nomeclatura: data?.nomeclatura
    })
  }, [form])

  const onFinish = async (values) => {
    const {
      fechaInicio,
      fechaFin,
    } = values;

    if (tecnicosTabla.length < 1) {
      message.warning('Debes agregar un técnico')
      return
    }

    let _usuariosProyecto = []
    if (editing) {
      const _modeloUsuarios = model?.usuarios

      for (let i = 0; i < tecnicosTabla.length; i++) {
        const usuario = _modeloUsuarios.find(user => user.idUsuario === tecnicosTabla[i]?.id)
        let _obj = {
          'id': (usuario?.id || null),
          'idUsuario': (usuario?.idUsuario ? usuario?.idUsuario : tecnicosTabla[i]?.id),
        }
        _usuariosProyecto.push(_obj)
      }
    }
    else {
      for (let i = 0; i < tecnicosTabla.length; i++) {
        let _obj = { 'id': null, 'idUsuario': tecnicosTabla[i].id }
        _usuariosProyecto.push(_obj)
      }
    }

    try {
      setSaveLoading(true)

      let _body = {
        ...values,
        idCliente: clienteValue,
        idPlaza: plazaValue,
        fechaInicio: moment(fechaInicio.toDate()).format('YYYY-MM-DD HH:mm:ss'),
        fechaFin: moment(fechaFin.toDate()).format('YYYY-MM-DD HH:mm:ss'),
        usuariosProyecto: _usuariosProyecto,
        eliminados: eliminado,
        estatus: "nuevo",
      }

      if (editing) {
        _body.id = id
      }

      const res = await StatusResponse.post("proyecto", _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => { secondsToGo -= 1 }, 100)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push('/proyectos')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning({
      content: 'Verifica que todos los campos estén correctos',
      style: {
        marginTop: '10vh',
      },
    });
    console.log(values, errorFields, outOfDate);
  };

  const insertarUsuario = (_modeloUsuario) => {
    let _usuario = []
    for (let i = 0; i < _modeloUsuario.length; i++) {
      let _obj = _modeloUsuario[i].usuario
      _usuario.push(_obj)
    }
    return _usuario
  }

  useEffect(() => {
    if (editing && model) {
      let _modeloUsuario = model?.usuarios
      let _usuario = insertarUsuario(_modeloUsuario)
      setClienteValue(model?.idCliente)
      setPlazaValue(model?.idPlaza)
      setTecnicosTabla(_usuario)
      setDeclaracionValue(model?.declaracionconformidad)
      setFormData(model)
      form.setFieldsValue({
        fechaInicio: moment(model?.fechaInicio),
        fechaFin: moment(model?.fechaFin),
      })
    }

  }, [editing, form, model, setFormData]);

  if (modelLoading) return <ViewLoading />

  return (
    <>
      <DefaultLayout
        title={model ? model?.nombre : "Proyecto nuevo"}
        breadcrumbItems={breadcrumb}
      >
        <Row>
          <Col>
            <Title level={4}>{model?.folio}</Title>
          </Col>
        </Row>
        <Form
          form={form}
          name="form"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={10}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
              <Form.Item
                name="idCliente"
                label="Cliente"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <UsuariosSelect
                  isDisabled={editing ? true : false}
                  setUsuarioValue={setClienteValue}
                  defaultValor={model?.idCliente}
                  usuarioValue={clienteValue}
                  dataUsuario={clientes}
                  loading={clientesLoading}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
              <Form.Item
                name="idPlaza"
                label="Plaza"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <PlazaSelect
                  setPlazaValue={setPlazaValue}
                  defaultPlaza={model?.idPlaza}
                  plazaValue={plazaValue}
                  dataPlaza={plazas}
                  loading={plazasLoading}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="nombre"
                label="Nombre de Proyecto"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {editing ? (
            <Row gutter={10}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="numeroProyecto"
                  label="Número de Proyecto"
                  style={{
                    fontWeight: 'bold'
                  }}
                >
                  <InputNumber
                    disabled={true}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="area"
                  label="Área"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Select
                    disabled={editing ? true : false}
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {area.map(item => (
                      <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="direccion"
                  label="Dirección"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={10}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="area"
                  label="Área"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Select
                    disabled={editing ? true : false}
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {area.map(item => (
                      <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="direccion"
                  label="Dirección"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="fechaInicio"
                  label="Fecha de Inicio"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <DatePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {editing ? (
            <Row gutter={10}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="fechaInicio"
                  label="Fecha de Inicio"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <DatePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="fechaFin"
                  label="Fecha de Fin"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <DatePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="nomeclatura"
                  label="Nomeclatura"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={10}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="fechaFin"
                  label="Fecha de Fin"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <DatePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="nomeclatura"
                  label="Nomeclatura"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name="secuencialConcreto"
                  label="Secuencial Concreto"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}

          {editing && (
            <>
              <Row gutter={10}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name="secuencialConcreto"
                    label="Secuencial Concreto"
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    <Input disabled={user?.rol === 'Administrador' ? false : true} />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name="secuencialSuelo"
                    label="Secuencial Suelos"
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name="secuencialAgregado"
                    label="Secuencial Agregado"
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={10}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name="secuencialMaterial"
                    label="Secuencial Material"
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name="secuencialPavimento"
                    label="Secuencial Pavimento"
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name="folioEvento"
                    label="Secuencial Evento"
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="declaracionconformidad"
                label="¿Desea que se relice declaración de conformidad?"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Radio.Group onChange={e => setDeclaracionValue(e.target.value)} value={declaracionValue}>
                  <Radio value='SI'>SI</Radio>
                  <Radio value='NO'>NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {declaracionValue === 'SI' && (
              <>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name='incertidumbreresistencia'
                    label='Incertidumbre de Resistencia'
                  >
                    <InputNumber
                      style={{ width: '100% ' }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                >
                  <Form.Item
                    name='incertidumbrerevenimiento'
                    label='Incertidumbre de Reevenimiento'
                  >
                    <InputNumber
                      style={{ width: '100% ' }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>

          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="observaciones"
                label="Comentarios"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginBottom: "9" }} gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
            <Col className="gutter-row">
              <Title level={4} style={{ paddingTop: "9", color: "#111" }}>Técnicos</Title>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                name="tecnicos"
                label="Técnicos Disponibles"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  loading={tecnicosLoading}
                  placeholder="Seleccione un técnico"
                  style={{ widt: '100%' }}
                  onChange={setIdUsuarioSeleccionado}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {tecnicos?.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="&nbsp;">
                <Button onClick={insertarEnArreglo}>
                  +
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={24}>
              <Form.Item name="usuariosProyecto">
                <Table
                  rowKey={"id"}
                  columns={columnsUser}
                  dataSource={tecnicosTabla}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={10}>
            <Col span={9}>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DefaultLayout>
    </>
  );
};

export default ProyectoForm;