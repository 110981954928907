import React from 'react';
import { Form, Row, Col, Input, DatePicker, Space, Tooltip, Button } from 'antd';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const Search = ({
  disabledButtons,
  multipleButtons,
  form,
  loading,
  onClean,
  onSearch
}) => {
  return (
    <Form
      form={form}
      layout='vertical'
    >
      <Row gutter={10}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item
            name='buscar'
            label='Buscar'
          >
            <Input placeholder='Buscar...'/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item
            name='fecha'
            label='Fecha'
          >
            <RangePicker
              format='DD-MM-YYYY'
              placeholder={['Fecha Movimiento', 'Fecha Movimiento']}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={2} lg={2} xl={2} xxl={2}>
          <Space
            justify='space-between'
          >
            <Tooltip title="Buscar">
              <Button
                disabled={disabledButtons}
                icon={<SearchOutlined />}
                loading={loading}
                onClick={onSearch}
                shape="circle"
                style={{ marginTop: '1.5rem' }}
                type="primary"
              />
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                disabled={disabledButtons}
                icon={<ClearOutlined />}
                onClick={onClean}
                shape="circle"
                style={{ marginTop: '1.5rem' }}
                type="default"
              />
            </Tooltip>
          </Space>
        </Col>
        {multipleButtons &&
        multipleButtons.map((item, index) => (
          <Space
            justify='space-between'
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <Button
                tabIndex={index}
                style={{ backgroundColor: item.color, color: item.texColor }}
                onClick={item.to ? item.to : undefined}
                {...item.props}
                icon={item.icon}
                // block
              >
                {item.text}
              </Button>
            </Col>
          </Space>
        ))}
      </Row>
    </Form>
  )
}

export default Search