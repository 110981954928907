import React from "react";
import { Layout, Menu, Row, Col, Dropdown, Button, Typography, Divider, Tooltip } from "antd";
import { Link } from 'react-router-dom';
import { LogoutOutlined, UserOutlined, BankOutlined, CloseOutlined } from "@ant-design/icons";
import { ImOffice, ImLab, ImPencil2 } from 'react-icons/im';
import { NavLink, useLocation } from "react-router-dom";

import { useAuth, useApp } from "../../hooks";
import { dashboardRoutes, clienteRoutes } from "../../routers";
import "../../styles/DashboardLayout.less";

import { useHistory } from "react-router-dom";

import { usePagination } from "../../hooks";

const { Header, Content, Footer, Sider } = Layout;

const rootSubmenuKeys = [""];


const DashboardLayout = ({ children: PanelContent }) => {

  const { reiniciarPaginacion } = usePagination();

  const [state, setState] = React.useState({
    collapsed: false,
  });
  const [openKeys, setOpenKeys] = React.useState([""]);
  const [selectedKey, setSelectedKey] = React.useState("");
  const location = useLocation();
  const { user, userLoading, signOut } = useAuth();
  const { token } = useApp();
  const { Paragraph } = Typography;

  const { SubMenu } = Menu;

  const history = useHistory();

  const rutas = user?.rol === 'Cliente' ? clienteRoutes : dashboardRoutes;

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const sidebarMapper = (route) => {
    if (route.sidebar === "single") {
      return (
        <Menu.Item
          key={route.path}
          onClick={() => setSelectedKey(route.path)}
          icon={route.icon}
        >
          <NavLink to={route.path}>{route.name}</NavLink>
        </Menu.Item>
      );
    } else if (route.sidebar === "collapse") {
      const innerMap = (r) => ({ ...r, path: route.path + r.path });
      const finalKey = "collapse-" + route.path;
      return (
        <SubMenu key={finalKey} icon={route.icon} title={route.name}>
          {route?.routes?.map(innerMap).map(sidebarMapper)}
        </SubMenu>
      );
    }
    return null;
  };

  const { collapsed } = state;

  React.useEffect(() => {
    const flatter = (r) =>
      r?.routes
        ? [
            r,
            ...r?.routes
              .map((sub) => ({ ...sub, path: r.path + sub.path }))
              .flatMap(flatter),
          ]
        : r;
    const flatted = rutas.flatMap(flatter);
    const paths = flatted.map((r) => r.path);
    const key = paths.find((path) => path === location.pathname);
    // TODO: Hay algo extraño con las rutas base, revisar
    // const sub = key.substr(0, key.lastIndexOf("/"));
    // if (paths.some((p) => p.includes(sub))) {
    setSelectedKey(key);
    // }
    const tmpOpenKeys = flatted
      .filter(
        (r) => r.sidebar === "collapse" && location.pathname.includes(r.path)
      )
      .map((r) => "collapse-" + r.path);
    setOpenKeys(tmpOpenKeys);
  }, [location.pathname, rutas]);

  const onCollapse = (collapsed) => {
    setState({ collapsed });
  };

  if (!user && userLoading) return null;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="auth-sider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="light"
        width={236}
      >
        <img src={collapsed ? "/assets/oestec_full_logo.png" : "/assets/oestec_full_logo.png"} style={{padding: 10 }} width={ collapsed ? "200px" : "200px"} alt="Logo" />
        {/* <Row>
          <Col xs={{span: 9}} sm={{span: 6}} md={{span: 5}} lg={{span: 3}}> 
            <img
              src="./assets/oestec_full_logo.png"
              alt="logo"
              style={{ height: 80 }}
            />
          </Col>
        </Row> */}
        
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          className="sider-menu"
          selectedKeys={selectedKey}
          inlineIndent={18}
        >
          {rutas.map(sidebarMapper)}
          <Menu.Item
            key="single-/0"
            icon={<LogoutOutlined />}
            onClick={signOut}
          >
            Cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="space-between" style={{ padding: "0px 10px" }}>
            <Col>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Paragraph copyable={{ text: token }}>{user?.correo}</Paragraph>
                  {/* <strong>{'Usuario: '+user?.nombre}</strong> */}
                </Col>
                <Col>
                  <Divider type="vertical"/>
                </Col>
                <Col>
                  <Tooltip title='Reiniciar Paginaciones'>
                    <CloseOutlined
                      onClick={() => {
                        reiniciarPaginacion();
                        window.location.reload(false);
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Divider type="vertical"/>
                </Col>
                <Col>
                  <div className="ed-vertical-div"></div>
                  {user?.rol === 'Administrador' &&(
                    <Dropdown
                    className="ed-usuario-btn"
                    overlay={
                      <>
                      <Menu>
                        <Link to="/administracion/usuarios" key="1">
                          <Menu.Item icon={<UserOutlined />}>
                            <Button type="text">Usuarios</Button>
                          </Menu.Item>
                        </Link>
                        <Link to="/administracion/empresas" key="2">
                          <Menu.Item icon={<ImOffice />}>
                            <Button type="text">Empresas</Button>
                          </Menu.Item>
                        </Link>
                        <Link to="/administracion/plazas" key="3">
                          <Menu.Item icon={<BankOutlined />}>
                          <Button type="text">Plazas</Button>
                          </Menu.Item>
                        </Link>
                        <Link to="/administracion/laboratorios" key="4">
                          <Menu.Item icon={<ImLab />}>
                          <Button type="text">Laboratorios</Button>
                          </Menu.Item>
                        </Link>
                        <Link to="/administracion/variables" key="5">
                          <Menu.Item icon={<ImPencil2 />}>
                          <Button type="text">Variables</Button>
                          </Menu.Item>
                        </Link>
                      </Menu>
                      </>
                    } 
                    placement="bottom"  
                  >
                    <Button onClick={() => history.push('/administracion')}>
                      Administrador
                    </Button>
                  </Dropdown>
                  )}
                </Col>
                <Col style={{ marginLeft: 10 }}>
                  <Button type="primary" shape="circle" icon={<UserOutlined />} onClick={() => history.push('/perfil')} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content>{PanelContent}</Content>
        <Footer style={{ textAlign: "center" }}>Derechos reservados { new Date().getFullYear() } &#xa9; - v05.12.2022+1</Footer>
      </Layout>
      
    </Layout>
  );
};

export default DashboardLayout;