const diametroAltura = [
    {
        id: 1,
        name: '10×20'
    },
    {
        id: 2,
        name: '15×30'
    }
];

export default diametroAltura