import React from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { emptyRequest, getRequest } from "../constants/requests";
import { useHttp } from "./useHttp";
import { signInWithEmailAndPassword, signOut as signOutFirebase } from 'firebase/auth';
import { auth } from "../services/firebase";
import { Modal } from "antd";
import { useApp } from "./useApp";

const AuthContext = React.createContext();

const empty = emptyRequest();

export function AuthProvider(props) {
  const [session, sessionLoading, sessionError] = useAuthState(auth);
  const [agendaRequest, setAgendaRequest] = React.useState(empty);
  const [agendaResponse, agendaResponseLoading, agendaError] = useHttp(agendaRequest);
  const { token } = useApp();

  const signIn = React.useCallback(async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      if( e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found' ) {
        Modal.warning({
          title: "Error",
          content: 'Usuario/Contraseña son incorrectos.',
        });
      } else if ( e.code === 'auth/invalid-email' ) {
        Modal.warning({
          title: "Error",
          content: 'El correo electrónico no es válido.',
        });
      }
    }
  }, []);

  const signOut = React.useCallback(async () => {
    try {
      await signOutFirebase( auth );
      setAgendaRequest(empty);
    } catch (e) {
      console.error(e);
    }
  }, []);

  React.useEffect(() => {
    if (token) {
      const agendaReq = getRequest("perfil");
      setAgendaRequest(() => agendaReq);
    } else {
      setAgendaRequest(empty);
    }
  }, [token]);

  const memData = React.useMemo(() => {
    return {
      session,
      sessionLoading,
      user:
        agendaResponse?.resultado &&
        agendaResponse?.resultado[0] ? 
          agendaResponse?.resultado[0]
          : null,
      userLoading: agendaResponseLoading,
      userError: agendaError || sessionError,
      signIn,
      signOut,
    };
  }, [
    session,
    sessionLoading,
    sessionError,
    agendaResponse,
    agendaResponseLoading,
    agendaError,
    signIn,
    signOut,
  ]);

  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}