import React from 'react';
import { Row } from 'antd';

const Agregados = () => {

  return (
    <Row>
      Agregados
    </Row>
  );
}

export default Agregados;