import React from 'react';

export function useSortColumns({columns, ordenar, onHeaderCell = {}}) {
  const [ ordenarValue, setOrdenarValue ] = React.useState(ordenar);
  const [ columnas, setColumnas ] = React.useState([]);
  
  const _onHeaderCell = React.useCallback((column) => {
    return {
      onClick: () => {
        let _ordenar = ordenarValue.indexOf("asc") >= 0 ? "desc" : "asc";
        
        setOrdenarValue(`${column?.orden ? column.orden : column.dataIndex}-${_ordenar}`);
      },
    }
  }, [ordenarValue])
  
  React.useEffect(() => {
    
    const columnsDefaultProps = {
      sorter: {multiple: 2},
      sortOrder: ordenarValue.indexOf("asc") >= 0 ? "ascend" : "descend",
      onHeaderCell: _onHeaderCell
    }

    const _columnas = columns?.map(column => {
      column.sortOrder = null
      if (column?.orden){
        if (ordenarValue.indexOf(column?.orden) >= 0){
          column.sortOrder = ordenarValue.indexOf("asc") >= 0 ? "ascend" : "descend"
        }
      } else if (ordenarValue.indexOf(column.dataIndex) >= 0) {
        column.sortOrder = ordenarValue.indexOf("asc") >= 0 ? "ascend" : "descend"
      }
      return {...columnsDefaultProps, ...column}
    })

    setColumnas(_columnas)
  }, [_onHeaderCell, columns, ordenarValue])

  return React.useMemo(() => {
    return {
      ordenarValue,
      columnas
    }
  }, [ordenarValue, columnas]);
}