import React, { useState } from 'react';
import { SimpleTableLayout } from '../../components/layouts';
import { Modal, Table, Form, Row, Col, Checkbox, Button, Spin, message } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx';
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { ActionsButton } from '../../components';
import { useHistory } from 'react-router-dom';
import { useModels, useAuth, usePagination, useSortColumns } from '../../hooks';
import StatusResponse from '../../services/statusResponse';
import { area, renderDateUTC } from '../../utilities';
import { reporteExcel } from '../../utilities/Excel';
import { Search } from './form/Search';

const Proyectos = () => {

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const history = useHistory();
  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const { user } = useAuth();
  const [columns, setColumns] = useState([]);
  const [extraParams, setExtraParams] = useState({});

  const { ordenarValue, columnas } = useSortColumns({columns, ordenar: 'id-desc'});

  const requestParams = React.useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: 'proyecto',
        ordenar: ordenarValue,
        limite: limite,
        pagina: pagina,
        expand: 'cliente,plaza',
        extraParams: extraParams
      }
    }
  }, [extraParams, limite, ordenarValue, pagina])

  const [ request, setRequest ] = useState({});
  const [ proyectoValue, setProyectoValue ] = useState(null);
  const [ visible, setVisible ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ importLoading, setImportLoading ] = useState(false);
  const [ actuales, setActuales ] = useState(0);
  const [ totalCilindros, setTotalCilindros ] = useState(0);

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  const filterArea = (id, row) => {
    return area?.filter(ai => ai?.id === id)[0]?.name
  }

  const breadcrumb = [
    {
      name: "Proyectos",
      to: "/proyectos"
    },
  ]

  const buttonData = [{
    text: 'Proyecto',
    to: () => history.push('/proyectos/nuevo-proyecto'),
    props: { disabled: false, type: 'primary' },
    icon: <PlusOutlined />,
  }];

  const modalDelete = React.useCallback((row) => {
    if (!row?.id) return;
    Modal.confirm({
      title: "Eliminar Proyecto",
      content: `¿Está seguro de eliminar el proeycto "${row?.titulo}"?`,
      icon: <DeleteOutlined style={{ color: '#ff0000' }} />,
      okText: 'Eliminar',
      okType: 'default',
      okButtonProps: {
        type: 'danger',
      },
      onCancel: () => { },
      cancelText: 'Cancelar',
      onOk: async () => {
        const res = await StatusResponse.delete('proyecto', { id: row?.id })
        return new Promise((resolve, reject) => {
          try {
            if (row.id > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'id-desc',
                  pagina: 1,
                  limite: -1
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el proyecto",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      }
    });
  }, [refreshModels])

  
  const columnasExcel = React.useMemo(() => ([
    {
      title: 'Folio',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Fecha Muestreo',
      key: 'muestraHora',
      dataIndex: 'muestraHora',
    },
    {
      title: 'Fecha Esperada de Prueba',
      key: 'fechaPruebaCalculada',
      dataIndex: 'fechaPruebaCalculada'
    }
  ]), [])
  
  const infoToExport = React.useCallback(async (proyecto) => {
    const obj = { idProyecto: proyecto?.id, expand: "concreto" };
    let flag = true;
    let pagina = 1, total = 0, limite = 100;
    let registros = [];
    setImportLoading(true);
    while (flag) {
      obj["pagina"] = pagina;
      obj["limite"] = limite;
      const params = new URLSearchParams(obj).toString();
      const res = await StatusResponse.get(`cilindro?${params}`);
      const resultado = res?.resultado;
      registros = [
        ...registros,
        ...resultado,
      ];
      let _paginacion = res?.paginacion;
      total = _paginacion.total;
      setTotalCilindros(total);
      
      const actual = pagina * limite;
      setActuales(actual);
      pagina = _paginacion?.pagina + 1;
      
      if(total < actual) {
        flag = false;
        setImportLoading(false);
      }
    }
    for (let i = 0, l = registros.length; i < l; i++) {
      registros[i].creado = renderDateUTC(registros[i].creado);
      registros[i].muestraHora = renderDateUTC(registros[i].concreto.muestraHora);
      let fechaPruebaCalculaqda = moment(registros[i].concreto.muestraHora).add(registros[i].edad, 'days').format('YYYY-MM-DD HH:mm:ss');
      registros[i].fechaPruebaCalculada = renderDateUTC(fechaPruebaCalculaqda);
    }
    registros.sort((a, b) => {
      return a.id - b.id;
    });
    return [
      ...registros
    ]
  }, [])

  const descargarExcel = React.useCallback(async (v) => {
    reporteExcel(
      columnasExcel,
      await infoToExport(v),
      `reporte_cilindros_${v?.nomeclatura}`,
      `Cilindros ${v?.nomeclatura}`,
      "Total cilindros",
      "/assets/oestec_full_logo.png",
    )
  }, [columnasExcel, infoToExport])
  
  const columnsContent = React.useMemo(() => [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => {
        if (user?.rol === 'Administrador') {
          return (
            <ActionsButton
              key={item}
              options={[
                {
                  name: 'Editar',
                  onClick: () => history.push(`/proyectos/proyecto?id=${item?.id}`)
                },
                {
                  name: 'Eventos',
                  onClick: () => history.push(`/eventos?idProyecto=${item?.id}`)
                },
                {
                  name: 'Registro Cilindros',
                  onClick: () => {
                    descargarExcel(item);
                  }
                },
                {
                    name: 'Bloqueo',
                    onClick: () => {
                        setProyectoValue(item);
                        setVisible(true);
                    }
                },
                {
                  name: 'Eliminar',
                  onClick: () => modalDelete(item),
                  styleProps: {
                    color: '#f5222d'
                  }
                }
              ]}
            />
          );
        }
        else {
          return (
            <ActionsButton
              key={item}
              options={[
                {
                  name: 'Editar',
                  onClick: () => history.push(`/proyectos/proyecto?id=${item?.id}`)
                },
                {
                  name: 'Eventos',
                  onClick: () => history.push(`/eventos?idProyecto=${item?.id}`)
                },
                {
                  name: 'Registro Cilindros',
                  onClick: () => {
                    descargarExcel(item);
                  }
                },
              ]}
            />
          );
        }
      }
    },
    {
      title: 'Folio',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Nombre del Proyecto',
      key: 'nombre',
      dataIndex: 'nombre',
      orden: 'Proyecto.nombre'
    },
    {
      title: 'Cliente',
      key: 'cliente',
      dataIndex: 'cliente',
      orden: 'Usuario.nombre',
      render: (value) => value.nombre
    },
    {
      title: 'Plaza',
      key: 'plaza',
      dataIndex: 'plaza',
      render: (value) => value.titulo
    },
    {
      title: 'Área',
      key: 'area',
      dataIndex: 'area',
      render: (_, row) => (
        filterArea(row.area, row)
      )
    },
  ], [descargarExcel, history, modalDelete, user?.rol]);

  const onSearch = async (search) => {

    const { buscar } = searchForm.getFieldsValue(true);
    let params = {};
    
    if (buscar){
      params.q = buscar;
    }

    setExtraParams(params);
  };

  const onClear = () => {
    searchForm.resetFields();
    setLimite(10);
    onSearch();
  }

  const onFinish = async (values) => {
    try {
      setSaveLoading(true)

      let _body = {
        id: proyectoValue?.id,
        idusuariolibera: user?.id,
        bloqueado: form.getFieldValue(`bloqueo_proyecto${proyectoValue?.id}`)
      }

      const res = await StatusResponse.post('proyecto', _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        console.log("estatus correcto");
        setVisible(false);
        message.success(res?.mensaje, 2);
        refreshModels()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning({
      content: 'Verifica que todos los campos esten correctos.',
      style: {
        marginTop: '10vh',
      },
    });
  };

  React.useEffect(() => {
    setColumns(columnsContent)
  }, [columnsContent])

  React.useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams])

  React.useEffect(() => {
    if(modelsPage) {
      setTotal(modelsPage.total);
      setLimite(modelsPage.limite);
    }
  }, [modelsPage, setLimite, setTotal])

  return (
    <>
      <Modal
        title={(proyectoValue?.bloqueado || proyectoValue?.bloqueado === null) ? 'Bloquear Proyecto' : 'Liberar Proyecto'}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Form
            form={form}
            name='form'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={10} justify='end'>
              <Col span={6}>
                <Button
                  block
                  onClick={() => setVisible(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={9}>
                <Form.Item>
                  <Button
                    icon={<SaveOutlined />}
                    type='primary'
                    block
                    htmlType='submit'
                    loading={saveLoading}
                  >
                    Guardar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ]}
      >
        <Form
          form={form}
          name='form'
          layout='horizontal'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={20} justify='center'>
            <Col>
              <Form.Item
                name={'bloqueo_proyecto' + proyectoValue?.id}
                label='Bloqueado'
                valuePropName="checked"
                initialValue={proyectoValue?.bloqueado}
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <SimpleTableLayout
        withSearchButton={false}
        breadcrumbItems={breadcrumb}
        title="Proyectos"
        buttonData={buttonData}
        form={searchForm}
        onSearchClicked={onSearch}
        onClearCLicked={onClear}
        children={
          <>
            <Search
              multipleButtons={buttonData}
              disabledButtons={modelsLoading}
              form={searchForm}
              loading={modelsLoading}
              onClean={onClear}
              onSearch={onSearch}
            />
            <Modal
              visible={importLoading}
              footer={null}
              closable={false}
            >
              <Row justify="center">
                <Col>
                  <Spin spinning={importLoading} tip={<>Obteniendo cilindros... <br/> {actuales > totalCilindros ? totalCilindros : actuales} de {totalCilindros}</>} size="large"/>
                </Col>
              </Row>
            </Modal>
            <Table
              columns={columnas}
              dataSource={models}
              loading={modelsLoading}
              pagination={configPagination}
              rowKey='id'
              scroll={{ x: 'max-content' }}
              size="small"
            />
          </>
        }
      />
    </>
  );
}

export default Proyectos;