const timezone = [
    {
        id: 0,
        name: 'America/Tijuana'
    },
    {
        id: 1,
        name: 'America/Hermosillo'
    },
    {
        id: 2,
        name: 'America/Ojinaga'
    },
    {
        id: 3,
        name: 'America/Mazatlan'
    },
    {
        id: 4,
        name: 'America/La_Paz'
    },
    {
        id: 5,
        name: 'America/Mexico_City'
    },
    {
        id: 6,
        name: 'America/Cancun'
    },
];

export default timezone