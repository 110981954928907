import React, { useState, useEffect, useMemo } from 'react';
import { SimpleTableLayout } from '../../components/layouts';
import { useHistory } from 'react-router-dom';
import { ActionsButton } from '../../components';
import { Form, Table, Modal } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useModels, useQuery, useAuth, usePagination, useSortColumns } from '../../hooks';
import StatusResponse from '../../services/statusResponse';
import moment from 'moment';
import { Search } from './form/Search';

const Eventos = ({location}) => {

  const q = useQuery();
  const idP = q.get("idProyecto");

  const history = useHistory();
  const [ searchForm ] = Form.useForm();
  const { user } = useAuth();
  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [columns, setColumns] = useState([]);
  const { ordenarValue, columnas } = useSortColumns({columns, ordenar: 'id-desc'});
  const [ extraParams, setExtraParams ] = useState({ idProyecto: idP });

  const idProyectoMemo = useMemo(() => ({ id: idP }), [idP])

  const requestParams = useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: `evento`,
        extraParams: extraParams,
        ordenar: ordenarValue,
        limite: limite,
        pagina: pagina
      }
    }
  }, [extraParams, limite, ordenarValue, pagina])

  const [request, setRequest] = useState({})

  const defaultText = prop => prop || <span style={{ color: '#c7c3c3' }}>---</span>;

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  //Proyectos
  const [
    proyectos,
    // eslint-disable-next-line no-unused-vars
    proyectosError, proyectosPage, refreshProyectos
  ] = useModels({
    name: 'proyecto',
    ordenar: 'id-asc',
    limite: -1,
    extraParams: idProyectoMemo
  });

  const breadcrumb = [
    {
      name: "Proyectos",
      to: "/proyectos"
    },
    {
      name: proyectos[0]?.nombre,
      to: `/eventos?idProyecto=${idP}`
    },
  ]

  const buttonData = [{
    text: 'Evento',
    to: () => history.push(`eventos/agregar-evento?idProyecto=${idP}`),
    props: { disabled: false, type: 'primary' },
    icon: <PlusOutlined />,
  }];

  const modalDelete = React.useCallback((row) => {
    if (!row?.id) return;
    Modal.confirm({
      title: "Eliminar Evento",
      content: `¿Está seguro de eliminar el evento "${row?.titulo}"?`,
      icon: <DeleteOutlined style={{ color: '#ff0000' }} />,
      okText: 'Eliminar',
      okType: 'default',
      okButtonProps: {
        type: 'danger',
      },
      onCancel: () => { },
      cancelText: 'Cancelar',
      onOk: async () => {
        const res = await StatusResponse.delete('evento', { id: row?.id })
        return new Promise((resolve, reject) => {
          try {
            if (row.id > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'id-desc',
                  pagina: 1,
                  limite: -1
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el evento",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      }
    });
  }, [refreshModels])

  const columnsContent = React.useMemo(() => [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => {
        if (user?.rol === 'Administrador') {
          return (
            <ActionsButton
              key={item}
              options={[
                {
                  name: 'Editar',
                  onClick: () => history.push(`eventos/editar-evento?id=${item.id}&idProyecto=${idP}`)
                },
                {
                  name: 'Muestreos',
                  onClick: () => history.push(`/eventos/muestras?idEvento=${item.id}&idProyecto=${idP}`)
                },
                {
                  name: 'Eliminar',
                  onClick: () => modalDelete(item),
                  styleProps: {
                    color: '#f5222d'
                  }
                },
              ]}
            />
          );
        }
        else {
          return (
            <ActionsButton
              key={item}
              options={[
                {
                  name: 'Editar',
                  onClick: () => history.push(`eventos/editar-evento?id=${item.id}&idProyecto=${idP}`)
                },
                {
                  name: 'Muestreos',
                  onClick: () => history.push(`/eventos/muestras?idEvento=${item.id}&idProyecto=${idP}`)
                }
              ]}
            />
          );
        }
      }
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: defaultText
    },
    {
      title: 'Título Evento',
      key: 'titulo',
      dataIndex: 'titulo',
      render: defaultText
    },
    {
      title: 'Fecha Inicio',
      key: 'fechaInicio',
      dataIndex: 'fechaInicio',
      render: (_, item) => item?.fechaInicio ? moment(item?.fechaInicio).format('DD-MM-YYYY') : <span style={{ color: '#c7c3c3' }}>-----</span>
    },
    {
      title: 'Fecha Fin',
      key: 'fechaFin',
      dataIndex: 'fechaFin',
      render: (_, item) => item?.fechaFin ? moment(item?.fechaFin).format('DD-MM-YYYY') : <span style={{ color: '#c7c3c3' }}>-----</span>
    },
    {
      title: 'Observaciones',
      key: 'observaciones',
      dataIndex: 'observaciones',
      render: defaultText
    },
    {
      title: 'Tipo',
      key: 'tipo',
      dataIndex: 'tipo',
      render: defaultText
    },
  ], [history, idP, modalDelete, user?.rol]);

  const onSearch = async (search) => {

    const { buscar } = searchForm.getFieldsValue(true);
    let params = {idProyecto: extraParams.idProyecto};

    if (buscar) {
      params.q = buscar;
    }

    setExtraParams(params);
  };

  const onClear = () => {
    searchForm.resetFields();
    setLimite(10);
    onSearch();
  }

  useEffect(() => {
    setColumns(columnsContent)
  }, [columnsContent])

  useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams])

  useEffect(() => {
    if(modelsPage) {
      setTotal(modelsPage.total);
      setLimite(modelsPage.limite);
    }
  }, [modelsPage, setLimite, setTotal])

  return (
    <SimpleTableLayout
      withSearchButton={false}
      breadcrumbItems={breadcrumb}
      title={proyectos[0]?.nombre}
      children={
        <>
          <Search
            multipleButtons={buttonData}
            disabledButtons={modelsLoading}
            form={searchForm}
            loading={modelsLoading}
            onClean={onClear}
            onSearch={onSearch}
          />
          <Table
            columns={columnas}
            dataSource={models}
            loading={modelsLoading}
            pagination={configPagination}
            rowKey='id'
            scroll={{ x: 'max-content' }}
            size="small"
          />
        </>
      }
    />
  );
}

export default Eventos;