import React from 'react'
import { Select } from 'antd'

const EmpresasSelect = ({
    setEmpresaValue,
    empresaValue,
    isDisabled = false,
    isLoading,
    dataEmpresa
}) => {
    const {Option} = Select;

    const handleChange = (value) => {
        setEmpresaValue(value)
    }

    return (
        <Select
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={isLoading}
            disabled={isDisabled}
            placeholder="Selecciona una empresa"
            style={{ width: '100%' }}
            onChange={handleChange}
            defaultValue={empresaValue}
            filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
        >
            {dataEmpresa?.map( item => (
                <Option key={item?.titulo} value={item?.id}>{item?.titulo}</Option>
            ))}
        </Select>
    )
}

export default EmpresasSelect