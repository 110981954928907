import React, { useState } from 'react';
import { DefaultLayout } from '../../components/layouts';
import { Card, Form, Row, Col, Divider, Tabs, Avatar, Input, Button, Modal, Upload, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useModel, useAuth } from '../../hooks';
import StatusResponse from '../../services/statusResponse';
import { ViewLoading } from '../../components';

const Perfil = () => {

  const [ form_perfil ] = Form.useForm();
  const [ form_clave ] = Form.useForm();
  const { user } = useAuth();
  const { TabPane } = Tabs;

  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ listaArchivos, setListaArchivos ] = useState([]);
  const [ archivoLoading, setArchivoLoading ] = useState(false);

  const {
    model,
    modelLoading,
    // eslint-disable-next-line no-unused-vars
    modelError,
    refreshModel,
  } = useModel({
    name: 'perfil',
    id: user?.id
  });

  const setFormData = React.useCallback((data) => {
    form_perfil.setFieldsValue({
      nombre: data?.nombre,
      correo: data?.correo,
      direccion: data?.direccion,
      rfc: data?.rfc,
      telefono: data?.telefono,
      celular: data?.celular
    })
  }, [form_perfil]);

  const subirFirma = async (file, idDocumento) => {
    try {
      setArchivoLoading(true);

      let _archivo = file[0]?.originFileObj;

      if(!_archivo) {
        message.info({
          content: 'Debes de seleccionar un archivo',
          style: {marginTop: '20vh'},
        });
        return
      }

      const isImage = _archivo.type.includes('image/');

      if (!isImage) {
        message.error(`${_archivo.name} no es un archivo de Imagen`);
      }

      const form = new FormData();
      form.append('archivo', _archivo);
      form.append('idUsuario', user?.id);

      const res = await StatusResponse.postFormData('usuario/cambiar-firma', form);
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => { secondsToGo -= 1 }, 100)
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
          // window.location.reload(false);
        }, secondsToGo * 1000)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setArchivoLoading(false)
    }
  }

  const onChangeFirma = ({fileList: newFileList}) => {
    // let _archivo = newFileList[0]?.originFileObj;

    
    if(newFileList.length > 1){
      newFileList.splice(0, 1);
    }
    setListaArchivos(newFileList);
  }

  const onFinishPerfil = async values => {

    try {
      setSaveLoading(true)

      let _body = {
        ...values,
        id: user?.id,
      }

      const res = await StatusResponse.post("usuario", _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => { secondsToGo -= 1 }, 100)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
      }
    } catch (error) {
      console.log(error)
    } finally {
      refreshModel();
      setSaveLoading(false)
    }
  };

  const onFinishClave = async values => {
    const {
      password,
      confirmarClave
    } = values
    try {
      setSaveLoading(true)
      if (password && confirmarClave) {
        if (confirmarClave !== password) {
          Modal.warning({
            title: "Error",
            content: "Las contraseñas no coinciden. Intente de nuevo."
          });
          return;
        }
        const res = await StatusResponse.post("usuario/cambiar-clave", {id: user?.id, clave: password})
        if (res?.status === 400 && res?.errores !== null) {
          const newArray = Object.values(res?.errores)
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>
                {
                  newArray.map((m, i) =>
                    <span key={(i + 1)}>- {m}</span>
                  )
                }
              </div>
            )
          })
        }
        else if (res?.status === 400 && res?.errores === null) {
          Modal.error({
            title: res?.mensaje
          })
        }
        else if (res?.status === 200) {
          form_clave.setFieldsValue({
            password: null,
            confirmarClave: null
          });
          let secondsToGo = 2
          const modal = Modal.success({
            title: res?.mensaje,
            cancelButtonProps: { style: { display: 'none' } },
            okButtonProps: { style: { display: 'none' } }
          })
          const timer = setInterval(() => { secondsToGo -= 1 }, 100)
          setTimeout(() => {
            clearInterval(timer)
            modal.destroy()
          }, secondsToGo * 1000)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  React.useEffect(() => {
    if(model){
      setFormData(model);
    }
  }, [model, setFormData]);

  if (modelLoading) return <ViewLoading />

  return (
    <>
      <DefaultLayout
        nameClass={false}
        breadcrumbDisable={true}
        title='Perfil'
      >
        <Row gutter={10}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
          >
            <Card>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar size={100} icon={<UserOutlined />}/>
                <br />
                <h3>{model?.nombre}</h3>
              </div>
              
              <Divider />

              <span><h4>RFC.</h4>{model?.rfc}</span>
              <span><h4>CORREO.</h4>{model?.correo}</span>
              <span><h4>TEL.</h4>{model?.telefono}</span>
              <span><h4>CEL.</h4>{model?.celular}</span>

              <Divider />
              <Col
                span={24}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}
              >
                <span><h4>Firma Actual</h4></span>
                <Upload
                  action={`${user?.firma}`}
                  beforeUpload={() => false}
                  // beforeUpload={beforeUpload}
                  multiple={false}
                  listType="picture-card"
                  fileList={listaArchivos}
                  onChange={onChangeFirma}
                  // onChange={handleChange}
                  loading={archivoLoading}
                  style={{ width: "100%", height: "100%"}}
                  showUploadList={{ showPreviewIcon: false }}
                >
                  {listaArchivos.length < 1 && user?.firma ? <img src={user?.firma} style={{ width: "100%" }} alt="firma"/> : 'Subir firma'}
                </Upload>
              </Col>
              <Col span={24}>
                <Button
                  loading={archivoLoading}
                  onClick={() => subirFirma(listaArchivos)}
                  style={{ width: "100%" }}
                  type="primary"
                >
                  Actualizar Firma
                </Button>
              </Col>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 16 }}
            lg={{ span: 18 }}
          >
            <Tabs defaultActiveKey='1'>
              <TabPane key='1' tab='Actualizar Perfil'>
                <Form
                  form={form_perfil}
                  name='form_perfil'
                  layout='vertical'
                  onFinish={onFinishPerfil}
                >
                  <Row gutter={10}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item name='nombre' label='Nombre'>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item name='correo' label='Correo'>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item name='direccion' label='Dirección'>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item name='rfc' label='RFC'>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item name='telefono' label='Teléfono'>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <Form.Item name='celular' label='Celular'>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  
                  <Form.Item>
                    <Button 
                      style={{float: "right"}} 
                      type="primary" 
                      htmlType="submit" 
                      loading={saveLoading}
                    >
                      ACTUALIZAR
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane key='2' tab='Cambio de Contraseña'>
                <Form
                  form={form_clave}
                  name='form_clave'
                  layout='vertical'
                  onFinish={onFinishClave}
                >
                  <Row gutter={10}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        name='password'
                        label='Contraseña'
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        name='confirmarClave'
                        label='Confirmar Contraseña'
                        rules={[{
                          required: true,
                          message: 'Este campo es obligatorio.'
                        }]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button 
                      style={{float: "right"}} 
                      type="primary" 
                      htmlType="submit" 
                      loading={saveLoading}
                    >
                      ACTUALIZAR
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </DefaultLayout>
    </>
  );
}

export default Perfil