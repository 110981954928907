import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import imageToBase64 from 'image-to-base64/browser';

const obtenerExtensionImagen = (path) => {
  const ext = path.split('.').pop();
  return ext.toLowerCase();
}

export const reporteExcel = async (cols, data, nombre = "archivo-excel", titulo = "", subtitulo = "", path = null) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(titulo);  // Título de la hoja de cálculo 

    if (path !== null && typeof path === 'string') {
      const img64 = await imageToBase64(path);
      const idImagen = workbook.addImage({
        base64: img64,
        extension: obtenerExtensionImagen(path),  // * jpg, gif, png
      });
      worksheet.addImage(idImagen, {  // * Aquí se acomoda la imagen
        tl: { col: 0.2, row: 0.2 }, // * midpoints
        ext: { width: 200, height: 70 },
      });
    }

    const header = cols?.map(c => (c.title));

    worksheet.columns = cols

    const styleTitle = { // * estilo para el título
      font: {
        bold: true,
        size: 18,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      },
    };
    const styleSub = { // * estilo para el título
      font: {
        bold: true,
        size: 12,
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      },
    };
    const rowHeaderStyle = { // * estilo para el título
      font: {
        bold: true,
        size: 12,
        color: { argb: 'FFFFFFFF' }
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        bgColor: { argb: 'FFFFFFFF' },
        fgColor: { argb: 'FF340909' }
      }
    };
    worksheet.mergeCells('A1:C3');  // * combinar celdas  (lugar imagen)
    // worksheet.mergeCells("A5:F5");  // * juntar celdas para título ()titulo
    // worksheet.mergeCells("A6:F6");  // * juntar celdas para título ()titulo

    const colA = worksheet.getColumn("A");
    colA.width = 15;
    const colB = worksheet.getColumn("B");
    colB.width = 15;
    const colC = worksheet.getColumn("C");
    colC.width = 15;

    // * Despues de mergeCells se debe aplicar estilos y valores  
    worksheet.getCell('D1').value = titulo; // * valor de la celda que se combinará
    worksheet.getCell('D1').style = styleTitle; // * estilo de la celda que se combinará
    worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'left' }; // * alineación de la celda que se combinará

    // * Despues de mergeCells se debe aplicar estilos y valores  
    worksheet.getCell('D2').value = subtitulo; // * valor de la celda que se combinará
    worksheet.getCell('D2').style = styleSub; // * estilo de la celda que se combinará
    worksheet.getCell('D2').alignment = { vertical: 'middle', horizontal: 'left' }; // * alineación de la celda que se combinará
    worksheet.addRow([]);
    worksheet.addRow(header);

    let letras = "ABCDEFGHIJKLMNOPQRSTUV"
    for (let i = 0; i < header.length; i++) {
      worksheet.getCell(`${letras.charAt(i)}5`).style = rowHeaderStyle

    }
    // row.font = rowHeaderStyle.font
    // row.alignment = rowHeaderStyle.alignment

    for (let i = 0; i < data?.length; i++) { // * agregar datos (contenido)
      const row = data[i];
      worksheet.addRow(row);
    }

    // * Este forEach es para agregar width a las columnas dependiendo de su contenido
    worksheet.columns.forEach((column) => {
      var dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        var columnLength = cell.value?.length;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      })
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${nombre}.xlsx`);
    });
  } catch (error) {
    console.log(error);
  }
}