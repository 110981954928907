const ubicacion = [
    {
        id: 1,
        name: 'MANZANA/LOTE'
    },
    {
        id: 2,
        name: 'CADENAMIENTO'
    },
    {
        id: 3,
        name: 'COORDENADA'
    },
    {
        id: 4,
        name: 'COORDENADA GPS'
    },
    {
        id: 5,
        name: 'ABIERTA'
    },
];

export default ubicacion;