import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionsButton } from '../../../../../components';
import { Form, Table, Modal, Descriptions } from 'antd';
import { useModels, useQuery, usePagination, useModel, useSortColumns } from '../../../../../hooks';
import { renderDateUTC } from '../../../../../utilities';
import { Search } from './form';
import moment from 'moment';

const Concreto = () => {

  const [searchForm] = Form.useForm();
  const history = useHistory();
  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [ searchLoading, setSearchLoading ] = useState(false);
  const [ columns, setColumns ] = useState([]);

  const { ordenarValue, columnas } = useSortColumns({columns, ordenar: 'id-desc'});

  const { REACT_APP_PDF_URL: pdfURL } = process.env;

  const q = useQuery();
  const idP = q.get("idProyecto");

  const requestParams = useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: `concreto`,
        extraParams: {
          idProyecto: idP
        },
        ordenar: ordenarValue,
        limite: limite,
        pagina: pagina,
        expand: 'usuarioMuestra'
      }
    }
  }, [idP, limite, pagina, ordenarValue])

  const [request, setRequest] = useState({})

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  const {
    model,
    // eslint-disable-next-line no-unused-vars
    modelLoading,
} = useModel({
    name: "proyecto",
    id: idP,
})

  const columnsContent = useMemo(() => [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item}
          options={[
            {
              name: 'Ver Detalle',
              onClick: () => modalInfo(item)
            },
            {
              name: 'Cilindros',
              onClick: () => history.push(`muestras/cilindros?idConcreto=${item?.id}&idEvento=${item?.idEvento}&idProyecto=${idP}`)
            },
            {
              name: 'Reporte Laboratorio',
              onClick: () => {
                if (model?.bloqueado === true) {
                  window.open(`${pdfURL}registro-laboratorio?idConcreto=${item?.id}&idProyecto=${idP}`,"_blank")
                } else if (model?.bloqueado === false) {
                  window.open(`${pdfURL}reporte-final?idConcreto=${item?.id}&idProyecto=${idP}`,"_blank")
                } else if (model?.bloqueado === null) {
                  window.open(`${pdfURL}reporte-final?idConcreto=${item?.id}&idProyecto=${idP}`,"_blank")
                }
              }
            }
          ]}
        />
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Fecha Muestreo',
      key: 'muestraHora',
      dataIndex: 'muestraHora',
      render: (_, item) => renderDateUTC(item?.muestraHora)
    },
    {
      title: 'Folio Muestra',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Usuario Muestra',
      key: 'usuarioMuestra',
      dataIndex: 'usuarioMuestra',
      render: (value) => value?.nombre
    },
    {
      title: 'Remisión',
      key: 'remision',
      dataIndex: 'remision'
    },
  ], [history, idP, model?.bloqueado, pdfURL]);

  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          bordered
          column={2}
          size="small"
          title=""
        >
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Ubicación">{row?.ubicacion}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Detalle Ubicación">{row?.titulo}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Resistencia de Diseño">{row?.resistenciaDisenoFC}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Edad">{row?.edad}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Remisión">{row?.remision}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Revenimiento de Diseño (cm)">{row?.revenimientoDiseno}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Margen de Revenimiento">{row?.reveminientoDisenoMargen}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Proveedor">{row?.proveedor}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Ingeniero">{row?.ingeniero}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: "bold"}} label="Contratista">{row?.contratista}</Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const modalInfo = (row) => {
    Modal.confirm({
      title: "Muestra de Concreto "+row.folio,
      icon: null,
      content: <ContentModal row={row}/>,
      okText: "Ok",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { primary: true },
      closable: true,
      width: 1000
    });
  };

  const onSearch = React.useCallback(async (v) => {
    setSearchLoading(true);
    const { buscar, fecha } = searchForm.getFieldsValue(true);
    let params = {};
    if (fecha && fecha[0] && fecha[0]) {
      params.inicio = moment(fecha[0].format("YYYY-MM-DD 00:00:00")).utc().format('YYYY-MM-DD HH:mm:ss');
      params.fin = moment(fecha[1].format("YYYY-MM-DD 23:59:59")).utc().format('YYYY-MM-DD HH:mm:ss');
    }
    if(buscar) {
      params.q = buscar;
    }

    await refreshModels(true, { ...params });
    setSearchLoading(false)
  }, [refreshModels, searchForm])

  const onClean = () => {
    searchForm.resetFields();
    onSearch();
  }

  useEffect(() => {
    setColumns(columnsContent)
  }, [columnsContent])

  useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [models, requestParams])

  useEffect(() => {
    if(modelsPage) {
      setTotal(modelsPage.total);
      setLimite(modelsPage.limite);
    }
  }, [modelsPage, setLimite, setTotal])

  return (
    <>
      <Search
        disabledButtons={modelsLoading}
        form={searchForm}
        loading={searchLoading}
        onClean={onClean}
        onSearch={onSearch}
      />
      <Table
        columns={columnas}
        dataSource={models}
        loading={modelsLoading}
        pagination={configPagination}
        rowKey='id'
        scroll={{ x: 'max-content' }}
        size="small"
      />
    </>
  );
}

export default Concreto;