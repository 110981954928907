import { signOut } from "@firebase/auth";
import { auth } from "../services/firebase";

import { HomeOutlined, FolderOutlined, ContainerOutlined, ExperimentOutlined } from "@ant-design/icons";

//import HomeView from "../views/home/Home";
import NotAuthorizedView from "../views/shared/NotAuthorized";
import NotFoundView from "../views/shared/NotFound";

import {
  Proyectos,
  ProyectoForm,
} from "../views/proyectos";

import RegistroMuestras from "../views/registroMuestras/RegistroMuestras";

import {
  Eventos,
  EventoForm,
} from "../views/eventos";

import Muestreo from "../views/eventos/muestreos/Muestreo";

import ConcretoForm from "../views/eventos/muestreos/muestras/forms/ConcretoForm";

import PruebasCampo from "../views/eventos/muestreos/muestras/forms/PruebasCampo";

import PruebasLaboratorio from "../views/pruebasLaboratorio/PruebasLaboratorio";
import Pruebas from "../views/pruebasLaboratorio/pruebas/Pruebas";

import Cilindros from "../views/eventos/muestreos/muestras/forms/cilindros/Cinlindros";

import PruebasPorFecha from "../views/pruebasLaboratorio/PruebasPorFecha";

import { PruebasDia } from "../views/pruebasLaboratorio/form/pruebas";

import {
  PruebasLaboratorioJuntas,
  TablaPruebas,
  PruebaCilindro
} from "../views/pruebasLaboratorioUnificado";

import {
  Inicio,
  Empresas,
  EmpresaForm,
  Plazas,
  PlazaForm,
  Laboratorios,
  LaboratorioForm,
  Variables,
  VariablesForm,
  Usuarios,
  UsuarioForm
} from "../views/admin"

import { Perfil } from "../views/perfil";

import { ProyectosCliente } from "../views/cliente";

import MuestreoClientes from "../views/cliente/eventos/muestreos/MuestreoClientes";

import CilindrosCliente from "../views/cliente/eventos/muestreos/muestras/CilindrosCliente";

import { Redirect } from "react-router-dom";

const singOutRoute = () => {   
  signOut( auth );
  return( "Cargando..." )
}

const sharedRoutes = [
  {
    path: "/no-autorizado",
    component: NotAuthorizedView,
  },
  {
    path: "/salir",
    component: singOutRoute,
  },
  {
    path: "*",
    component: NotFoundView,
  },
];

const redirectProyectos = () => {
  return <Redirect to={'/proyectos'}/>
}

const dashboardRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <HomeOutlined />,
    sidebar: "single",
    component: redirectProyectos,
  },
  {
    layout: "dashboard",
    path: "/proyectos",
    name: "Proyectos",
    icon: <FolderOutlined />,
    sidebar: "single",
    routes: [
      {
        path: "/",
        component: Proyectos,
      },
      {
        path: "/nuevo-proyecto",
        component: ProyectoForm,
      },
      {
        path: "/proyecto",
        component: ProyectoForm,
      }
    ]
  },
  {
    layout: "dashboard",
    path: "/registro-de-muestras",
    name: "Registro de Muestras",
    icon: <ContainerOutlined />,
    sidebar: "single",
    component: RegistroMuestras,
  },
  {
    layout: "dashboard",
    path: "/pruebas-de-laboratorio",
    name: "Pruebas de Laboratorio",
    icon: <ExperimentOutlined />,
    sidebar: "single",
    routes: [
      {
        path: '/',
        component: PruebasLaboratorio,
      },
      {
        path: '/pruebas',
        component: Pruebas
      },
    ]
  },
  {
    layout: "dashboard",
    path: "/pruebas-por-fecha",
    name: "Pruebas por Fecha",
    icon: <ExperimentOutlined />,
    sidebar: "single",
    routes: [
      {
        path: '/',
        component: PruebasPorFecha,
      },
      {
        path: '/pruebas-dia',
        component: PruebasDia
      }
    ]
  },
  {
    layout: "dashboard",
    path: "/pruebas-laboratorio-unidas",
    name: "Pruebas Laboratorio Unidas",
    icon: <ExperimentOutlined />,
    sidebar: "single",
    routes: [
      {
        path: '/',
        component: PruebasLaboratorioJuntas,
      },
      {
        path: '/pruebas',
        component: TablaPruebas
      },
      {
        path: '/prueba-cilindro',
        component: PruebaCilindro
      },
    ]
  },
  {
    layout: "dashboard",
    path: "/eventos",
    routes: [
      {
        path: '/',
        component: Eventos,
      },
      {
        path: "/agregar-evento",
        component: EventoForm,
      },
      {
        path: "/editar-evento",
        component: EventoForm,
      },
      {
        path: '/muestras',
        routes: [
          {
            path: '/',
            component: Muestreo,
          },
          {
            path: '/nuevo-concreto',
            component: ConcretoForm
          },
          {
            path: '/concreto',
            component: ConcretoForm
          },
          {
            path: '/pruebas-de-campo',
            component: PruebasCampo
          },
          {
            path: '/cilindros',
            component: Cilindros
          }
        ]
      }
    ]
  },
  {
    layout: "dashboard",
    path: "/administracion",
    routes: [
      {
        path: '/',
        component: Inicio,
      },
      {
        path: "/usuarios",
        routes: [
          {
            path: '/',
            component: Usuarios,
          },
          {
            path: '/nuevo-usuario',
            component: UsuarioForm,
          },
          {
            path: '/usuario',
            component: UsuarioForm,
          },
        ]
      },
      {
        path: '/empresas',
        routes: [
          {
            path: '/',
            component: Empresas
          },
          {
            path: '/nueva-empresa',
            component: EmpresaForm
          },
          {
            path: '/empresa',
            component: EmpresaForm
          },
        ]
      },
      {
        path: '/plazas',
        routes: [
          {
            path: '/',
            component: Plazas
          },
          {
            path: '/nueva-plaza',
            component: PlazaForm
          },
          {
            path: '/plaza',
            component: PlazaForm
          },
        ]
      },
      {
        path: '/laboratorios',
        routes: [
          {
            path: '/',
            component: Laboratorios,
          },
          {
            path: '/nuevo-laboratorio',
            component: LaboratorioForm,
          },
          {
            path: "/laboratorio",
            component: LaboratorioForm,
          },
        ]
      },
      {
        path: "/variables",
        routes: [
          {
            path: "/",
            component: Variables,
          },
          {
            path: "/nueva-variable",
            component: VariablesForm,
          },
          {
            path: "/variable",
            component: VariablesForm,
          },
        ]
      },
    ]
  },
  {
    layout: "dashboard",
    path: "/cliente",
    component: ProyectosCliente
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: Perfil,
  },
  ...sharedRoutes,
];

const clienteRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <HomeOutlined />,
    sidebar: "single",
    component: redirectProyectos,
  },
  {
    layout: "dashboard",
    path: "/proyectos",
    name: "Proyectos",
    icon: <FolderOutlined />,
    sidebar: "single",
    routes: [
      {
        path: "/",
        component: ProyectosCliente,
      },
      {
        path: '/muestras',
        routes: [
          {
            path: '/',
            component: MuestreoClientes,
          },
          {
            path: '/cilindros',
            component: CilindrosCliente,
          }
        ]
      },
    ]
  },
  ...sharedRoutes,
];

const publicRoutes = [...sharedRoutes];

export { dashboardRoutes, clienteRoutes, publicRoutes };