import React, { useState, useMemo, useEffect } from 'react';
import { DefaultLayout } from '../../components/layouts';
import {
  Form,
  Row,
  Col,
  InputNumber,
  Select,
  Radio,
  Typography,
  Divider,
  TimePicker,
  Input,
  Button,
  Modal,
  message
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useModel, useQuery, useModels } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { ViewLoading } from '../../components';
import StatusResponse from '../../services/statusResponse';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { fallas } from '../../utilities';

const PruebaCilindro = () => {

  const q = useQuery();
  const id = q.get('id');
  const idP = q.get('idProyecto');
  const history = useHistory();
  const [form] = Form.useForm();

  let _diametroA = 0;
  let _diametroB = 0;

  const { Title } = Typography;
  const { Option } = Select;

  const [ request, setRequest ] = useState({});
  const [ cabeceoValue, setCabeceoValue ] = useState('');
  const [ idPlaza, setIdPlaza ] = useState(null);
  const [ saveLoading, setSaveLoading ] = useState(false);

  const idPadreParams = useMemo(() => ({ idPadre: 6 }), []);
  const idProyectoParams = useMemo(() => ({ id: idP }), [idP]);
  const tecnicoParamsMemo = useMemo(() => ({ rol: 'Técnico Laboratorio', idPlaza: idPlaza}), [idPlaza])
  const requestParams = useMemo(() => ({
    name: 'cilindro',
    id: id,
    expand: 'concreto'
  }), [id]);

  const {
    model,
    modelLoading,
  } = useModel(request);

  //variables
  const [
    variables,
  ] = useModels({
    name: 'variable',
    extraParams: idPadreParams,
    ordenar: 'id-asc',
    limite: -1
  });

  // Proyecto[0]
  const [
    proyectos,
  ] = useModels({
    name: 'proyecto',
    extraParams: idProyectoParams,
    limite: 1
  });

  //Tecnicos
  const [
    tecnicos,
    // eslint-disable-next-line no-unused-vars
    tecnicosLoading, tecnicosError, tecnicosPage, refreshTecnicos
  ] = useModels({
    name: 'usuario',
    extraParams: tecnicoParamsMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  const breadcrumb = [
    {
      name: "Pruebas de laboratorio",
      to: "/pruebas-laboratorio-unidas"
    },
    {
      name: proyectos[0]?.folio + ' ' + proyectos[0]?.nombre,
      to: `pruebas?idProyecto=${idP}`
    },
    {
      name: `Pruebas Cilindro ${model?.folio}`,
      to: `prueba-cilindro?idProyecto=${idP}&id=${id}`
    }
  ]

  const calcularArea = (valor, tipoDiametro) => {
    let _area = 0
        if (tipoDiametro) {
          _diametroA = valor
        }
        else {
          _diametroB = valor;
        }

          if (_diametroA !== undefined &&
            _diametroA !== null &&
            _diametroB !== undefined &&
            _diametroB !== null) {
            let area = parseFloat((_diametroA + _diametroB) / 2)
            _area = parseFloat((((area * area) * 3.14159265359) / 4).toFixed(2));
          }
      
    
    form.setFieldsValue({
      area: _area
    })
  }

  const calcularEspesorProm = () => {
    let promedio = 
      (parseFloat(form.getFieldValue(`espesor1`)) || 0) + 
      (parseFloat(form.getFieldValue(`espesor2`)) || 0) + 
      (parseFloat(form.getFieldValue(`espesor3`)) || 0) + 
      (parseFloat(form.getFieldValue(`espesor4`)) || 0) + 
      (parseFloat(form.getFieldValue(`espesor5`)) || 0) + 
      (parseFloat(form.getFieldValue(`espesor6`)) || 0);
    promedio = promedio / 6;
    form.setFieldsValue({ espesorPromedio: promedio })
  }

  const valorarHoraEnsayo = (_hora) => {
    switch (model?.edad) {
      case 1:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(model?.creado).subtract(30, 'minutes').add(1, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(model?.creado).add(30, 'minutes').add(1, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${model?.folio}. Hora de ensayo dentro de margen de tolerancia.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(model?.creado).format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(model?.creado).subtract(30, 'minutess').add(1, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).format('YYYY-MM-DD HH:mm') > moment(model?.creado).format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(model?.creado).subtract(30, 'minutes').add(1, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 3:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(model?.creado).subtract(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(model?.creado).add(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${model?.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(model?.creado).subtract(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(model?.creado).subtract(2, 'hours').add(3, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).format('YYYY-MM-DD HH:mm') > moment(model?.creado).subtract(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(model?.creado).subtract(2, 'hours').add(3, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 7:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(model?.creado).subtract(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(model?.creado).add(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${model?.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(model?.creado).subtract(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(model?.creado).subtract(6, 'hours').add(7, 'days').diff(moment(_hora), 'minutes');
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).format('YYYY-MM-DD HH:mm') > moment(model?.creado).subtract(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(model?.creado).subtract(6, 'hours').add(7, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 14:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(model?.creado).subtract(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(model?.creado).add(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${model?.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(model?.creado).subtract(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(model?.creado).subtract(12, 'hours').add(14, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).add(11, 'days').format('YYYY-MM-DD HH:mm') > moment(model?.creado).subtract(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(model?.creado).subtract(12, 'hours').add(14, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 28:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(model?.creado).subtract(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(model?.creado).add(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${model?.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(model?.creado).subtract(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(model?.creado).subtract(20, 'hours').add(28, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).add(11, 'days').format('YYYY-MM-DD HH:mm') > moment(model?.creado).subtract(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(model?.creado).subtract(20, 'hours').add(28, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 90:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(model?.creado).subtract(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(model?.creado).add(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${model?.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(model?.creado).subtract(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(model?.creado).subtract(2, 'days').add(90, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).add(11, 'days').format('YYYY-MM-DD HH:mm') > moment(model?.creado).subtract(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(model?.creado).subtract(2, 'days').add(90, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${model?.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      default:
        break;
    }
  }

  const calcularCargaMaximaYResistencia = (valor) => {
    form.setFieldsValue({
      cargaMaxima: ((valor * 1000) * form.getFieldValue(`factorCorreccion`))
    });
    form.setFieldsValue({
      cargaMaximaN: parseFloat(((form.getFieldValue(`cargaMaxima`)) * 9.8066).toFixed(0)),
      resistencia: parseFloat((form.getFieldValue(`cargaMaxima`) / form.getFieldValue('area')).toFixed(1))
    });
    form.setFieldsValue({
      resistenciaMPa: parseFloat(((form.getFieldValue(`resistencia`)) * 0.098066).toFixed(1))
    });
  }

  const onFinish = async values => {
    const {
      horaEnsayo
    } = values;

    try {
      setSaveLoading(true)

      let _body = {
        ...values,
        id: id,
        horaEnsayo: moment.utc(horaEnsayo).format('YYYY-MM-DD HH:mm:ss')
      }

      const res = await StatusResponse.post('pruebas-laboratorio/pruebas-unidas', _body);
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => { secondsToGo -= 1 }, 100)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push(`/pruebas-laboratorio-unidas/pruebas?idProyecto=${idP}`)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSaveLoading(false);
    }
  }

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning({
      content: 'Verifica que todos los campos estén correctos',
      style: {
        marginTop: '10vh',
      },
    });
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    if(proyectos.length > 0){
      setIdPlaza(proyectos[0]?.idPlaza);
    }
  }, [proyectos])

  useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams])

  if (modelLoading) return (<ViewLoading />);

  return (
    <>
      <DefaultLayout
        title={`${proyectos[0]?.nombre}. Cilindro ${model?.folio}`}
        breadcrumbItems={breadcrumb}
      >
        <Form
          form={form}
          name='form'
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={10}>
            <Title level={4}>{`${proyectos[0]?.nombre}. Cilindro ${model?.folio}`}</Title>
          </Row>

          <Row gutter={10}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
              <Title level={5}>Edad: {model?.edad}</Title>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Title level={5}>Elaborado: {moment(model?.horaElaboracion).format('DD-MM-YYYY')}</Title>
            </Col>
          </Row>

          <Row gutter={10}>
            <Title level={5}>Dimencionamiento</Title>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='idDefectoLaboratorio'
                label='Defecto de Laboratorio/Manipulación'
                rules={[{ required: true, message: 'Campo Defecto de Laboratorio/Manipulación no debe estar vacío' }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  placeholder='Seleccionar defecto'
                >
                  {variables.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.valor} - {item?.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='diametroA'
                label='Diámetro 1 (cm)'
                rules={[{ required: true, message: 'Valor requerido para calcular área' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onBlur={(e) => {
                    const tipoDiametro = true;
                    calcularArea(parseFloat(e.target.value), tipoDiametro)
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='diametroB'
                label='Diámetro 2 (cm)'
                rules={[{ required: true, message: 'Valor requerido para calcular área' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onBlur={(e) => {
                    const tipoDiametro = false;
                    calcularArea(parseFloat(e.target.value), tipoDiametro)
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='area'
                label='Área (cm²)'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='alturaA'
                label='Altura 1 (cm)'
                rules={[{ required: true, message: 'Altura no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='alturaB'
                label='Altura 2 (cm)'
                rules={[{ required: true, message: 'Altura no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='masa'
                label='Masa (kg)'
                rules={[{ required: true, message: 'Campo Masa no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
              xg={{ span: 6 }}
            >
              <Form.Item
                name='cabeceo'
                label='¿Será cabeceado?'
                rules={[{ required: true, message: 'Debe seleccionar una opción' }]}
              >
                <Radio.Group onChange={(e) => setCabeceoValue(e.target.value)}>
                  <Radio value={'SI'}>Sí</Radio>
                  <Radio value={'NO'}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {cabeceoValue === "SI" && (
            <>
              <Divider />
              <Row gutter={10}>
                <Title level={5}>Cabeceo</Title>
              </Row>

              <Row gutter={10}>
                <Col span={6}>
                  <Form.Item
                    name='planicidad'
                    label='Planicidad ¿Cumple'
                  >
                    <Radio.Group>
                      <Radio value={'SI'}>Sí</Radio>
                      <Radio value={'NO'}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='perpendicularidad'
                    label='Perpendicularidad ¿Cumple?'
                  >
                    <Radio.Group>
                      <Radio value={'SI'}>Sí</Radio>
                      <Radio value={'NO'}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='adherencia'
                    label='Adherencia ¿Cumple?'
                  >
                    <Radio.Group>
                      <Radio value={'SI'}>Sí</Radio>
                      <Radio value={'NO'}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name='espesor'
                    label='Espesor ¿Cumple?'
                  >
                    <Radio.Group>
                      <Radio value={'SI'}>Sí</Radio>
                      <Radio value={'NO'}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Title level={5}>
                  Espesor Promedio
                </Title>
              </Row>
              <Row>
                <Title level={5}>
                  {'Máximo 8mm (<350 kg/cm²). Máximo 5mm (>350 kg/cm²)'}
                </Title>
              </Row>
              <Row>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xg={{ span: 3 }}
                >
                  <Form.Item
                    name='espesor1'
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        form.setFieldsValue({
                          espesor1: e.target.value
                        });
                        calcularEspesorProm();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xg={{ span: 3 }}
                >
                  <Form.Item
                    name='espesor2'
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        form.setFieldsValue({
                          espesor2: e.target.value
                        });
                        calcularEspesorProm();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xg={{ span: 3 }}
                >
                  <Form.Item
                    name={'espesor3'}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        form.setFieldsValue({
                          espesor3: e.target.value
                        });
                        calcularEspesorProm();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xg={{ span: 3 }}
                >
                  <Form.Item
                    name={'espesor4'}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        form.setFieldsValue({
                          espesor4: e.target.value
                        });
                        calcularEspesorProm();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xg={{ span: 3 }}
                >
                  <Form.Item
                    name={'espesor5'}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        form.setFieldsValue({
                          espesor5: e.target.value
                        });
                        calcularEspesorProm();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xg={{ span: 3 }}
                >
                  <Form.Item
                    name={'espesor6'}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      onBlur={(e) => {
                        form.setFieldsValue({
                          espesor6: e.target.value
                        });
                        calcularEspesorProm();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xg={{ span: 3 }}
                >
                  <Form.Item
                    name={'espesorPromedio'}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder='Prom'
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item
                    name='velocidadCarga'
                    label='Velocidad de Carga (t/min)'
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Divider />

          <Row>
            <Col>
              <Title level={5}>Ensayo</Title>
            </Col>
          </Row>

          <Row>
            <Col>
              <Title level={5}>Tipo de Falla</Title>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={24}>
              <img src={'/assets/fallas_cilindros.png'} style={{ padding: 10 }} width={"1060px"} alt="Fallas Cilindros" />
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`factorCorreccion`}
                label='Factor de Corrección'
                initialValue={1}
                rules={[{ required: true, message: 'Campo Factor de Corrección no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  readOnly={((form.getFieldValue('alturaA') === '30.00' || form.getFieldValue('alturaA') === '30') && (form.getFieldValue('alturaB') === '30.00' || form.getFieldValue('alturaB') === '30')) ? true : false}
                  onBlur={() => {
                    document.getElementsByName(`factorCorreccion`);
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`lecNano`}
                label='Manómetro (t)'
                rules={[{ required: true, message: 'Campo Manómetro no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onBlur={(e) => {
                    calcularCargaMaximaYResistencia(parseFloat(e.target.value));
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`cargaMaxima`}
                label='Carga Máxima en kg'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`cargaMaximaN`}
                label='Carga Máxima en N'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`resistencia`}
                label='Resistencia en kg/cm²'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`resistenciaMPa`}
                label='Resistencia en MPa'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`falla`}
                label='Falla'
                initialValue={0}
                rules={[{ required: true, message: 'Campo Falla no debe estar vacío' }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  disabled={(model?.edad === 28 || cabeceoValue === 'SI') ? false : true}
                >
                  {fallas.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`observaciones`}
                label='Observaciones'
                rules={[{ required: true, message: 'Campo Observaciones no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`horaEnsayo`}
                label='Hora Ensayo'
                rules={[{ required: true, message: 'Campo Hora Ensayo no debe estar vacío' }]}
              >
                <TimePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format='HH:mm'
                  onChange={(v) => valorarHoraEnsayo(moment.utc(v).format('YYYY-MM-DD HH:mm:ss'))}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`idUsuarioEnsayo`}
                label='Ensayado por'
                rules={[{ required: true, message: 'Campo Ensayado Por no debe estar vacío' }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  placeholder='Selecione técnico que ensayó'
                >
                  {tecnicos.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={10}>
            <Col span={9}>
              <Form.Item>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  block
                  size="large"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DefaultLayout>
    </>
  );
}

export default PruebaCilindro