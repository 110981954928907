import Agregados from './Agregados';
import Concreto from './Concreto';
import Materiales from './Materiales';
import Pavimentos from './Pavimentos';
import Suelo from './Suelo';

const formsMuestras = [
  {
    key: 1,
    title: 'CONCRETO',
    component: <Concreto />
  },
  {
    key: 2,
    title: 'SUELO',
    component: <Suelo />
  },
  {
    key: 3,
    title: 'AGREGADOS',
    component: <Agregados />
  },
  {
    key: 4,
    title: 'PAVIMENTOS',
    component: <Pavimentos />
  },
  {
    key: 5,
    title: 'MATERIALES',
    component: <Materiales />
  },
];

export default formsMuestras;