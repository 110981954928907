import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DefaultLayout } from '../../components/layouts';
import { Row, Col, Form, Select, DatePicker, Input, Divider, Button, Modal, message } from 'antd';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { useHistory } from 'react-router-dom';
import { useModel, useModels, useQuery } from '../../hooks';
import StatusResponse from '../../services/statusResponse';
import { ViewLoading, UsuariosSelect } from '../../components';
import { tipo, periodo } from '../../utilities';

const EventoForm = () => {

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const history = useHistory();
  const q = useQuery();
  const id = q.get("id");
  const idP = q.get("idProyecto");
  const editing = !!id;
  const [saveLoading, setSaveLoading] = useState(false);

  //const [proyectoValue, setProyectoValue] = useState(null)
  const [tecnicoValue, setTecnicoValue] = useState('')

  const idTecnicoParamsMemo = useMemo(() => ({ rol: 'Técnico Campo' }), [])
  const idProyectoMemo = useMemo(() => ({ id: idP }), [idP])

  //Proyectos
  const [
    proyectos,
    // eslint-disable-next-line no-unused-vars
    proyectosError, proyectosPage, refreshProyectos
  ] = useModels({
    name: 'proyecto',
    ordenar: 'id-asc',
    limite: -1,
    extraParams: idProyectoMemo
  });

  //Tecnicos
  const [
    tecnicos,
    tecnicosLoading,
    // eslint-disable-next-line no-unused-vars
    tecnicosError, tecnicosPage, refreshTecnicos
  ] = useModels({
    name: 'usuario',
    extraParams: idTecnicoParamsMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  const {
    model,
    modelLoading,
  } = useModel({
    name: "evento",
    id: id,
  });

  const breadcrumb = [
    {
      name: "Proyectos",
      to: "/proyectos"
    },
    {
      name: proyectos[0]?.nombre,
      to: `/eventos?idProyecto=${idP}`
    },
    {
      name: editing ? model?.titulo : "Agregar Evento",
      to: editing ? `editar-evento?id=${id}&idProyecto=${idP}` : `agregar-evento?idProyecto=${idP}`
    },
  ]

  const setFormData = useCallback((data) => {
    form.setFieldsValue({
      idProyecto: proyectos[0]?.id,
      //titulo: data?.titulo,
      tipo: data?.tipo,
      fechaInicio: data?.fechaInicio,
      fechaFin: data?.fechaFin,
      periodo: data?.periodo,
      idUsuario: data?.idUsuario,
      observaciones: data?.observaciones,
    })
  }, [form, proyectos])

  const onFinish = async (values) => {
    const {
      fechaInicio,
      fechaFin,
    } = values

    try {
      setSaveLoading(true)

      let _body = {
        ...values,
        idProyecto: proyectos[0]?.id,
        fechaInicio: moment(fechaInicio.toDate()).format('YYYY-MM-DD HH:mm:ss'),
        fechaFin: moment(fechaFin.toDate()).format('YYYY-MM-DD HH:mm:ss'),
        idUsuario: tecnicoValue,
        idUsuarioCreo: 1,
        estatus: 'nuevo'
      }

      if (editing) {
        _body.id = id
      }


      const res = await StatusResponse.post("evento", _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => { secondsToGo -= 1 }, 100)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push(`/eventos?idProyecto=${idP}`)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning({
      content: 'Verifica que todos los campos estén correctos',
      style: {
        marginTop: '10vh',
      },
    });
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    if (editing && model) {
      //setProyectoValue(proyectos[0]?.id)
      setTecnicoValue(model?.idUsuario)
      setFormData(model)
      form.setFieldsValue({
        fechaInicio: moment(model?.fechaInicio),
        fechaFin: moment(model?.fechaFin),
        idProyecto: proyectos[0]?.id
      })
    }
  }, [editing, form, model, proyectos, setFormData]);

  if (modelLoading) return <ViewLoading />

  return (
    <>
      <DefaultLayout
        title={model?.titulo ? model?.titulo : "Evento nuevo"}
        breadcrumbItems={breadcrumb}
      >
        <Form
          form={form}
          name="form"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="idProyecto"
                label="Proyecto"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <Select
                  disabled
                  style={{ widt: '100%' }}
                  dafualtValue={proyectos[0]?.id}
                  placeholder={proyectos[0]?.nombre}
                >
                  {proyectos?.map(item => (
                    <Option key={item?.nombre} value={item?.id}>{item?.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="tipo"
                label="Tipo"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Select
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {tipo.map(item => (
                    <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="fechaInicio"
                label="Fecha Inicial"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <DatePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="fechaFin"
                label="Fecha Fin"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <DatePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="periodo"
                label="Período"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Select
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {periodo.map(item => (
                    <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="idUsuario"
                label="Técnico Asignado"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <UsuariosSelect
                  setUsuarioValue={setTecnicoValue}
                  defaultValor={model?.idUsuario}
                  usuariotoValue={tecnicoValue}
                  dataUsuario={tecnicos}
                  loading={tecnicosLoading}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="observaciones"
                label="Observaciones"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={10}>
            <Col span={9}>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DefaultLayout>
    </>
  );
}

export default EventoForm;