import React, { useMemo, useState, useEffect } from 'react';
import { useModels, useQuery, usePagination } from '../../../../hooks';
import { Form, Row, Col, Button, Table, Radio, InputNumber, Modal, Typography, message } from 'antd';
import { SaveOutlined, EyeOutlined } from '@ant-design/icons';
import StatusResponse from '../../../../services/statusResponse';

const Cabeceo = () => {

  const [form] = Form.useForm();
  const q = useQuery();
  const idP = q.get("idProyecto");

  const { Title } = Typography;

  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [request, setRequest] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [copiaCilindros, setCopiaCilindros] = useState([]);
  const [cilindroValue, setCilindroValue] = useState({});
  const [visible, setVisible] = useState(false);

  const idProyectoParams = useMemo(() => ({ idProyecto: idP, estado: 'DIMENSIONADO LABORATORIO', cabeceo: 'SI' }), [idP]);
  const requestParams = useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: 'cilindro',
        extraParams: idProyectoParams,
        expand: 'concreto',
        ordenar: 'id-asc',
        limite: limite,
        pagina: pagina,
      }
    }
  }, [idProyectoParams, limite, pagina]);

  //Cilindros
  const [
    cilindros,
    cilindrosLoading,
    // eslint-disable-next-line no-unused-vars
    cilindrosError,
    cilindrosPage,
    refreshCilindros
  ] = useModels(request);

  const hacerCopia = React.useCallback(() => {
    setCopiaCilindros(cilindros)
  }, [cilindros]);

  const columns = [
    {
      title: 'Folio',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 130,
      align: 'center',
      render: (_, item) => (
        <>
          <Form
            form={form}
            name="form"
            layout="vertical"
          >
            <Form.Item>
              <Button
                icon={<EyeOutlined />}
                type='primary'
                key={item}
                htmlType='submit'
                onClick={() => {
                  // guardarCilindros(item)
                  setCilindroValue(item);
                  setVisible(true);
                }}
                loading={saveLoading}
              >
                Detalle
              </Button>
            </Form.Item>
          </Form>
        </>
      )
    },
  ];

  const calcularEspesorProm = (row) => {
    let promedio = (form.getFieldValue(`espesor_1_cilindro_${row.folio}`) || 0) + (form.getFieldValue(`espesor_2_cilindro_${row.folio}`) || 0) + (form.getFieldValue(`espesor_3_cilindro_${row.folio}`) || 0) + (form.getFieldValue(`espesor_4_cilindro_${row.folio}`) || 0) + (form.getFieldValue(`espesor_5_cilindro_${row.folio}`) || 0) + (form.getFieldValue(`espesor_6_cilindro_${row.folio}`) || 0);
    promedio = promedio / 6;
    form.setFieldsValue({ ['espesor_prom_cilindro_' + row.folio]: promedio })
  }

  const guardarCilindros = (cilindro) => {
    let cilindros = [...copiaCilindros];
    let valor;
    for (let i = 0, l = cilindros.length; i < l; i++) {
      if (cilindros[i]?.id === cilindro.id) {
        cilindros[i].planicidad = form.getFieldValue(`planicidad_cilindro_${cilindro.folio}`);
        cilindros[i].perpendicularidad = form.getFieldValue(`perpendicularidad_cilindro_${cilindro.folio}`);
        cilindros[i].adherencia = form.getFieldValue(`adherencia_cilindro_${cilindro.folio}`);
        cilindros[i].espesorMaximo = form.getFieldValue(`espesor_cilindro_${cilindro.folio}`);
        cilindros[i].espesor1 = form.getFieldValue(`espesor_1_cilindro_${cilindro.folio}`);
        cilindros[i].espesor2 = form.getFieldValue(`espesor_2_cilindro_${cilindro.folio}`);
        cilindros[i].espesor3 = form.getFieldValue(`espesor_3_cilindro_${cilindro.folio}`);
        cilindros[i].espesor4 = form.getFieldValue(`espesor_4_cilindro_${cilindro.folio}`);
        cilindros[i].espesor5 = form.getFieldValue(`espesor_5_cilindro_${cilindro.folio}`);
        cilindros[i].espesor6 = form.getFieldValue(`espesor_6_cilindro_${cilindro.folio}`);
        cilindros[i].espesorPromedio = form.getFieldValue(`espesor_prom_cilindro_${cilindro.folio}`);
        cilindros[i].velocidadCarga = form.getFieldValue(`velocidad_carga_cilindro_${cilindro.folio}`);

        if (cilindros[i].planicidad &&
          cilindros[i].perpendicularidad &&
          cilindros[i].adherencia &&
          cilindros[i].espesorMaximo &&
          cilindros[i].espesor1 &&
          cilindros[i].espesor2 &&
          cilindros[i].espesor3 &&
          cilindros[i].espesor4 &&
          cilindros[i].espesor5 &&
          cilindros[i].espesor6 &&
          cilindros[i].velocidadCarga) {
          valor = true;
        }
        else {
          valor = false;
        }
      }
    }

    if (!valor) {
      message.warning('Campos obligatorios vacíos en cilindro ' + cilindro.folio)
    }
    else {
      onFinishCabeceo(cilindro)
    }
  }

  const onFinishCabeceo = async (item) => {
    const {
      id,
      planicidad,
      perpendicularidad,
      adherencia,
      espesorMaximo,
      espesor1,
      espesor2,
      espesor3,
      espesor4,
      espesor5,
      espesor6,
      espesorPromedio,
      velocidadCarga
    } = item

    try {
      setSaveLoading(true);

      let body = {
        id,
        planicidad,
        perpendicularidad,
        adherencia,
        espesorMaximo,
        espesor1,
        espesor2,
        espesor3,
        espesor4,
        espesor5,
        espesor6,
        espesorPromedio,
        velocidadCarga,
        cabeceo: 'CABECEADO'
      }

      const res = await StatusResponse.post('pruebas-laboratorio/cabeceo', body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        console.log('Estatus correcto');
        refreshCilindros();
        message.success(res?.mensaje, 2);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  }

  useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams]);

  useEffect(() => {
    hacerCopia()
  }, [hacerCopia]);

  useEffect(() => {
    if (cilindrosPage) {
      setTotal(cilindrosPage?.total);
      setLimite(cilindrosPage?.limite);
    }
  }, [cilindrosPage, setLimite, setTotal])

  return (
    <>
      <Modal
        title={'Cabeceo de Cilindro ' + cilindroValue?.folio}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={685}
        footer={[
          <Row gutter={10} justify='end'>
            <Col span={6}>
              <Button
                block
                onClick={() => setVisible(false)}
              >
                Cancelar
              </Button>
            </Col>
            <Col span={9}>
              <Button
                icon={<SaveOutlined />}
                type='primary'
                block
                loading={saveLoading}
                onClick={() => {
                  guardarCilindros(cilindroValue);
                  setVisible(false);
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        ]}
      >
        <Form
          form={form}
          name='form'
          layout='vertical'
        >
          <Row gutter={10}>
            <Col span={6}>
              <Form.Item
                name={'planicidad_cilindro_' + cilindroValue?.folio}
                label='Planicidad ¿Cumple'
              >
                <Radio.Group>
                  <Radio value={'SI'}>Sí</Radio>
                  <Radio value={'NO'}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={'perpendicularidad_cilindro_' + cilindroValue?.folio}
                label='Perpendicularidad ¿Cumple?'
              >
                <Radio.Group>
                  <Radio value={'SI'}>Sí</Radio>
                  <Radio value={'NO'}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={'adherencia_cilindro_' + cilindroValue?.folio}
                label='Adherencia ¿Cumple?'
              >
                <Radio.Group>
                  <Radio value={'SI'}>Sí</Radio>
                  <Radio value={'NO'}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={'espesor_cilindro_' + cilindroValue?.folio}
                label='Espesor ¿Cumple?'
              >
                <Radio.Group>
                  <Radio value={'SI'}>Sí</Radio>
                  <Radio value={'NO'}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Title level={5}>
              Espesor Promedio
            </Title>
          </Row>
          <Row>
            <Title level={5}>
              {'Máximo 8mm (<350 kg/cm²). Máximo 5mm (>350 kg/cm²)'}
            </Title>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name={'espesor_1_cilindro_' + cilindroValue.folio}
              >
                <InputNumber
                  onBlur={() => {
                    document.getElementsByName(`espesor_1_cilindro_${cilindroValue.folio}`);
                    calcularEspesorProm(cilindroValue);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={'espesor_2_cilindro_' + cilindroValue.folio}
              >
                <InputNumber
                  onBlur={() => {
                    document.getElementsByName(`espesor_2_cilindro_${cilindroValue.folio}`);
                    calcularEspesorProm(cilindroValue);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={'espesor_3_cilindro_' + cilindroValue.folio}
              >
                <InputNumber
                  onBlur={() => {
                    document.getElementsByName(`espesor_3_cilindro_${cilindroValue.folio}`);
                    calcularEspesorProm(cilindroValue);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={'espesor_4_cilindro_' + cilindroValue.folio}
              >
                <InputNumber
                  onBlur={() => {
                    document.getElementsByName(`espesor_4_cilindro_${cilindroValue.folio}`);
                    calcularEspesorProm(cilindroValue);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={'espesor_5_cilindro_' + cilindroValue.folio}
              >
                <InputNumber
                  onBlur={() => {
                    document.getElementsByName(`espesor_5_cilindro_${cilindroValue.folio}`);
                    calcularEspesorProm(cilindroValue);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={'espesor_6_cilindro_' + cilindroValue.folio}
              >
                <InputNumber
                  onBlur={() => {
                    document.getElementsByName(`espesor_6_cilindro_${cilindroValue.folio}`);
                    calcularEspesorProm(cilindroValue);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={'espesor_prom_cilindro_' + cilindroValue.folio}
              >
                <InputNumber
                  placeholder='Prom'
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name={'velocidad_carga_cilindro_' + cilindroValue?.folio}
                label='Velocidad de Carga (t/min)'
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Row>
        <Col span={24}>
          <Table
            dataSource={copiaCilindros}
            columns={columns}
            loading={cilindrosLoading}
            pagination={configPagination}
            rowKey='id'
            scroll={{ x: 'max-content' }}
            size='small'
          />
        </Col>
      </Row>
    </>
  );
}

export default Cabeceo;