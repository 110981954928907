import area from "./AreaProyecto";
import elemento from "./Elementos";
import ubicacion from "./Ubicaciones";
import tipo from "./Tipos";
import periodo from "./Periodos";
import numeros from "./Numeros";
import letras from "./Letras";
import rol from "./Roles";
import diametroAltura from "./DiametroAltura";
import edad from "./Edad";
import tipoMuestra from "./TipoMuestra";
import estados from "./Estados";
import fallas from "./FallasEnsayo";
import vencimientos from "./Vencimiento";
import timezone from "./ZonasHorarias";
import { Search } from "./Search";

import moment from 'moment';

const capitalizeFirst = (string) => {
  const split = string.split("-");
  let palabraUnida = "";
  split.forEach((s) => {
    palabraUnida = palabraUnida + s.charAt(0).toUpperCase() + s.slice(1);
  });
  return palabraUnida;
};

const generateDefaultChartOptions = (chartType = "pie", options = {}, callback) => ({
  chart: {
    type: chartType,
    inverted: options.inverted || false,
    options3d: {
      enabled: chartType === "pie",
      alpha: 45,
      beta: 0,
    },
    height: options.chartHeight || null,
  },
  credits: {
    enabled: false,
  },
  colors: options?.colores || ["#2f7ed8", "#0d233a", "#8bbc21", "#910000", "#1aadce", "#492970", "#f28f43", "#77a1e5", "#c42525", "#a6c96a"],
  title: {
    text: options?.titulo || "TITULO POR DEFAULT",
  },
  subtitle: {
    text: options?.subtitulo || "SUBTITULO POR DEFAULT",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
      events: {
        click: typeof callback === "function" ? callback : () => {},
      }
    },
  },
  series: [
    {
      name: options?.nombre || "NOMBRE DE LA COLECCION DE DATOS",
      data: options?.datos || [],
    },
  ],
});

const generateDefaultChartOptions2D = (chartType = "pie", options = {}) => ({
  chart: {
    type: chartType,
    inverted: options.inverted || false,
    height: options.chartHeight || null,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: options.title || "TITULO POR DEFAULT",
  },
  subtitle: {
    text: options.subtitle || "SUBTITULO POR DEFAULT",
  },
  plotOptions: {
    pie: {},
  },
  series: [
    {
      name: options.name || "NOMBRE DE LA COLECCION DE DATOS",
      data: options.data || [],
    },
  ],
});

const renderDateUTC = prop => {
  if (prop){
      const date = new Date(prop);
      const dateUTC = new Date(
          date.getTime() - date.getTimezoneOffset() * 60 * 1000
      );
      return moment(dateUTC).format('DD-MM-YYYY HH:mm:ss');
  }
};

export {
  capitalizeFirst,
  generateDefaultChartOptions,
  generateDefaultChartOptions2D,
  renderDateUTC,
  Search,
  area,
  elemento,
  ubicacion,
  tipo,
  periodo,
  numeros,
  letras,
  rol,
  diametroAltura,
  edad,
  tipoMuestra,
  estados,
  fallas,
  vencimientos,
  timezone
};
