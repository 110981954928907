import React from "react";
import { Form, Input, Button, Row, Col, Modal } from "antd";
import { Helmet } from 'react-helmet';
import { useAuth } from "../../hooks";
import "../../styles/LoginForm.less"

const SignIn = () => {
  const { signIn, sessionLoading } = useAuth();

  const onFinish = (values) => {
    const { usuario, clave } = values;
    if(!navigator.onLine) {
      Modal.warning({
        title: "Atención",
        content: 'Compruebe su conexión a internet.',
      });
    }
      signIn(usuario, clave);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
    <Helmet><title>OESTEC - Iniciar Sesión</title></Helmet>
    <div className="ck-app-login-wrap">
      <div className="ck-app-login-container">
          <div className="ck-app-login-main-content">
            <div className="ck-app-logo-content">
                <div className="ck-app-logo-content-bg"><img src="./assets/login-bg.png" alt="bg" /></div>
                <div
                  className="ck-app-logo"
                  // style={{
                  //   backgroundColor: "#fff",
                  //   borderRadius: "8px",
                  //   padding: "5px",
                  //   margin: "0 auto",
                  // }}
                >
                  <img src="./assets/oestec_logo.png" alt="logo" width="100" />
                </div>
                <div className="ck-app-logo-wid">
                  <h1>OESTEC</h1>
                  <p>Bienvenido</p>
                </div>
            </div>
            <div className="ck-app-login-content">
              <Form 
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="ck-signin-form"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                        name="usuario"
                        rules={[{ required: true, message: "Por favor ingresa un usuario o un correo electrónico" }]}
                    >
                        <Input
                          autoComplete="off"  
                          placeholder="Ingresa su usuario o correo electrónico"
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                        name="clave"
                        rules={[{ required: true, message: "No dejar vacio" }]}
                    >
                        <Input.Password
                          autoComplete="off"  
                          placeholder="Ingresa una contraseña"
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item>
                        <Button 
                            className="ck-btn"
                            type="primary" 
                            htmlType="submit"
                            size="middle"
                            loading={sessionLoading}
                        >
                            Ingresar
                        </Button>
                    </Form.Item>
                  </Col>
                </Row>           
              </Form>
              <div className="ck-app-login-footer" style={{ paddingTop: 60 }}>
                  <Col span={24}>Derechos reservados { new Date().getFullYear() } &#xa9;</Col>
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  );
};

export default SignIn;