const tipoMuestra = [
    {
        id: 0,
        name: 'Concreto',
    },
    {
        id: 1,
        name: 'Suelo',
    },
    {
        id: 2,
        name: 'Agregados',
    },
    {
        id: 3,
        name: 'Pavimentos',
    },
    {
        id: 4,
        name: 'Materiales',
    }
];

export default tipoMuestra