import React from 'react'
import { DefaultLayout } from '../../components/layouts'
import { Form, Row, Col, Select, Button, Modal } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { tipoMuestra, vencimientos } from '../../utilities'
import { useModels, useAuth } from '../../hooks'
import { useHistory } from 'react-router-dom'

const PruebasLaboratorio = () => {
  const [form] = Form.useForm();
  const { Option } = Select
  const { user } = useAuth();
  const history = useHistory();

  const [idProyectoValue, setIdProyectoValue] = React.useState(null);

  //Proyectos
  const [
    proyectos,
  ] = useModels({
    name: 'proyecto',
    ordenar: 'id-asc',
    limite: -1,
  });

  const breadcrumb = [
    {
      name: "Pruebas de laboratorio",
      to: "/pruebas-de-laboratorio"
    },
  ]

  const error = React.useCallback(() => {
    if (!(user?.rol === 'Técnico Laboratorio' || user?.rol === 'Administrador')) {
      Modal.error({
        title: 'Rol Incorrecto.',
        content: 'Se necesita rol de Técnico Laboratorio para acceder a este apartado.'
      });
      history.push('/proyectos')
    }
  }, [history, user?.rol]);

  const cambioMuestras = (idProyecto) => {
    history.push(`pruebas-de-laboratorio/pruebas?idProyecto=${idProyecto}&tab=1`)
  }

  React.useEffect(() => {
    error();
  }, [error])

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title='Pruebas de Laboratorio'
    >
      <Form
        form={form}
        name='form'
        layout='vertical'
      >
        <Row gutter={20}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <Form.Item name='tipoMuestra' label='Tipo Muestra'>
              <Select
                showSearch
                style={{ width: "100%" }}
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {tipoMuestra.map(item => (
                  <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <Form.Item
              name='proyecto'
              label='Proyecto'
            >
              <Select
                showSearch
                placeholder='Seleccione un Proyecto'
                style={{ width: "100%" }}
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={(v) => setIdProyectoValue(v)}
              >
                {proyectos.map(item => (
                  <Option key={item?.id} value={item?.id}>{item?.nombre + ' - ' + item?.folio}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <Form.Item name='vencimiento' label='Vencimiento'>
              <Select
                showSearch
                placeholder='Vence en'
                style={{ width: "100%" }}
                optionFilterProp="children"
              >
                {vencimientos.map(item => (
                  <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col>
            <Button
              icon={<SearchOutlined />}
              block
              type='primary'
              size='large'
              onClick={() => cambioMuestras(idProyectoValue)}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  )
}

export default PruebasLaboratorio