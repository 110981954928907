import React, {useState, useEffect, useCallback} from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { Form, Row, Col, Button, Input, /* Upload, */ Modal } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useModel, useQuery } from "../../../hooks"
import StatusResponse from "../../../services/statusResponse";
import { ViewLoading } from "../../../components";

const NuevaEmpresa = () => {

    const [ form ] = Form.useForm();
    const history = useHistory();
    const q = useQuery();
    const id = q.get("id");
    const editing = !!id;
    const [saveLoading, setSaveLoading] = useState(false);

    // const [ /* selectedFile, */ setSelectedFile ] = useState(null);
    // const [ selectedFileList, setSelectedFileList ] = useState([]);

    /* const normFile = ( e ) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }; */

    /* const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }; */
    
    /* const onChange = info => {
        switch (info.file.status) {
            case "uploading":
                setSelectedFileList([info.file]);
                break;
    
            case "done":
                setSelectedFile(info.file);
                setSelectedFileList([info.file]);
                break;
    
            default:
                setSelectedFile(null);
                setSelectedFileList([]);
        }
    }; */

    const {
        model,
        modelLoading,
    } = useModel({
        name: "empresa",
        id: id,
    })

    const breadcrumb = [
        {
            name: "Administración",
            to: "/administracion"
        },
        {
            name: "Empresas",
            to: "/administracion/empresas"
        },
        {
            name: editing ? model?.titulo : "Nueva Empresa",
            to: editing ? `empresa?id=${id}` : "nueva-empresa"
        }
    ]

    const setFormData = useCallback((data) => {
        form.setFieldsValue({
            titulo: data?.titulo,
            razonSocial: data?.razonSocial,
            rfc: data?.rfc,
            direccion: data?.direccion,
            telefono: data?.telefono,
            //logo: data?.logo
        })
    },[form])

    const onFinish = async values => {
        const { titulo, razonSocial, rfc, direccion, telefono } = values
        
        try {
            setSaveLoading(true)

            let _body = {
                titulo,
                razonSocial,
                rfc,
                direccion,
                telefono,
                //logo: selectedFile
            }

            if(editing){
                _body.id = id
            }

            const res = await StatusResponse.post("empresa", _body)
            if(res?.status === 400 && res?.errores !== null){
                const newArray = Object.values(res?.errores)
                Modal.error({
                    title: res?.mensaje,
                    content:(
                        <div>
                            {
                                newArray.map((m, i) =>
                                    <span key={(i + 1)}>- {m}</span>    
                                )
                            }
                        </div>
                    )
                })
            }
            else if(res?.status === 400 && res?.errores === null){
                Modal.error({
                    title: res?.mensaje
                })
            }
            else if(res?.status === 200){
                let secondsToGo = 2
                const modal = Modal.success({
                    title: res?.mensaje,
                    cancelButtonProps: {style: {display: 'none'}},
                    okButtonProps: {style: {display: 'none'}}
                })
                const timer = setInterval(() => {secondsToGo -= 1}, 100)
                setTimeout(() => {
                    clearInterval(timer)
                    modal.destroy()
                }, secondsToGo * 1000)
                history.push('/administracion/empresas')
            }
        }catch(error){
            console.log(error)
        }finally{
            setSaveLoading(false)
        }
    };

    useEffect(() => {
        if(editing && model){
            setFormData(model)
        }
    }, [editing, model, setFormData]);

    if(modelLoading) return <ViewLoading />
    
    return (
        <>
        <DefaultLayout
            breadcrumbItems={breadcrumb}
            title={model ? "Editar Información de "+model?.titulo : "Nueva Empresa"}
        >
            <Form
                form={form}
                name="form"
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='titulo'
                            label='Título'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='razonSocial'
                            label='Razón Social'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='rfc'
                            label='RFC'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='direccion'
                            label='Dirección'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='telefono'
                            label='Teléfono'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {/* <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='logo'
                            label='Logo'
                            style={{
                                fontWeight: 'bold'
                            }}
                            getValueFromEvent={normFile}
                        >
                            <Upload accept='image/*' fileList={selectedFileList} customRequest={dummyRequest} onChange={ onChange }>
                                <Button icon={<UploadOutlined />}>Seleccionar un Archivo</Button>
                            </Upload>
                        </Form.Item>
                    </Col> */}
                </Row>

                <Row gutter={10}>
                    <Col span={9}>
                        <Form.Item>
                            <Button
                                type="primary"
                                block
                                size="large"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DefaultLayout>
        </>
    );
}

export default NuevaEmpresa;