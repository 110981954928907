import React from "react";
import { Result } from "antd";

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="No se encontró la página solicitada."
    />
  );
};

export default NotFound;
