const periodo = [
    {
        id: 1,
        name: 'NINGUNO'
    },
    {
        id: 2,
        name: 'DIARIO'
    },
    {
        id: 3,
        name: 'SEMANAL'
    },
    {
        id: 4,
        name: 'QUINCENAL'
    },
    {
        id: 5,
        name: 'MENSUAL'
    }
];

export default periodo;