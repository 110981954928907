import React, {useState, useEffect, useCallback} from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { Form, Row, Col, Button, Input, Modal} from 'antd';
import { useHistory } from 'react-router-dom';
import { useModel, useQuery } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';
import { ViewLoading } from '../../../components';

const LaboratorioForm = () => {

    const [ form ] = Form.useForm();
    const history = useHistory();
    const q = useQuery();
    const id = q.get("id");
    const editing = !!id;
    const [saveLoading, setSaveLoading] = useState(false);

    const {
        model,
        modelLoading,
    } = useModel({
        name: "laboratorio",
        id: id,
    })

    const breadcrumb = [
        {
            name: "Administración",
            to: "/administracion"
        },
        {
            name: "Laboratorios",
            to: "/administracion/laboratorios"
        },
        {
            name: editing ? model?.titulo : "Nuevo Laboratorio",
            to: editing ? `laboratorio?id=${id}` : "nuevo-laboratorio"
        }
    ]

    const setFormData = useCallback((data) => {
        form.setFieldsValue({
            titulo: data?.titulo,
            descripcion: data?.descripcion,
            claveLaboratorio: data?.claveLaboratorio,
        })
    },[form])

    const onFinish = async values => {
        const { titulo, descripcion, claveLaboratorio } = values
        try {
            setSaveLoading(true)

            let _body = {
                titulo,
                descripcion,
                claveLaboratorio
            }

            if(editing){
                _body.id = id
            }

            const res = await StatusResponse.post("laboratorio", _body)
            if(res?.status === 400 && res?.errores !== null){
                const newArray = Object.values(res?.errores)
                Modal.error({
                    title: res?.mensaje,
                    content:(
                        <div>
                            {
                                newArray.map((m, i) =>
                                    <span key={(i + 1)}>- {m}</span>    
                                )
                            }
                        </div>
                    )
                })
            }
            else if(res?.status === 400 && res?.errores === null){
                Modal.error({
                    title: res?.mensaje
                })
            }
            else if(res?.status === 200){
                let secondsToGo = 2
                const modal = Modal.success({
                    title: res?.mensaje,
                    cancelButtonProps: {style: {display: 'none'}},
                    okButtonProps: {style: {display: 'none'}}
                })
                const timer = setInterval(() => {secondsToGo -= 1}, 100)
                setTimeout(() => {
                    clearInterval(timer)
                    modal.destroy()
                }, secondsToGo * 1000)
                history.push('/administracion/laboratorios')
            }
        }catch(error){
            console.log(error)
        }finally{
            setSaveLoading(false)
        }
    };

    useEffect(() => {
        if(editing && model){
            setFormData(model)
        }
    }, [editing, model, setFormData]);

    if(modelLoading) return <ViewLoading />

    return (
        <>
        <DefaultLayout
            breadcrumbItems={breadcrumb}
            title={model ? "Editar Información de "+model?.titulo : "Nuevo Laboratorio"}
        >
            <Form
                form={form}
                name="form"
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                    >
                        <Form.Item
                            name='titulo'
                            label='Título'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                    >
                        <Form.Item
                            name='descripcion' 
                            label='Descripción'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                    >
                        <Form.Item
                            name='claveLaboratorio'
                            label='Clave'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                {editing &&(
                    <Row>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                        >
                            <Form.Item
                                name='secuencialLaboratorio'
                                label='Secuencial'
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                <Row gutter={10}>
                    <Col span={9}>
                        <Form.Item>
                            <Button
                                type="primary"
                                block
                                size="large"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DefaultLayout>
        </>
    );
}

export default LaboratorioForm;