import React, { useState, useEffect, useMemo } from 'react';
import { Form, Row, Col, Table, InputNumber, Input, Select, Button, Modal, Typography, TimePicker, message } from 'antd';
import { EyeOutlined, SaveOutlined } from '@ant-design/icons';
import { useModels } from '../../../../../hooks';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { fallas } from '../../../../../utilities';
import StatusResponse from '../../../../../services/statusResponse';
import { renderDateUTC } from '../../../../../utilities';

const Ensayos = ({
  fechaVencimiento
}) => {

  const [form] = Form.useForm();
  const { Column } = Table;
  const { Option } = Select;
  const { Title } = Typography;

  const [request, setRequest] = useState({});
  const [copiaCilindros, setCopiaCilindros] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [cilindroValue, setCilindroValue] = useState(null);
  const [visible, setVisible] = useState(false);

  const cilindroParams = useMemo(() => ({
    estado: 'DIMENSIONADO LABORATORIO',
    inicio: moment(fechaVencimiento).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    fin: moment(fechaVencimiento).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  }), [fechaVencimiento]);
  const requestParams = useMemo(() => ({
    name: 'cilindro',
    extraParams: cilindroParams,
    ordenar: 'id-asc',
    limite: -1,
    expand: 'concreto'
  }), [cilindroParams]);
  

  const tecnicoParamsMemo = useMemo(() => ({ rol: 'Técnico Laboratorio' }), [])

  //Cilindros
  const [
    cilindros,
    cilindrosLoading,
    // eslint-disable-next-line no-unused-vars
    cilindrosError,
    // eslint-disable-next-line no-unused-vars
    cilindrosPage,
    refreshCilindros
  ] = useModels(request);

  //Tecnicos
  const [
    tecnicos,
    // eslint-disable-next-line no-unused-vars
    tecnicosLoading, tecnicosError, tecnicosPage, refreshTecnicos
  ] = useModels({
    name: 'usuario',
    extraParams: tecnicoParamsMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  const hacerCopia = React.useCallback(() => {
    setCopiaCilindros(cilindros)
  }, [cilindros]);

  const calcularCargaMaximaYResistencia = (row, valor) => {
    form.setFieldsValue({
      [`cargaMaxima_${row.id}`]: ((valor * 1000) * form.getFieldValue(`factorCorreccion_${row.id}`))
    });
    form.setFieldsValue({
      [`cargaMaximaN_${row.id}`]: parseFloat(((form.getFieldValue(`cargaMaxima_${row.id}`)) * 9.8066).toFixed(0)),
      [`resistencia_${row.id}`]: parseFloat((form.getFieldValue(`cargaMaxima_${row.id}`) / row?.areaNominal).toFixed(1))
    });
    form.setFieldsValue({
      [`resistenciaMPa_${row.id}`]: parseFloat(((form.getFieldValue(`resistencia_${row.id}`)) * 0.098066).toFixed(1))
    });
  }

  const valorarHoraEnsayo = (row, _hora) => {
    switch (row.edad) {
      case 1:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(row.creado).subtract(30, 'minutes').add(1, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(row.creado).add(30, 'minutes').add(1, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${row.folio}. Hora de ensayo dentro de margen de tolerancia.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(row.creado).format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(row.creado).subtract(30, 'minutess').add(1, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).format('YYYY-MM-DD HH:mm') > moment(row.creado).format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(row.creado).subtract(30, 'minutes').add(1, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 3:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(row.creado).subtract(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(row.creado).add(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${row.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(row.creado).subtract(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(row.creado).subtract(2, 'hours').add(3, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).format('YYYY-MM-DD HH:mm') > moment(row.creado).subtract(2, 'hours').add(3, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(row.creado).subtract(2, 'hours').add(3, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 7:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(row.creado).subtract(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(row.creado).add(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${row.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(row.creado).subtract(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(row.creado).subtract(6, 'hours').add(7, 'days').diff(moment(_hora), 'minutes');
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).format('YYYY-MM-DD HH:mm') > moment(row.creado).subtract(6, 'hours').add(7, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(row.creado).subtract(6, 'hours').add(7, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 14:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(row.creado).subtract(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(row.creado).add(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${row.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(row.creado).subtract(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(row.creado).subtract(12, 'hours').add(14, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).add(11, 'days').format('YYYY-MM-DD HH:mm') > moment(row.creado).subtract(12, 'hours').add(14, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(row.creado).subtract(12, 'hours').add(14, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 28:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(row.creado).subtract(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(row.creado).add(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${row.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(row.creado).subtract(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(row.creado).subtract(20, 'hours').add(28, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).add(11, 'days').format('YYYY-MM-DD HH:mm') > moment(row.creado).subtract(20, 'hours').add(28, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(row.creado).subtract(20, 'hours').add(28, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      case 90:
        if (moment(_hora).format('YYYY-MM-DD HH:mm') >= moment(row.creado).subtract(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm') && moment(_hora).format('YYYY-MM-DD HH:mm') <= moment(row.creado).add(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm')) {
          message.success(`Cilindro ${row.folio}. Hora de ensayo adecuada.`);
        }
        else {
          if (moment(_hora).format('YYYY-MM-DD HH:mm') < moment(row.creado).subtract(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(row.creado).subtract(2, 'days').add(90, 'days').diff(moment(_hora), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo restante: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo restante: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo restante: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo restante: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
          else if (moment(_hora).add(11, 'days').format('YYYY-MM-DD HH:mm') > moment(row.creado).subtract(2, 'days').add(90, 'days').format('YYYY-MM-DD HH:mm')) {
            const diferenciaMinutos = moment(_hora).diff(moment(row.creado).subtract(2, 'days').add(90, 'days'), 'minutes')
            if (diferenciaMinutos < 60) {
              message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
              message.warning(`Tiempo excedido: ${diferenciaMinutos} minutos.`);
            }
            else if (diferenciaMinutos >= 60) {
              const diferenciaHoras = diferenciaMinutos / 60;
              if (diferenciaHoras > 24) {
                const diferenciaDias = diferenciaHoras / 24;
                if (diferenciaDias === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaDias} día.`);
                }
                else {
                  if (diferenciaDias === 1) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaDias} días.`);
                  }
                  else {
                    if (diferenciaMinutos % 60 !== 0) {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días y ${(diferenciaDias % 24).toFixed(0)} horas.`);
                    }
                    else {
                      message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                      message.warning(`Tiempo excedido: ${diferenciaDias.toFixed(0)} días.`);
                    }
                  }
                }
              }
              else {
                if (diferenciaHoras === 1) {
                  message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                  message.warning(`Tiempo excedido: ${diferenciaHoras} hora.`);
                }
                else {
                  if (diferenciaMinutos % 60 !== 0) {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras.toFixed(0)} horas y ${diferenciaMinutos % 60} minutos.`);
                  }
                  else {
                    message.error(`La hora de ensayo del cilindro ${row.folio} no coincide con la tolerancia permisible.`);
                    message.warning(`Tiempo excedido: ${diferenciaHoras} horas.`);
                  }
                }
              }
            }
          }
        }
        break;
      default:
        break;
    }
  }

  const onFinishEnsayo = async (values) => {
    try {
      setSaveLoading(true)

      let _body = {
        id: cilindroValue?.id,
        lecNano: form.getFieldValue(`lecNano_${cilindroValue?.id}`),
        cargaMaxima: form.getFieldValue(`cargaMaxima_${cilindroValue?.id}`),
        cargaMaximaN: form.getFieldValue(`cargaMaximaN_${cilindroValue?.id}`),
        resistencia: form.getFieldValue(`resistencia_${cilindroValue?.id}`),
        resistenciaMPa: form.getFieldValue(`resistenciaMPa_${cilindroValue?.id}`),
        observaciones: form.getFieldValue(`observaciones_${cilindroValue?.id}`),
        falla: form.getFieldValue(`falla_${cilindroValue?.id}`),
        idUsuarioEnsayo: form.getFieldValue(`idUsuarioEnsayo_${cilindroValue?.id}`),
        horaEnsayo: moment.utc(form.getFieldValue(`horaEnsayo_${cilindroValue?.id}`)).format('YYYY-MM-DD HH:mm:ss')
      }

      const res = await StatusResponse.post('pruebas-laboratorio/ensayo', _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        console.log("estatus correcto");
        setVisible(false);
        refreshCilindros();
        message.success(res?.mensaje, 2);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning({
      content: 'Verifica que todos los campos esten correctos.',
      style: {
        marginTop: '10vh',
      },
    });
  };

  useEffect(() => {
    hacerCopia();
  }, [hacerCopia])

  useEffect(() => {
    setRequest(requestParams);
    refreshCilindros();
    return () => setRequest({})
  }, [refreshCilindros, requestParams])

  return (
    <>
      <Row>
        <Col>
          <Title level={5}>Tipo de Falla</Title>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={24}>
          <img src={'/assets/fallas_cilindros.png'} style={{ padding: 10 }} width={"1060px"} alt="Fallas Cilindros" />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            // columns={columns}
            dataSource={copiaCilindros}
            loading={cilindrosLoading}
            // pagination={configPagination}
            rowKey='id'
            scroll={{ x: 'max-content' }}
            size="small"
          >
            <Column
              title='Folio'
              key='id'
              dataIndex='id'
              render={
                (_, value) => value?.folio
              }
            />
            <Column
              title='Edad'
              key='edad'
              dataIndex='edad'
              align='center'
              render={
                (_, value) => value?.edad
              }
            />
            <Column
              title='Fecha Creado'
              key='concreto'
              dataIndex='concreto'
              render={
                (value) => value?.horaElaboracion ? renderDateUTC(value?.horaElaboracion) : <span style={{ color: '#c7c3c3' }}>-----</span>
              }
            />
            <Column
              title='Fecha Ensaye'
              key='fechaPruebaLaboratorio'
              dataIndex='fechaPruebaLaboratorio'
              render={
                (_, value) => value?.fechaPruebaLaboratorio ? renderDateUTC(value?.fechaPruebaLaboratorio) : <span style={{ color: '#c7c3c3' }}>-----</span>
              }
            />
            <Column
              title='Ubicación'
              key='id'
              dataIndex='id'
              render={
                (_, value) => {
                  let _ubiPileta1 = value?.ubiPileta1
                  let _ubiPileta2 = value?.ubiPileta2
                  let _ubiPileta3 = value?.ubiPileta3
                  return (<>{_ubiPileta1} - {_ubiPileta2} - {_ubiPileta3}</>)
                }
              }
            />
            <Column
              title='Acciones'
              key='id'
              dataIndex='id'
              width={130}
              align='center'
              render={
                (_, item) => (
                  <Button
                    icon={<EyeOutlined />}
                    type='primary'
                    key={item}
                    onClick={() => {
                      setCilindroValue(item);
                      setVisible(true);
                    }}
                    loading={saveLoading}
                  >
                    Detalle
                  </Button>
                )
              }
            />
          </Table>
        </Col>
      </Row>

      <Modal
        title={'Cilindro ' + cilindroValue?.folio}
        visible={visible}
        /*okText='Guardar'
        okButtonProps={{
            form: 'form', key: 'submit', htmlType: 'submit', icon: <SaveOutlined />, loading: saveLoading
        }}
        cancelText='Cancelar'*/
        onCancel={() => setVisible(false)}
        footer={[
          <Form
            form={form}
            name='form'
            layout='vertical'
            onFinish={onFinishEnsayo}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={10} justify='end'>
              <Col span={6}>
                <Button
                  block
                  size="large"
                  onClick={() => setVisible(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={9}>
                <Form.Item>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    block
                    size="large"
                    htmlType="submit"
                    loading={saveLoading}
                  >
                    Guardar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ]}
      >
        <Form
          form={form}
          name='form'
          layout='vertical'
          onFinish={onFinishEnsayo}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={10}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
              <Title level={5}>Edad: {cilindroValue?.edad}</Title>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Title level={5}>Elaborado: {moment(cilindroValue?.horaElaboracion).format('DD-MM-YYYY')}</Title>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name={`factorCorreccion_${cilindroValue?.id}`}
                label='Factor de Corrección'
                initialValue={1}
                rules={[{ required: true, message: 'Campo Factor de Corrección no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  readOnly={(cilindroValue?.alturaA === '30.00' && cilindroValue?.alturaB === '30.00') ? true : false}
                  onBlur={() => {
                    document.getElementsByName(`factorCorreccion_${cilindroValue?.id}`);
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name={`lecNano_${cilindroValue?.id}`}
                label='Manómetro (t)'
                rules={[{ required: true, message: 'Campo Manómetro no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onBlur={(e) => {
                    calcularCargaMaximaYResistencia(cilindroValue, parseFloat(e.target.value));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <Title level={5}>{`Carga Máxima`}</Title>
            </Col>
            <Col span={12}>
              <Title level={5}>{`Resistencia`}</Title>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`cargaMaxima_${cilindroValue?.id}`}
                label='kg'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`cargaMaximaN_${cilindroValue?.id}`}
                label='N'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`resistencia_${cilindroValue?.id}`}
                label='kg/cm²'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`resistenciaMPa_${cilindroValue?.id}`}
                label='MPa'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Form.Item
                name={`falla_${cilindroValue?.id}`}
                label='Falla'
                initialValue={0}
                rules={[{ required: true, message: 'Campo Falla no debe estar vacío' }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  disabled={(cilindroValue?.edad === 28 || cilindroValue?.cabeceo === 'SI') ? false : true}
                >
                  {fallas.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`observaciones_${cilindroValue?.id}`}
                label='Observaciones'
                rules={[{ required: true, message: 'Campo Observaciones no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`horaEnsayo_${cilindroValue?.id}`}
                label='Hora Ensayo'
                rules={[{ required: true, message: 'Campo Hora Ensayo no debe estar vacío' }]}
              >
                <TimePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format='HH:mm'
                  onChange={(v) => valorarHoraEnsayo(cilindroValue, moment.utc(v).format('YYYY-MM-DD HH:mm:ss'))}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name={`idUsuarioEnsayo_${cilindroValue?.id}`}
                label='Ensayado por'
                rules={[{ required: true, message: 'Campo Ensayado Por no debe estar vacío' }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  placeholder='Selecione técnico que ensayó'
                >
                  {tecnicos.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Ensayos;