import React from 'react';
import { Row } from 'antd';

const Materiales = () => {

  return (
    <Row>
      Materiales
    </Row>
  );
}

export default Materiales;