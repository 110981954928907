import React, { useState, useMemo } from 'react';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import { Form, Typography, Row, Col, Select, DatePicker, Space, Button, Divider, Modal, message } from 'antd';
import { SearchOutlined, SaveOutlined } from '@ant-design/icons'
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { tipoMuestra, estados, numeros, letras } from '../../utilities';
import { useModels, useAuth } from '../../hooks';
import { useHistory } from 'react-router-dom';
import StatusResponse from '../../services/statusResponse';
import { TablaComponente } from './form';

const RegistroMuestras = () => {

  const [form] = Form.useForm();
  const [form_cilindros] = Form.useForm();
  const { user } = useAuth();
  const { Title } = Typography;
  const { Option } = Select;
  const history = useHistory();

  const [idProyecto, setIdProyecto] = useState(0);
  const [request, setRequest] = useState({});
  const [copiaCilindros, setCopiaCilindros] = useState([])
  const [copiaConcretos, setCopiaConcretos] = useState([])
  const [estadoValue, setEstadoValue] = useState('NO REGISTRADO');
  const [idMuestras, setIdMuestras] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [cilindroValue, setCilindroValue] = useState({});

  const idPadreParams = useMemo(() => ({ idPadre: 6 }), []);
  const idProyectoParams = useMemo(() => ({ idProyecto: idProyecto, estado: estadoValue }), [idProyecto, estadoValue]);
  const requestParams = useMemo(() => ({
    name: 'cilindro',
    extraParams: idProyectoParams,
    ordenar: 'id-asc',
    limite: -1,
    expand: 'concreto'
  }), [idProyectoParams]);

  const { REACT_APP_PDF_URL: pdfURL } = process.env;
  let idCon;
  let lista = [];

  //Proyectos
  const [
    proyectos,
  ] = useModels({
    name: 'proyecto',
    ordenar: 'id-asc',
    limite: -1,
  });

  //Concretos
  const [
    concretos,
  ] = useModels({
    name: 'concreto',
    ordenar: 'id-asc',
    limite: -1,
  });

  //Laboratorios
  const [
    laboratorios,
  ] = useModels({
    name: 'laboratorio',
    ordenar: 'id-asc',
    limite: -1,
  });

  //Cilindros
  const [
    cilindros,
    cilindrosLoading,
    // eslint-disable-next-line no-unused-vars
    cilindrosError,
    // eslint-disable-next-line no-unused-vars
    cilindrosPage,
    refreshCilindros
  ] = useModels(request);

  //Variables
  const [
    variables,
  ] = useModels({
    name: 'variable',
    extraParams: idPadreParams,
    ordenar: 'id-asc',
    limite: -1,
  });

  const breadcrumb = [
    {
      name: "Registro de Muestras",
      to: "/registro-de-muestras"
    },
  ]

  const mostrarCilindros = () => {
    setRequest(requestParams);
    refreshCilindros();
  }

  const hacerCopia = React.useCallback(() => {
    setCopiaCilindros(cilindros)
  }, [cilindros]);

  const hacerCopiaConcretos = React.useCallback(() => {
    setCopiaConcretos(concretos)
  }, [concretos]);

  const valorDefectos = (row) => {
    const variable = variables.find(_variable => _variable.id === row?.defectoObra);
    const defecto = '' + variable?.valor + ' - ' + variable?.nombre;
    return defecto;
  }

  const guardarEdad = (cilindro) => {
    let _copiaCilindros = [...copiaCilindros];

    for (let i = 0, l = _copiaCilindros.length; i < l; i++) {
      if (_copiaCilindros[i]?.id === cilindro?.id) {
        const concreto = copiaConcretos.find(_concreto => _concreto.id === _copiaCilindros[i]?.idConcreto)
        
        _copiaCilindros[i].fechaTransporte = form_cilindros.getFieldValue('fechaTransporte_concreto_' + concreto?.id);
        _copiaCilindros[i].edad = form_cilindros.getFieldValue(`edad_cilindro_${cilindro?.folio}`);
        break;
      }
    }
  }

  const guardarValores = (cilindro) => {
    let _copiaCilindros = [...copiaCilindros];

    for (let i = 0, l = _copiaCilindros.length; i < l; i++) {
      if (_copiaCilindros[i]?.id === cilindro?.id) {
        _copiaCilindros[i].ubiPileta1 = form_cilindros.getFieldValue(`ubi_pileta_1_cilindro_${cilindro?.folio}`);
        _copiaCilindros[i].ubiPileta2 = form_cilindros.getFieldValue(`ubi_pileta_2_cilindro_${cilindro?.folio}`);
        _copiaCilindros[i].ubiPileta3 = form_cilindros.getFieldValue(`ubi_pileta_3_cilindro_${cilindro?.folio}`);
        _copiaCilindros[i].defectoObra = form_cilindros.getFieldValue(`defecto_obra_cilindro_${cilindro?.folio}`);
        _copiaCilindros[i].defectoTransporte = form_cilindros.getFieldValue(`defecto_transporte_cilindro_${cilindro?.folio}`);
        break;
      }

    }

    setCopiaCilindros(_copiaCilindros);
  }

  const guardarCilindro = (_idCon) => {
    lista = [];
    let _copiaCilindros = [...copiaCilindros];
    let cilindro;
    let esNull = false;

    for (let i = 0, l = _copiaCilindros.length; i < l; i++) {
      cilindro = _copiaCilindros.find(_cilindro => _cilindro.id === _copiaCilindros[i].id);
      if (cilindro.idConcreto === _idCon) {
        lista.push({
          'id': cilindro?.id,
          'edad': cilindro?.edad,
          'defectoObra': cilindro?.defectoObra,
          'defectoTransporte': cilindro?.defectoTransporte,
          'estado': 'REGISTRADO',
          'ubiPileta1': cilindro?.ubiPileta1,
          'ubiPileta2': cilindro?.ubiPileta2,
          'ubiPileta3': cilindro?.ubiPileta3
        })
        idCon = cilindro?.idConcreto
      }
    }
    // eslint-disable-next-line array-callback-return
    lista.map(item => {
      if (item.defectoObra === null || item.defectoTransporte === null || item.ubiPileta1 === null || item.ubiPileta2 === null || item.ubiPileta3 === null || form_cilindros.getFieldValue(`idLaboratorio_concreto_${idCon}`) === (null || undefined) || form_cilindros.getFieldValue(`transporta_concreto_${idCon}`) === (null || undefined) || form_cilindros.getFieldValue(`transporta_concreto_${idCon}`) === "" || form_cilindros.getFieldValue(`fechaTransporte_concreto_${idCon}`) === null) {
        esNull = true;
      }
    })

    if (esNull) {
      message.warning('Compruebe que no haya campos vacíos')
      return
    }
    onFinishRegistro();
  }

  const buscarCilindros = (values) => {
    const _estado = values?.estado;
    setEstadoValue(_estado);
  };

  const error = React.useCallback(() => {
    if (!(user?.rol === 'Técnico Laboratorio' || user?.rol === 'Administrador')) {
      Modal.error({
        title: 'Rol Incorrecto.',
        content: 'Se necesita rol de Técnico Laboratorio para acceder a este apartado.'
      });
      history.push('/proyectos')
    }
  }, [history, user?.rol]);

  const onFinishRegistro = async () => {
    try {
      setSaveLoading(true)

      let _body = {
        idConcreto: idCon,
        fechaTransporte: moment(form_cilindros.getFieldValue(`fechaTransporte_concreto_${idCon}`)).format('YYYY-MM-DD HH:mm:ss'),
        transporta: form_cilindros.getFieldValue(`transporta_concreto_${idCon}`),
        cilindros: lista,
        idLaboratorio: form_cilindros.getFieldValue(`idLaboratorio_concreto_${idCon}`)
      }

      const res = await StatusResponse.post('cilindro/registro-muestra', _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        console.log("estatus correcto");
        refreshCilindros();
        message.success(res?.mensaje, 2);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  React.useEffect(() => {
    error();
  }, [error])

  React.useEffect(() => {
    hacerCopia()
  }, [hacerCopia])

  React.useEffect(() => {
    hacerCopiaConcretos()
  }, [hacerCopiaConcretos])

  React.useEffect(() => {
    if (cilindros.length > 0) {
      let arregloID = [];
      for (let i = 0, l = cilindros.length; i < l; i++) {
        if (!arregloID.includes(cilindros[i]?.idConcreto)) {
          arregloID.push(cilindros[i].idConcreto);
        }
      }
      setIdMuestras(arregloID)
    }
  }, [cilindros])

  return (
    <>
      <DefaultLayout
        breadcrumbItems={breadcrumb}
        title='Registro de Muestras'
      >
        <Modal
          title={'Cilindro ' + cilindroValue?.folio}
          visible={visible}
          onCancel={() => {
            setVisible(false);
          }}
          footer={[
            <Row gutter={10} justify='end'>
              <Col span={6}>
                <Button
                  block
                  onClick={() => setVisible(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={9}>
                <Button
                  icon={<SaveOutlined />}
                  type='primary'
                  block
                  loading={saveLoading}
                  onClick={() => {
                    form_cilindros.setFieldsValue({
                      [`ubi_pileta_1_cilindro_${cilindroValue?.folio}`]: form_cilindros.getFieldValue(`ubi_pileta_1_cilindro_${cilindroValue?.folio}`),
                      [`ubi_pileta_2_cilindro_${cilindroValue?.folio}`]: form_cilindros.getFieldValue(`ubi_pileta_2_cilindro_${cilindroValue?.folio}`),
                      [`ubi_pileta_3_cilindro_${cilindroValue?.folio}`]: form_cilindros.getFieldValue(`ubi_pileta_3_cilindro_${cilindroValue?.folio}`),
                      [`defecto_obra_cilindro_${cilindroValue?.folio}`]: form_cilindros.getFieldValue(`defecto_obra_cilindro_${cilindroValue?.folio}`),
                      [`defecto_transporte_cilindro_${cilindroValue?.folio}`]: form_cilindros.getFieldValue(`defecto_transporte_cilindro_${cilindroValue?.folio}`),
                    });
                    guardarValores(cilindroValue);
                    setVisible(false);
                  }}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          ]}
        >
          <Form
            form={form_cilindros}
            name='form_cilindros'
            layout="vertical"
          >
            <Row>
              Ubicación en Pileta
            </Row>

            <Row>
              <Col span={8}>
                <Form.Item
                  name={'ubi_pileta_1_cilindro_' + cilindroValue?.folio}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  initialValue={cilindroValue?.ubiPileta1 ? cilindroValue?.ubiPileta1 : null}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    disabled={estadoValue === 'NO REGISTRADO' ? false : true}
                    placeholder='0'
                    value={cilindroValue?.ubiPileta1 ? cilindroValue?.ubiPileta1 : null}
                    onBlur={() => {
                      document.getElementById(`ubi_pileta_1_cilindro_${cilindroValue?.folio}`);
                    }}
                  >
                    {numeros.map(item => (
                      <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'ubi_pileta_2_cilindro_' + cilindroValue?.folio}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  initialValue={cilindroValue?.ubiPileta2 ? cilindroValue?.ubiPileta2 : null}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    disabled={estadoValue === 'NO REGISTRADO' ? false : true}
                    placeholder='A'
                    value={cilindroValue?.ubiPileta2 ? cilindroValue?.ubiPileta2 : null}
                    onBlur={() => {
                      document.getElementById(`ubi_pileta_2_cilindro_${cilindroValue?.folio}`);
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {letras.map(item => (
                      <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'ubi_pileta_3_cilindro_' + cilindroValue?.folio}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  initialValue={cilindroValue?.ubiPileta3 ? cilindroValue?.ubiPileta3 : null}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    disabled={estadoValue === 'NO REGISTRADO' ? false : true}
                    placeholder='0'
                    value={cilindroValue?.ubiPileta3 ? cilindroValue?.ubiPileta3 : null}
                    onBlur={() => {
                      document.getElementById(`ubi_pileta_3_cilindro_${cilindroValue?.folio}`);
                    }}
                  >
                    {numeros.map(item => (
                      <Option key={item?.id} value={item?.id}>{item?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name={'defecto_obra_cilindro_' + cilindroValue?.folio}
                  label='Defecto de Obra'
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  initialValue={cilindroValue?.defectoObra ? cilindroValue?.defectoObra : null}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    disabled={estadoValue === 'NO REGISTRADO' ? false : true}
                    // defaultValue={cilindroValue?.defectoObra ? cilindroValue?.defectoObra : null}
                    value={cilindroValue?.defectoObra ? cilindroValue?.defectoObra : null}
                    placeholder={cilindroValue?.defectoObra ? valorDefectos(cilindroValue) : 'Seleccionar defecto'}
                    onBlur={() => {
                      document.getElementsByName(`defecto_obra_cilindro_${cilindroValue?.folio}`);
                      form_cilindros.setFieldsValue({[`defecto_obra_cilindro_${cilindroValue?.folio}`]: form_cilindros.getFieldValue(`defecto_obra_cilindro_${cilindroValue?.folio}`)})
                    }}
                  >
                    {variables.map(item => (
                      <Option key={item?.id} value={item?.id}>{item?.valor} - {item?.nombre}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name={'defecto_transporte_cilindro_' + cilindroValue?.folio}
                  label='Defecto de Transporte'
                  initialValue={cilindroValue?.defectoTransporte ? cilindroValue?.defectoTransporte : null}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    disabled={estadoValue === 'NO REGISTRADO' ? false : true}
                    valeu={cilindroValue?.defectoTransporte ? cilindroValue?.defectoTransporte : null}
                    placeholder={cilindroValue?.defectoTransporte ? valorDefectos(cilindroValue) : 'Seleccionar defecto'}
                  >
                    {variables.map(item => (
                      <Option key={item?.id} value={item?.id}>{item?.valor} - {item?.nombre}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Row>
          <Col>
            <Title level={4}>Registro de Muestras</Title>
          </Col>
        </Row>

        <Form
          form={form}
          name='form'
          layout="vertical"
          onFinish={buscarCilindros}
        >
          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='tipoMuestra'
                label='Tipo Muestra'
                style={{
                  fontWeight: 'bold'
                }}
                initialValue='Concreto'
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {tipoMuestra.map(item => (
                    <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='proyecto'
                label='Proyecto'
                style={{
                  fontWeight: 'bold'
                }}
              >
                <Select
                  showSearch
                  placeholder='Seleccione un Proyecto'
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(v) => (
                    setIdProyecto(v)
                  )}
                >
                  {proyectos.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.nombre + ' - ' + item?.folio}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='estado'
                label='Estado'
                style={{
                  fontWeight: 'bold'
                }}
                initialValue='NO REGISTRADO'
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(v) => {
                    setEstadoValue(v);
                    setCopiaCilindros([])
                  }}
                >
                  {estados.map(item => (
                    <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='fechaInicio'
                label='Fechas'
                style={{
                  fontWeight: 'bold'
                }}
              >
                <DatePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col>
              <Space direction="vertical">
                {' '}
                {' '}
                - a -
              </Space>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
            >
              <Form.Item
                name='fechaFin'
                label='&nbsp;'
                style={{
                  fontWeight: 'bold'
                }}
              >
                <DatePicker
                  locale={locale}
                  style={{ width: '100%' }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Form.Item>
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  block
                  size="large"
                  htmlType="submit"
                  disabled={idProyecto > 0 ? false : true}
                  loading={cilindrosLoading}
                  onClick={mostrarCilindros}
                >
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />
        <TablaComponente
          idMuestras={idMuestras}
          cilindros={copiaCilindros}
          estado={estadoValue}
          form={form_cilindros}
          pdfURL={pdfURL}
          saveLoading={saveLoading}
          concretos={concretos}
          guardarEdad={guardarEdad}
          laboratorios={laboratorios}
          idProyecto={idProyecto}
          setVisible={setVisible}
          setCilindroValue={setCilindroValue}
          guardarCilindro={guardarCilindro}
          cilindrosLoading={cilindrosLoading}
        />
      </DefaultLayout>
    </>
  );
}

export default RegistroMuestras;