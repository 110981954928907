import React from 'react'
import { DefaultLayout } from '../../../components/layouts'
import { Row, Col, Tabs } from 'antd'
import { useModels, useQuery } from '../../../hooks'
import formsPruebas from './forms'
import { Link } from 'react-router-dom'

const Pruebas = () => {

  const { TabPane } = Tabs;

  const q = useQuery();
  const idP = q.get("idProyecto");
  const tab = q.get("tab");

  const idProyectoMemo = React.useMemo(() => ({ id: idP }), [idP])

  //Proyectos
  const [
    proyectos,
  ] = useModels({
    name: 'proyecto',
    extraParams: idProyectoMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  const breadcrumb = [
    {
      name: "Pruebas de laboratorio",
      to: "/pruebas-de-laboratorio"
    },
    {
      name: proyectos[0]?.folio + ' ' + proyectos[0]?.nombre,
      to: `pruebas?idProyecto=${idP}`
    },
  ]
  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title='Pruebas de Laboratorio'
    >
      <Row>
        <Col span={24}>
          <Tabs /* defaultActiveKey='1' */>
            {formsPruebas?.map((item) => (
              <TabPane tab={<Link to={"/pruebas-de-laboratorio/pruebas?idProyecto=" + idP + "&tab=" + item?.key}>{item?.title}</Link>} key={item?.key}>
                <React.Fragment key={item?.key}>
                  {parseInt(tab) === item?.key ? item?.component : null}
                </React.Fragment>
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </DefaultLayout>
  )
}

export default Pruebas