import React, { useState, useEffect, useCallback } from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { Form, Row, Col, Button, Input, Select, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useModel, useModels, useQuery } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';
import { ViewLoading, EmpresasSelect } from '../../../components';
import { timezone } from '../../../utilities';

const PlazaForm = () => {

    const [ form ] = Form.useForm();
    const { Option } = Select;
    const history = useHistory();
    const q = useQuery();
    const id = q.get("id");
    const editing = !!id;
    const [saveLoading, setSaveLoading] = useState(false);

    const [empresaValue, setEmpresaValue] = useState(0)

    //Empresas
    const [
        empresas,
        empresasLoading,
        // eslint-disable-next-line no-unused-vars
        empresasError, empresasPage, refreshEmpresas
    ] = useModels({
        name: 'empresa',
        ordenar: 'id-asc',
        limite: -1,
    });

    const {
        model,
        modelLoading,
    } = useModel({
        name: "plaza",
        id: id,
    })

    const breadcrumb = [
        {
            name: "Administración",
            to: "/administracion"
        },
        {
            name: "Plazas",
            to: "/administracion/plazas"
        },
        {
            name: editing ? model?.titulo : "Nueva Plaza",
            to: editing ? `plaza?id=${id}` : "nueva-plaza"
        }
    ]

    const setFormData = useCallback((data) => {
        form.setFieldsValue({
            idEmpresa: data?.idEmpresa,
            titulo: data?.titulo,
            descripcion: data?.descripcion,
            clave: data?.clave,
            zonaHoraria: data?.zonaHoraria,
            secuencialProyecto: data?.secuencialProyecto
        })
    },[form])

    const onFinish = async values => {
        try {
            setSaveLoading(true)

            let _body = {
                ...values,
                idEmpresa: empresaValue
            }

            if(editing){
                _body.id = id
            }

            const res = await StatusResponse.post("plaza", _body)
            if(res?.status === 400 && res?.errores !== null){
                const newArray = Object.values(res?.errores)
                Modal.error({
                    title: res?.mensaje,
                    content:(
                        <div>
                            {
                                newArray.map((m, i) =>
                                    <span key={(i + 1)}>- {m}</span>    
                                )
                            }
                        </div>
                    )
                })
            }
            else if(res?.status === 400 && res?.errores === null){
                Modal.error({
                    title: res?.mensaje
                })
            }
            else if(res?.status === 200){
                let secondsToGo = 2
                const modal = Modal.success({
                    title: res?.mensaje,
                    cancelButtonProps: {style: {display: 'none'}},
                    okButtonProps: {style: {display: 'none'}}
                })
                const timer = setInterval(() => {secondsToGo -= 1}, 100)
                setTimeout(() => {
                    clearInterval(timer)
                    modal.destroy()
                }, secondsToGo * 1000)
                history.push('/administracion/plazas')
            }
        }catch(error){
            console.log(error)
        }finally{
            setSaveLoading(false)
        }
    };

    useEffect(() => {
        if(editing && model){
            setEmpresaValue(model?.idEmpresa)
            setFormData(model)
        }
    }, [editing, model, setFormData]);

    if(modelLoading) return <ViewLoading />

    return (
        <>
        <DefaultLayout
            breadcrumbItems={breadcrumb}
            title={model ? "Editar Información de "+model?.titulo : "Nueva Plaza"}
        >
            <Form
                form={form}
                name="form"
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='titulo'
                            label='Título'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='descripcion'
                            label='Descripción'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='clave'
                            label='Clave'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='idEmpresa'
                            label='Empresa'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <EmpresasSelect
                                setEmpresaValue={setEmpresaValue}
                                empresaValue={model?.idEmpresa}
                                dataEmpresa={empresas}
                                loading={empresasLoading}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='zonaHoraria'
                            label='Zona Horaria'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Select
                                showSearch
                                style={{width: "100%"}}
                                optionFilterProp="children"
                                placeholder='Seleccionar Zona Horaria'
                            >
                                {timezone.map(item => (
                                    <Option key={item.id} value={item.name}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='secuencialProyecto'
                            label='Secuencial Proyecto'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col span={9}>
                        <Form.Item>
                            <Button
                                type="primary"
                                block
                                size="large"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DefaultLayout>
        </>
    );
}

export default PlazaForm;