import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DefaultLayout } from '../../../../../components/layouts';
import { Form, Modal, Row, Col, Button, Divider, Select, Input, InputNumber, Space, DatePicker, TimePicker, Typography, Table, Image, Empty, message } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { /* elemento, */ ubicacion } from '../../../../../utilities';
import { useHistory } from 'react-router-dom';
import { useModel, useModels, useQuery, useAuth } from '../../../../../hooks';
import StatusResponse from '../../../../../services/statusResponse';
import { ViewLoading, UsuariosSelect } from '../../../../../components';
import { firestore } from '../../../../../services/firebase';
import {
  query,
  collection,
  where,
  onSnapshot
} from "@firebase/firestore";
import ReactAudioPlayer from 'react-audio-player';

const ConcretoForm = () => {

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const { Title } = Typography;
  const { user } = useAuth();

  const history = useHistory();
  const q = useQuery();
  const id = q.get("id");
  const idE = q.get("idEvento");
  const idP = q.get("idProyecto");
  const editing = !!id;
  const idTecnicoParamsMemo = useMemo(() => ({ rol: 'Técnico Campo' }), [])
  const idPadreElementosMemo = useMemo(() => ({ idPadre: 51 }), [])

  const [saveLoading, setSaveLoading] = useState(false);
  const [tecnicoValue, setTecnicoValue] = useState(null);
  const [idHerramientaSeleccionada, setIdHerramientaSeleccionada] = useState(null);
  const [eliminado, setEliminado] = useState([]);
  const [equipoTabla, setEquipoTabla] = useState([]);
  const [evidencias, setEvidencias] = useState([]);
  const idProyectoMemo = useMemo(() => ({ id: idP }), [idP]);
  const idEventoMemo = useMemo(() => ({ id: idE }), [idE]);
  const idPadreHerramientasParams = useMemo(() => ({ idPadre: 30 }), []);

  const {
    model,
    modelLoading,
  } = useModel({
    name: "concreto",
    id: id,
    expand: "equipo"
  })

  //Eventos
  const [
    eventos,
    // eslint-disable-next-line no-unused-vars
    eventosError, eventosPage, refreshEventos
  ] = useModels({
    name: 'evento',
    ordenar: 'id-asc',
    limite: -1,
    extraParams: idEventoMemo
  });

  //Proyectos
  const [
    proyectos,
    // eslint-disable-next-line no-unused-vars
    proyectosError, proyectosPage, refreshProyectos
  ] = useModels({
    name: 'proyecto',
    ordenar: 'id-asc',
    limite: -1,
    extraParams: idProyectoMemo
  });

  //Elementos
  const [
    elementos,
    // eslint-disable-next-line no-unused-vars
    elementosLoading,
    // eslint-disable-next-line no-unused-vars
    elementosError, elementosPage, refreshElementos
  ] = useModels({
    name: 'variable',
    extraParams: idPadreElementosMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  //Tecnicos
  const [
    tecnicos,
    tecnicosLoading,
    // eslint-disable-next-line no-unused-vars
    tecnicosError, tecnicosPage, refreshTecnicos
  ] = useModels({
    name: 'usuario?rol=Técnico',
    extraParams: idTecnicoParamsMemo,
    ordenar: 'id-asc',
    limite: -1,
  });

  //Herramientas
  const [
    herramientas,
  ] = useModels({
    name: 'variable',
    extraParams: idPadreHerramientasParams,
    ordenar: 'id-asc',
    limite: -1,
  });


  const breadcrumb = [
    {
      name: "Proyectos",
      to: "/proyectos"
    },
    {
      name: proyectos[0]?.nombre,
      to: `/eventos?idProyecto=${idP}`
    },
    {
      name: eventos[0]?.titulo,
      to: `/eventos?idProyecto=${idP}`
    },
    {
      name: "Muestreos",
      to: `/eventos/muestras?idEvento=${idE}&idProyecto=${idP}`
    },
    {
      name: editing ? "Muestra de concreto " + model?.folio : "Nueva muestra de concreto",
      to: editing ? `concreto?id=28&idEvento=${idE}&idProyecto=${idP}` : `nuevo-concreto?idEvento=${idE}&idProyecto=${idP}`
    }
  ]

  const setFormData = useCallback((data) => {
    form.setFieldsValue({
      idUsuarioMuestra: data?.idUsuarioMuestra,
      folio: data?.folio,
      elemento: data?.elemento,
      ubicacion: data?.ubicacion,
      titulo: data?.titulo,
      salidaPlanta: data?.salidaPlanta,
      llegadaObra: data?.llegadaObra,
      muestraHora: data?.muestraHora,
      finHora: data?.finHora,
      resistenciaDisenoFC: data?.resistenciaDisenoFC,
      edad: data?.edad,
      volumenRemision: data?.volumenRemision,
      remision: data?.remision,
      temperaturaAmbiente: data?.temperaturaAmbiente,
      revenimientoDiseno: data?.revenimientoDiseno,
      reveminientoDisenoMargen: data?.reveminientoDisenoMargen,
      tma: data?.tma,
      proveedor: data?.proveedor,
      ingeniero: data?.ingeniero,
      contratista: data?.contratista,
      volumenTotal: data?.volumenTotal,
      desviaciones: data?.desviaciones,
      observaciones: data?.observaciones,
      observacionesInternas: data?.observacionesInternas
    })
  }, [form])

  const insertarEnArreglo = () => {
    if (!idHerramientaSeleccionada) {
      message.warning('Selecciona una herramienta')
      return
    }
    const _herramienta = herramientas.find(herramienta => herramienta.id === idHerramientaSeleccionada);
    let obj = { 'id': null, 'idVariable': null, nombre: null, nombeclatura: null, 'serial': null };
    const _datos = [...equipoTabla];
    obj.id = equipoTabla[(equipoTabla.length) - 1]?.id + 1;
    obj.idVariable = _herramienta?.id;
    obj.nombre = _herramienta?.nombre;
    obj.nombeclatura = _herramienta?.nombeclatura;
    obj.serial = '';
    _datos.push(obj);
    setEquipoTabla(_datos)
  };

  const agregarSerial = (row, serial) => {
    const copiaLista = [...equipoTabla];
    for (let i = 0, l = copiaLista.length; i < l; i++) {
      if (copiaLista[i]?.id === row?.id) {
        copiaLista[i].serial = serial;
      }
    }
    setEquipoTabla(copiaLista)
  }

  const eliminarHerramienta = (item) => {
    const _herramienta = equipoTabla.find(herramienta => herramienta.id === item?.id)
    setEliminado([...eliminado, { 'id': _herramienta.id }])
    let _equipoTabla = [...equipoTabla]

    for (let i = 0, l = _equipoTabla.length; i < l; i++) {
      if (_equipoTabla[i]?.id === _herramienta.id) {
        _equipoTabla.splice(i, 1)
        break
      }
    }
    setEquipoTabla(_equipoTabla)
  }

  const columns = [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => (
        <Button
          danger
          icon={<CloseOutlined />}
          key={item}
          onClick={() => {
            eliminarHerramienta(item)
          }}
        />
      )
    },
    {
      title: 'Herramienta',
      key: 'nombre',
      dataIndex: 'nombre'
    },
    {
      title: 'Nomeclatura',
      key: 'nombeclatura',
      dataIndex: 'nombeclatura'
    },
    {
      title: 'Serie',
      key: 'serial',
      dataIndex: 'serial',
      render: (_, item) => (
        <Input
          defaultValue={item?.serial ? item?.serial : null}
          onBlur={(e) => {
            agregarSerial(item, e.target.value)
          }}
        />
      )
    }
  ]

  const valorRevenimientoMargen = (revenimiento) => {
    if (revenimiento < 5) {
      form.setFieldsValue({
        reveminientoDisenoMargen: 1.5
      })
    } else if (revenimiento <= 10) {
      form.setFieldsValue({
        reveminientoDisenoMargen: 2.5
      })
    } else if (revenimiento > 10) {
      form.setFieldsValue({
        reveminientoDisenoMargen: 3.5
      })
    }
  }

  const onFinish = async (values) => {
    const {
      salidaPlanta,
      llegadaObra,
      muestraHora,
      finHora
    } = values;

    if (equipoTabla.length < 1) {
      message.warning('Debes agregar el equipo necesario.')
      return
    }

    let _equipoMuestreo = []
    if (editing) {
      const _modeloEquipo = model?.equipo;

      for (let i = 0, l = equipoTabla.length; i < l; i++) {
        const herramienta = _modeloEquipo.find(_herramienta => _herramienta.id === equipoTabla[i]?.id);
        let _obj = {
          'id': (herramienta?.id || null),
          'idVariable': herramienta?.idVariable ? herramienta?.idVariable : equipoTabla[i]?.idVariable,
          'nombre': herramienta?.nombre ? herramienta.nombre : equipoTabla[i]?.nombre,
          'nombeclatura': herramienta?.nombeclatura ? herramienta.nombeclatura : equipoTabla[i]?.nombeclatura,
          'serial': herramienta?.serial ? herramienta.serial : equipoTabla[i]?.serial,
        };
        _equipoMuestreo.push(_obj);
      }
    }
    else {
      for (let i = 0; i < equipoTabla.length; i++) {
        let _obj = {
          'id': null,
          'idVariable': equipoTabla[i]?.idVariable,
          'nombre': equipoTabla[i]?.nombre,
          'nombeclatura': equipoTabla[i]?.nombeclatura,
          'serial': equipoTabla[i]?.serial,
        };
        _equipoMuestreo.push(_obj);
      }
    }

    try {
      setSaveLoading(true)

      let _body = {
        ...values,
        idUsuarioMuestra: tecnicoValue,
        idEvento: idE,//hardcodeado
        salidaPlanta: moment.utc(salidaPlanta).format('YYYY-MM-DD HH:mm:ss'),
        llegadaObra: moment.utc(llegadaObra).format('YYYY-MM-DD HH:mm:ss'),
        muestraHora: moment.utc(muestraHora).format('YYYY-MM-DD HH:mm:ss'),
        finHora: moment.utc(finHora).format('YYYY-MM-DD HH:mm:ss'),
        equipo: _equipoMuestreo,
        eliminados: eliminado,
        horaElaboracion: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        //HARCODEADO
        codigoBarras: "codigoBarras 4",
        numeroLaboratorio: "C123123",
      }

      if (editing) {
        _body.id = id
      }

      const res = await StatusResponse.post("concreto", _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        console.log("estatus correcto")
        let secondsToGo = 2
        const modal = Modal.success({
          title: res?.mensaje,
          cancelButtonProps: { style: { display: 'none' } },
          okButtonProps: { style: { display: 'none' } }
        })
        const timer = setInterval(() => { secondsToGo -= 1 }, 100)
        setTimeout(() => {
          clearInterval(timer)
          modal.destroy()
        }, secondsToGo * 1000)
        history.push(`/eventos/muestras?idEvento=${idE}&idProyecto=${idP}`)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning({
      content: 'Verifica que todos los campos estén correctos',
      style: {
        marginTop: '10vh',
      },
    });
    console.log(values, errorFields, outOfDate);
  };

  const insertarEquipo = (_modeloEquipo) => {
    let _equipo = []
    for (let i = 0, l = _modeloEquipo.length; i < l; i++) {
      let _obj = _modeloEquipo[i]
      _equipo.push(_obj)
    }
    return _equipo
  }

  useEffect(() => {
    if (model) {
      const q = query(collection(firestore, "concretos"), where("folioWeb", "==", model?.folio));
      onSnapshot(q, (querySnapshot) => {
        const concretos = []
        querySnapshot.forEach((doc) => {
          concretos.push(doc.data());
        })
        setEvidencias(concretos[0]?.evidencias)
      })
    }
  })

  useEffect(() => {
    if (editing && model) {
      let _modeloEquipo = model?.equipo
      let _equipo = insertarEquipo(_modeloEquipo)
      setEquipoTabla(_equipo)
      setFormData(model)
      setTecnicoValue(model?.idUsuarioMuestra)
      form.setFieldsValue({
        salidaPlanta: moment.utc(model?.salidaPlanta).local(),
        llegadaObra: moment.utc(model?.llegadaObra).local(),
        muestraHora: moment.utc(model?.muestraHora).local(),
        finHora: moment.utc(model?.finHora).local(),
        reveminientoDisenoMargen: model?.reveminientoDisenoMargen,
      })
    }
  }, [editing, form, model, setFormData]);

  if (modelLoading) return <ViewLoading />

  return (
    <>
      <DefaultLayout
        title={model?.folio ? model?.folio : 'Nueva Muestra de Concreto'}
        breadcrumbItems={breadcrumb}
      >

        <Form
          form={form}
          name="form"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='idUsuarioMuestra'
                label='Usuario Muestra'
                style={{
                  fontWeight: 'bold'
                }}
              >
                <UsuariosSelect
                  setUsuarioValue={setTecnicoValue}
                  defaultValor={model?.idUsuario}
                  usuarioValue={tecnicoValue}
                  dataUsuario={tecnicos}
                  loading={tecnicosLoading}
                />
              </Form.Item>
            </Col>
            {(user?.rol === "Administrador" && editing) ? (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name='folio'
                  label='Folio'
                  style={{
                    fontWeight: 'bold'
                  }}
                >
                  <Input
                  />
                </Form.Item>
              </Col>
            ) : null}
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="elemento"
                label="Elemento"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Select
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {elementos.map(item => (
                    <Option key={item?.id} value={item?.valor}>{item?.valor}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="ubicacion" label="Ubicación" rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Select
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {ubicacion.map(item => (
                    <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="titulo"
                label="&nbsp;"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          
            {user?.rol === 'Administrador' ? (
              <Row gutter={10}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Form.Item
                    name="salidaPlanta"
                    label="Salida Planta"
                    style={{
                      fontWeight: 'bold'
                    }}
                    rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  >
                    <DatePicker
                      showTime
                      locale={locale}
                      style={{ width: "100%" }}
                      format='DD-MM-YYYY HH:mm'
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Form.Item
                    name="llegadaObra"
                    label="Llegada Obra"
                    style={{
                      fontWeight: 'bold'
                    }}
                    rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  >
                    <DatePicker
                      showTime
                      locale={locale}
                      style={{ width: "100%" }}
                      format='DD-MM-YYYY HH:mm'
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Form.Item
                    name="muestraHora"
                    label="Muestreo Hora"
                    style={{
                      fontWeight: 'bold'
                    }}
                    rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  >
                    <DatePicker
                      showTime
                      locale={locale}
                      style={{ width: "100%" }}
                      format='DD-MM-YYYY HH:mm'
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <Form.Item
                    name="finHora"
                    label="Fin Hora"
                    style={{
                      fontWeight: 'bold'
                    }}
                    rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                  >
                    <DatePicker
                      showTime
                      locale={locale}
                      style={{ width: "100%" }}
                      format='DD-MM-YYYY HH:mm'
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
            <Row gutter={10}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  name="salidaPlanta"
                  label="Salida Planta"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <TimePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format='HH:mm'
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  name="llegadaObra"
                  label="Llegada Obra"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <TimePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format='HH:mm'
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  name="muestraHora"
                  label="Muestreo Hora"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <TimePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format='HH:mm'
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Form.Item
                  name="finHora"
                  label="Fin Hora"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <TimePicker
                    locale={locale}
                    style={{ width: "100%" }}
                    format='HH:mm'
                  />
                </Form.Item>
              </Col>
            </Row>
            )}

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="resistenciaDisenoFC"
                label="f'c (kg/cm²)"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="edad"
                label="Edad"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="volumenRemision"
                label="Volumen de Remisión (m³)"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="remision"
                label="Remisión"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="revenimientoDiseno"
                label="Revenimiento Diseño (cm)"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onBlur={(e) => {
                    valorRevenimientoMargen(parseInt(e.target.value))

                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Space direction="horizontal">
                +/-
                <Form.Item
                  name="reveminientoDisenoMargen"
                  label="&nbsp;"
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
                >
                  <InputNumber
                    disabled={true}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="tma"
                label="TMA (mm)"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="proveedor"
                label="Proveedor"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="ingeniero"
                label="Ingeniero"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="contratista"
                label="Contratista"
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{ required: true, message: 'Campo no debe estar vacío' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Title level={4}>Equipo</Title>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                name="herramientas"
                label="Herramientas"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  loading={tecnicosLoading}
                  placeholder="Seleccione una herramienta"
                  style={{ widt: '100%' }}
                  onChange={setIdHerramientaSeleccionada}
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {herramientas?.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="&nbsp;">
                <Button onClick={insertarEnArreglo}>
                  +
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={24}>
              <Form.Item name="equipoConcreto">
                <Table
                  rowKey={"id"}
                  columns={columns}
                  dataSource={equipoTabla}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="desviaciones"
                label="Desviaciones"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="observaciones"
                label="Observaciones"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name="observacionesInternas"
                label="Observaciones Internas"
                style={{
                  fontWeight: 'bold'
                }}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={9}>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {editing ? (
          <>
            <Row>
              <Col>
                <Title level={4}>Evidencias</Title>
              </Col>
            </Row>
            {evidencias && evidencias.length > 0 ? (
              <Image.PreviewGroup>
                {
                  evidencias.map((objeto) =>
                    objeto.tipo === 1 && (
                      <Row>
                        <div>
                          <h3>Imágenes</h3>
                          <Image.PreviewGroup>
                            <Image
                              key={objeto?.pathfirebase}
                              width={200}
                              height={200}
                              src={objeto?.pathfirebase}
                            />
                          </Image.PreviewGroup>
                        </div>
                      </Row>
                    )
                  )
                }
                {
                  evidencias.map((objeto) =>
                    objeto.tipo === 2 && (
                      <Row>
                        <div>
                          <h3>Firmas</h3>
                          <Image.PreviewGroup>
                            <Image
                              key={objeto?.pathfirebase}
                              width={200}
                              height={200}
                              src={objeto?.pathfirebase}
                            />
                          </Image.PreviewGroup>
                        </div>
                      </Row>
                    )
                  )
                }
                {
                  evidencias.map((objeto) =>
                    objeto.tipo === 3 && (
                      <Row>
                        <div>
                          <h3>Audios</h3>
                          <ReactAudioPlayer
                            src={objeto?.pathfirebase}
                            controls
                          />
                        </div>
                      </Row>
                    )
                  )
                }
              </Image.PreviewGroup>
            ) : (
              <div><Empty descriptive="No contiene evidencias." /></div>
            )}
          </>
        ) : null}

      </DefaultLayout>
    </>
  );
}

export default ConcretoForm;