import React, { useState, useMemo, useEffect} from 'react';
import { SimpleTableLayout } from '../../components/layouts';
import { Form, Table, Button } from 'antd';
import { useModels, useQuery, usePagination, useSortColumns } from '../../hooks';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Search } from '../../utilities';

const TablaPruebas = () => {

  const q = useQuery();
  const idP = q.get("idProyecto");

  const history = useHistory();

  const [ searchForm ] = Form.useForm();

  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();

  const [ request, setRequest ] = useState({});
  const [ extraParams, setExtraParams ] = useState({ idProyecto: idP, estado: 'REGISTRADO' });
  const [columns, setColumns] = useState([]);

  const { ordenarValue, columnas } = useSortColumns({columns, ordenar: 'folio-desc'});

  const idProyectoMemo = useMemo(() => ({ id: idP }), [idP]);
  const requestParams = useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: 'cilindro',
        extraParams: extraParams,
        expand: 'concreto',
        ordenar: ordenarValue,
        limite: limite,
        pagina: pagina
      }
    }
  }, [extraParams, limite, ordenarValue, pagina]);

  const [
    cilindros,
    cilindrosLoading,
    // eslint-disable-next-line no-unused-vars
    cilindrosError,
    cilindrosPage,
    // eslint-disable-next-line no-unused-vars
    refreshCilindros
  ] = useModels(request);

  const [
    proyectos
  ] = useModels({
    name: 'proyecto',
    extraParams: idProyectoMemo,
    limite: 1,
  })

  const onSearch = async (search) => {
    const { buscar } = searchForm.getFieldsValue(true);
    let params = { idProyecto: idP, estado: 'REGISTRADO' };
    
    if (buscar){
      params.q = buscar;
    }

    setExtraParams(params);
  };

  const onClear = () => {
    searchForm.resetFields();
    setLimite(10);
    onSearch();
  }

  const breadcrumb = [
    {
      name: "Pruebas de laboratorio",
      to: "/pruebas-laboratorio-unidas"
    },
    {
      name: proyectos[0]?.folio + ' ' + proyectos[0]?.nombre,
      to: `pruebas?idProyecto=${idP}`
    },
  ]

  const columnsContent = useMemo(() => [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      // width: 70,
      align: 'center',
      render: (_, item) => (
        <Button
          key={item}
          type='primary'
          size="large"
          onClick={() => history.push(`/pruebas-laboratorio-unidas/prueba-cilindro?idProyecto=${idP}&id=${item?.id}`)}
        >
          Ensayar
        </Button>
      )
    },
    {
      title: 'Num Lab/Fecha',
      key: 'concreto',
      dataIndex: 'concreto',
      orden: 'Concreto.id',
      render: (_, value) => {
        let _numLab = value?.concreto?.numeroLaboratorio;
        let _fecha = value?.fechaPruebaCampo ? moment(value?.fechaPruebaCampo).format('DD-MM-YYYY') : <span style={{ color: '#c7c3c3' }}>-----</span>;
        return (<>{_numLab}<br />{_fecha}</>)
      }
    },
    {
      title: 'Folio',
      key: 'folio',
      dataIndex: 'folio',
      align: 'center',
      render: (_, value) => value?.folio || <span style={{ color: '#c7c3c3' }}>-----</span>
    },
    {
      title: 'Edad',
      key: 'edad',
      dataIndex: 'edad',
      width: 81,
      align: 'center',
      render: (_, value) => value?.edad || <span style={{ color: '#c7c3c3' }}>-----</span>
    },
    {
      title: 'Ubicación',
      key: 'ubicacion',
      dataindex: 'ubiPileta1',
      align: 'center',
      render: (_, value) => {
        let _ubiPileta1 = value?.ubiPileta1 || <span style={{ color: '#c7c3c3' }}>/</span>;
        let _ubiPileta2 = value?.ubiPileta2 || <span style={{ color: '#c7c3c3' }}>/</span>;
        let _ubiPileta3 = value?.ubiPileta3 || <span style={{ color: '#c7c3c3' }}>/</span>;
        return (<>{_ubiPileta1}-{_ubiPileta2}-{_ubiPileta3}</>);
      }
    }
  ], [history, idP]);

  useEffect(() => {
    setColumns(columnsContent)
  }, [columnsContent])

  useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams])

  useEffect(() => {
    if(cilindrosPage) {
      setTotal(cilindrosPage.total);
      setLimite(cilindrosPage.limite);
    }
  }, [cilindrosPage, setLimite, setTotal])

  return (
    <>
      <SimpleTableLayout
        title='Pruebas de Laboratorio'
        breadcrumbItems={breadcrumb}
        withSearchButton={false}
        children={
          <>
            <Search
              disabledButtons={cilindrosLoading}
              form={searchForm}
              loading={cilindrosLoading}
              onClean={onClear}
              onSearch={onSearch}
            />
            <Table
              dataSource={cilindros}
              columns={columnas}
              loading={cilindrosLoading}
              rowKey='id'
              scroll={{ x: 'max-content' }}
              size='small'
              pagination={configPagination}
            />
          </>
        }
      />
    </>
  );
}

export default TablaPruebas