const vencimientos = [
    {
        id: 0,
        name: 1,
    },
    {
        id: 1,
        name: 7,
    },
    {
        id: 2,
        name: 14,
    },
    {
        id: 3,
        name: 30,
    },
];

export default vencimientos;