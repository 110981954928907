import React, { useState, useMemo } from 'react';
import { DefaultLayout } from '../../../../../../components/layouts';
import { Row, Col, Form, Select, Button, Divider, Table, Modal, TimePicker, message } from 'antd';
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import { useModels, useQuery } from '../../../../../../hooks';
import { ViewLoading } from '../../../../../../components';
import { diametroAltura, edad } from '../../../../../../utilities';
import StatusResponse from '../../../../../../services/statusResponse';
import { useAuth, usePagination } from '../../../../../../hooks';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { renderDateUTC } from '../../../../../../utilities';

const Cilindros = () => {
	const [form] = Form.useForm();
	const { user } = useAuth();
	const { Option } = Select;
	const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();

	const [listaCilindros, setListaCilindros] = useState(null);
	const [diametroAlturaCilindro, setDiametroAlturaCilindro] = useState(null);
	const [saveLoading, setSaveLoading] = useState(false);
	const [cantidadCilindros, setCantidadCilindros] = useState([]);

	const q = useQuery();
	const idCon = q.get('idConcreto');
	const idE = q.get('idEvento');
	const idP = q.get("idProyecto");

	const idProyectoMemo = useMemo(() => ({ id: idP }), [idP]);
	const idEventoMemo = useMemo(() => ({ id: idE }), [idE]);
	const idConcretoMemo = useMemo(() => ({ id: idCon }), [idCon]);

	const edadCilindro = [];
	const listaEditCilindro = [];

	const requestParams = React.useMemo(() => {
		if (pagina === null) {
			return {}
		} else {
			return {
				name: 'cilindro',
				extraParams: {
					estado: 'NO REGISTRADO',
					idConcreto: idCon,
					idEvento: idE,
					idProyecto: idP,
				},
				ordenar: 'folio-asc',
				limite: limite,
				pagina: pagina,
				expand: 'concreto'
			}
		}
	}, [idCon, idE, idP, limite, pagina])

	const [request, setRequest] = React.useState({})

	const [
		models,
		modelsLoading,
		// eslint-disable-next-line no-unused-vars
		modelsError,
		modelsPage,
		refreshModels
	] = useModels(request);

	//Eventos
	const [
		eventos,
	] = useModels({
		name: 'evento',
		ordenar: 'id-asc',
		limite: -1,
		extraParams: idEventoMemo
	});

	//Proyectos
	const [
		proyectos,
	] = useModels({
		name: 'proyecto',
		ordenar: 'id-asc',
		limite: -1,
		extraParams: idProyectoMemo
	});

	//Concretos
	const [
		concretos,
	] = useModels({
		name: 'concreto',
		ordenar: 'id-asc',
		expand: 'equipo',
		limite: -1,
		extraParams: idConcretoMemo
	});

	const cambiarNumeroCilindros = (cantidad) => {
		let lista = []
		for (let i = 1; i <= cantidad; i++) {
			lista.push({
				'id': null,
				"idUsuarioCreo": user.id,
				"idUsuarioRegistro": user.id,
				"numeroEspecimen": i,
				"edad": edadCilindro[i - 1],
				"diametroAltura": diametroAlturaCilindro,
				"fechaPruebaCampo": concretos[0]?.muestraHora,
			})
		}
		setCantidadCilindros(lista)
	}

	const insertarCilindros = () => {
		let lista = []
		
		for (let i = 0; i < cantidadCilindros.length; i++) {
			lista.push({
				'id': null,
				"idUsuarioCreo": user.id,
				"idUsuarioRegistro": user.id,
				"numeroEspecimen": cantidadCilindros[i].numeroEspecimen,
				"edad": form.getFieldValue("cilindro_" + cantidadCilindros[i].numeroEspecimen),
				"diametroAltura": diametroAlturaCilindro,
				"fechaPruebaCampo": concretos[0]?.muestraHora,
				"estado": 'NO REGISTRADO',
				"creado": moment.utc(form.getFieldValue('creado')).format('YYYY-MM-DD HH:mm:ss'),
				"molde": null
			})
		}
		setListaCilindros(lista)
	}

	const breadcrumb = [
		{
			name: 'Proyectos',
			to: '/proyectos'
		},
		{
			name: proyectos[0]?.nombre,
			to: `/eventos?idProyecto=${idP}`
		},
		{
			name: eventos[0]?.titulo,
			to: `/eventos?idProyecto=${idP}`
		},
		{
			name: concretos[0]?.folio,
			to: `/eventos/muestras?idEvento=${idE}&idProyecto=${idP}`
		},
		{
			name: 'Cilindros',
			to: `cilindros?idConcreto=${idCon}&idEvento=${idE}&idProyecto=${idP}`
		}
	];

	const onFinish = async (values) => {
		try {
			let eliminados = []

			for (let i = 0; i < models.length; i++) {
				const _elementoModels = models[i]
				eliminados.push({ 'id': _elementoModels.id })
			}

			let _body = {
				idConcreto: idCon,
				cilindros: listaCilindros,
				eliminados
			}

			const res = await StatusResponse.post('cilindro', _body)
			if (res?.status === 400 && res?.errores !== null) {
				const newArray = Object.values(res?.errores)
				Modal.error({
					title: res?.mensaje,
					content: (
						<div>
							{
								newArray.map((m, i) =>
									<span key={(i + 1)}>- {m}</span>
								)
							}
						</div>
					)
				})
			}
			else if (res?.status === 400 && res?.errores === null) {
				Modal.error({
					title: res?.mensaje
				})
			}
			else if (res?.status === 200) {
				console.log("estatus correcto")
				let secondsToGo = 2
				const modal = Modal.success({
					title: res?.mensaje,
					cancelButtonProps: { style: { display: 'none' } },
					okButtonProps: { style: { display: 'none' } }
				})
				const timer = setInterval(() => { secondsToGo -= 1 }, 100)
				setTimeout(() => {
					clearInterval(timer)
					modal.destroy()
				}, secondsToGo * 1000)
				refreshModels(true, {
					ordenar: 'folio-asc',
					pagina: 1,
					limite: -1
				});
			}
		} catch (error) {
			console.log(error)
		} finally {
			setSaveLoading(false)
		}
	};

	const onFinishFailed = ({ values, errorFields, outOfDate }) => {
		message.warning({
			content: 'Verifica que todos los campos estén correctos',
			style: {
				marginTop: '10vh',
			},
		});
		console.log(values, errorFields, outOfDate);
	};

	const editarCilindro = (row, editDiametro, valorEdit) => {
		let copiaModels = [...models]
		let obj

		for (let i = 0, l = copiaModels.length ; i < l ; i++) {
			if (copiaModels[i]?.id === row?.id) {
				obj = copiaModels[i]
				if (editDiametro) {
					obj.diametroAltura = valorEdit
				}
				else {
					obj.edad = valorEdit
				}
				copiaModels.splice(i, 1)
			}
		}
		copiaModels.push(obj)
		listaEditCilindro.push(...copiaModels)
		onFinishEdit();
	}

	const guardarMolde = (row, _molde) => {
		let copiaModels = [...models];
		let obj;

		for (let i = 0, l = copiaModels.length ; i < l ; i++){
			if(copiaModels[i]?.id === row.id){
				obj = copiaModels[i];
				obj.molde = _molde;

				copiaModels.splice(i, 1)
			}
		}
		copiaModels.push(obj)
		listaEditCilindro.push(...copiaModels)
		onFinishEdit();
	}

	const columns = [
		{
			title: 'Acciones',
			key: 'id',
			dataIndex: 'id',
			width: 100,
			align: 'center',
			render: (_, item) => (
				<><Button
					danger
					icon={<CloseOutlined />}
					key={item}
					onClick={() => {
						modalDelete(item)
					}}
				/>
				</>
			)
		},
		{
			title: 'Folio',
			key: 'folio',
			dataIndex: 'folio'
		},
		{
			title: 'Diametro/Altura',
			key: 'diametroAltura',
			dataIndex: 'diametroAltura',
			render: (_, row) => (
				<Select
					style={{ width: '100%' }}
					optionFilterProp='children'
					defaultValue={row?.diametroAltura}
					onChange={v => {
						const editDiametro = true
						editarCilindro(row, editDiametro, v)
					}}
				>
					{diametroAltura.map(item => (
						<Option key={item?.id} value={item?.id}>{item?.name}</Option>
					))}
				</Select>
			)
		},
		{
			title: 'Edad',
			key: 'edad',
			dataIndex: 'edad',
			render: (_, row) => (
				<Select
					style={{ width: '100%' }}
					optionFilterProp='children'
					defaultValue={row?.edad}
					onChange={v => {
						const editDiametro = false
						editarCilindro(row, editDiametro, v)
					}}
				>
					{edad.map(item => (
						<Option key={item?.id} value={item?.name}>{item?.name}</Option>
					))}
				</Select>
			)
		},
		
		{
			title: 'Molde',
			key: 'concreto',
			dataIndex: 'concreto',
			render: (_, row) => {
				let concreto = encontrarMoldes();
				return(
					<Select
						style={{ width: '100%' }}
						optionFilterProp='children'
						defaultValue={row?.molde}
						onChange={(v) => guardarMolde(row, (v).toString())}
					>
						{concreto.map(item => (
							<Option key={item?.id} value={item?.nombeclatura+''+item?.serial}>{item?.nombeclatura+''+item?.serial}</Option>
						))}
					</Select>
				);
			}
		},
		{
			title: 'Creado',
			key: 'creado',
			dataIndex: 'creado',
			render: (_, row) => row?.creado ? renderDateUTC(row?.creado) : <span style={{ color: '#c7c3c3' }}>-----</span>
		},
	];

	const encontrarMoldes = () => {
		let concreto = concretos[0]?.equipo;
		let obj = [];
		for(let i = 0, l = concretos[0]?.equipo.length ; i < l ; i++){
			if(concreto[i]?.nombeclatura === 'OMC-'){
				obj.push(concreto[i])
			}
		}
		concreto = obj;

		return concreto;
	}

	const onFinishEdit = async () => {
		try {


			let eliminados = []

			let _body = {
				idConcreto: idCon,
				cilindros: listaEditCilindro,
				eliminados
			}

			const res = await StatusResponse.post('cilindro', _body)
			if (res?.status === 400 && res?.errores !== null) {
				const newArray = Object.values(res?.errores)
				Modal.error({
					title: res?.mensaje,
					content: (
						<div>
							{
								newArray.map((m, i) =>
									<span key={(i + 1)}>- {m}</span>
								)
							}
						</div>
					)
				})
			}
			else if (res?.status === 400 && res?.errores === null) {
				Modal.error({
					title: res?.mensaje
				})
			}
			else if (res?.status === 200) {
				console.log("estatus correcto")
				message.success(res?.mensaje, 2)
			}
		} catch (error) {
			console.log(error)
		} finally {
			setSaveLoading(false)
		}
	};

	const modalDelete = (row) => {
		if (!row?.id) return;
		Modal.confirm({
			title: "Eliminar Cilindro",
			content: `¿Está seguro de eliminar el cilindro "${row?.folio}"?`,
			icon: <DeleteOutlined style={{ color: '#ff0000' }} />,
			okText: 'Eliminar',
			okType: 'default',
			okButtonProps: {
				type: 'danger',
			},
			onCancel: () => { },
			cancelText: 'Cancelar',
			onOk: async () => {
				const res = await StatusResponse.delete('cilindro', { id: row?.id })
				return new Promise((resolve, reject) => {
					try {
						if (row.id > 0) {
							if (res && res.status === 200) {
								refreshModels(true, {
									ordenar: 'folio-asc',
									pagina: 1,
									limite: -1
								});
								resolve();
							} else if (res?.status === 400) {
								Modal.error({
									title: "Error: no se logro eliminar el cilindro",
									content: res?.mensaje,
								});
								reject();
							}
							reject();
						}
					} catch (error) {
						console.log(error);
					}
				})
			}
		});
	}

	React.useEffect(() => {
		setRequest(requestParams)
		return () => setRequest({})
	}, [requestParams])

	React.useEffect(() => {
		if(modelsPage) {
			setTotal(modelsPage.total);
			setLimite(modelsPage.limite);
		}
	}, [modelsPage, setLimite, setTotal])

	if (modelsLoading) return <ViewLoading />

	return (
		<DefaultLayout
			breadcrumbItems={breadcrumb}
			title={concretos[0]?.folio}
		>
			<Form
				form={form}
				name="form"
				layout="vertical"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Row gutter={10}>
					<Col
						xs={{ span: 24 }}
						sm={{ span: 24 }}
						md={{ span: 8 }}
						lg={{ span: 4 }}
					>
						<Form.Item
							name='numCilindros'
							label='# de Cilindros'
						>
							<Select
								onChange={cambiarNumeroCilindros}
								placeholder='Cantidad de cilindros'
							>
								<Option value={1} />
								<Option value={2} />
								<Option value={3} />
								<Option value={4} />
								<Option value={5} />
								<Option value={6} />
								<Option value={7} />
								<Option value={8} />
								<Option value={9} />
								<Option value={10} />
								<Option value={11} />
								<Option value={12} />
								<Option value={13} />
								<Option value={14} />
								<Option value={15} />
								<Option value={16} />
								<Option value={17} />
								<Option value={18} />
								<Option value={19} />
								<Option value={20} />
							</Select>
						</Form.Item>
					</Col>
					<Col
						xs={{ span: 24 }}
						sm={{ span: 24 }}
						md={{ span: 8 }}
						lg={{ span: 4 }}
					>
						<Form.Item
							name='diametroAltura'
							label='Diametro/Altura'
							extra={
								(cantidadCilindros.length > 0 && !diametroAlturaCilindro) ?
									message.warning({
										content: 'Seleccione un diametro/antura para continuar',
										style: { marginTop: '35vh' },
										duration: 2
									})
									: null}
						>
							<Select
								style={{ width: '100%' }}
								optionFilterProp='children'
								onChange={(v) => setDiametroAlturaCilindro(v)}
								placeholder='Diametro×Altura'
							>
								{diametroAltura.map(item => (
									<Option key={item?.id} value={item?.id}>{item?.name}</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col
						xs={{ span: 24 }}
						sm={{ span: 24 }}
						md={{ span: 8 }}
						lg={{ span: 4 }}
					>
						<Form.Item
							name="creado"
							label="Hora Elaboración"
						>
							<TimePicker
								locale={locale}
								style={{ width: "100%" }}
								format='HH:mm'
							/>
						</Form.Item>
					</Col>
				</Row>
				<span>
					<h3>Edades</h3>
				</span>
				<Row gutter={10}>
					{cantidadCilindros.map((cilindro) => (
						<Col
							xs={{ span: 24 }}
							sm={{ span: 24 }}
							md={{ span: 8 }}
							lg={{ span: 4 }}
							key={`cilindro-${cilindro?.numeroEspecimen}`}
						>
							<Form.Item
								name={`cilindro_${cilindro?.numeroEspecimen}`}
								label={cilindro?.numeroEspecimen}
								style={{
									fontWeight: 'bold'
								}}
							>
								<Select
									showSearch
									disabled={diametroAlturaCilindro ? false : true}
									style={{ width: '100%' }}
									optionFilterProp='children'
									onChange={(v) => edadCilindro.push(v)}
									placeholder='Edad'
								>
									{edad.map(item => (
										<Option key={item?.id} value={item?.name}>{item?.name}</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					)
					)}
				</Row>
				<Row gutter={10}>
					<Col
						xs={{ span: 24 }}
						sm={{ span: 24 }}
						md={{ span: 8 }}
						lg={{ span: 4 }}
					>
						<Form.Item>
							<Button
								onClick={insertarCilindros}
								type="primary"
								block
								size="large"
								htmlType="submit"
								loading={saveLoading}
							>
								Guardar
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Divider />
			
			<Row>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 12 }}
					lg={{ span: 12 }}
				>
					<p>Hora Elaboración: <b>{concretos[0]?.horaElaboracion ? renderDateUTC(concretos[0]?.horaElaboracion) : <span style={{ color: '#c7c3c3' }}>-----</span> }</b></p>
				</Col>
			</Row>
			<Table
				columns={columns}
				dataSource={models}
				loading={modelsLoading}
				pagination={configPagination}
				rowKey='id'
				scroll={{ x: 'max-content' }}
				size="small"
			/>
		</DefaultLayout>
	)
}

export default Cilindros;