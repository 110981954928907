import React from 'react';
import { Row } from 'antd';

const Pavimentos = () => {

    return(
      <Row>
        Pavimentos
      </Row>
    );
}

export default Pavimentos;