import React from 'react'
import { Form, Table, Modal } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { SimpleTableLayout } from '../../../components/layouts';
import { ActionsButton } from '../../../components';
import { useModels, usePagination } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse';
import { auth } from "../../../services/firebase"
import { signInWithCustomToken } from 'firebase/auth';
import { Search } from '../../../utilities';

const Usuarios = () => {

  const history = useHistory();
  const [searchForm] = Form.useForm();
  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [extraParams, setExtraParams] = React.useState({})
  const requestParams = React.useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: 'usuario',
        ordenar: 'id-desc',
        extraParams: extraParams,
        limite: limite,
        pagina: pagina
      }
    }
  }, [extraParams, limite, pagina])

  const [request, setRequest] = React.useState({})

  const breadcrumb = [
    {
      name: "Administración",
      to: "/administracion"
    },
    {
      name: "Usuarios",
      to: "/administracion/usuarios"
    },
  ]

  const buttonData = [{
    text: 'Usuario',
    to: () => history.push('/administracion/usuarios/nuevo-usuario'),
    props: { disabled: false, type: 'primary' },
    icon: <PlusOutlined />,
  }];

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  const impersonar = async (uid) => {
    const res = await StatusResponse.post('usuario/impersonate', { uid: uid });
    let token = res?.resultado[0].token;

    await signInWithCustomToken(auth, token);
    history.push('/proyectos');
  }

  const columns = [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item}
          options={[
            {
              name: 'Editar',
              onClick: () => history.push(`/administracion/usuarios/usuario?id=${item?.id}`)
            },
            {
              name: 'Eliminar',
              onClick: () => modalDelete(item),
              styleProps: {
                color: '#f5222d'
              }
            },
            {
              name: 'Acceder como Usuario',
              onClick: () => impersonar(item?.uid),
            }
          ]}
        />
      )
    },
    {
      title: 'Nombre',
      key: 'nombre',
      dataIndex: 'nombre'
    },
    {
      title: 'Correo',
      key: 'correo',
      dataIndex: 'correo'
    },
    {
      title: 'Rol',
      key: 'rol',
      dataIndex: 'rol'
    },
  ];

  const modalDelete = (row) => {
    if (!row?.id) return;
    Modal.confirm({
      title: "Eliminar Usuario",
      content: `¿Está seguro de eliminar el usuario "${row?.nombre}"?`,
      icon: <DeleteOutlined style={{ color: '#ff0000' }} />,
      okText: 'Eliminar',
      okType: 'default',
      okButtonProps: {
        type: 'danger',
      },
      onCancel: () => { },
      cancelText: 'Cancelar',
      onOk: async () => {
        const res = await StatusResponse.delete('usuario', { id: row?.id })
        return new Promise((resolve, reject) => {
          try {
            if (row.id > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'id-desc',
                  pagina: 1,
                  limite: -1
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el usuario",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      }
    });
  }

  const onSearch = async (search) => {
    const { buscar } = searchForm.getFieldsValue(true);
    let params = {};
    
    if (buscar){
      params.q = buscar;
    }

    setExtraParams(params);
  };

  const onClear = () => {
    searchForm.resetFields();
    setLimite(10);
    onSearch();
  }

  React.useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams])

  React.useEffect(() => {
    if(modelsPage) {
      setTotal(modelsPage.total);
      setLimite(modelsPage.limite);
    }
  }, [modelsPage, setLimite, setTotal])

  return (
    <>
      <SimpleTableLayout
        title="Usuarios"
        breadcrumbItems={breadcrumb}
        withSearchButton={false}
        children={
          <>
            <Search
              multipleButtons={buttonData}
              disabledButtons={modelsLoading}
              form={searchForm}
              loading={modelsLoading}
              onClean={onClear}
              onSearch={onSearch}
            />
            <Table
              columns={columns}
              dataSource={models}
              loading={modelsLoading}
              pagination={configPagination}
              rowKey='id'
              scroll={{ x: 'max-content' }}
              size="small"
            />
          </>

        }
      />
    </>
  );
}

export default Usuarios;