import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export function usePagination(props) {

  const [pagina, setPagina] = useState(null);
  const [limite, setLimite] = useState(10);
  const [total, setTotal] = useState(0);
  const [rutaAnterior, setRutaAnterior] = useState('');
  const [rutaActual, setRutaActual] = useState('');

  const location = useLocation();

  const onSetPageCallback = React.useCallback(
    async ( page, size ) => {
      setPagina(page);
      setLimite(size);
    }, []
  );

  const configPagination = React.useMemo( () => {
    let size = limite;
    
    return {        
      total: total,
      pageSize: limite,
      current: parseInt(pagina),
      onShowSizeChange: ( _, newSize)  => ( size = newSize ),
      onChange: async (v) => await onSetPageCallback(v, size),
      showTotal: (total, range) => `Total: ${total}`,
      locale: { items_per_page: '/ página' },
      pageSizeOptions: [10, 20, 30].filter( val => val <= total ),
      showSizeChanger: true
    }
  }, [limite, onSetPageCallback, pagina, total] );

  const reiniciarPaginacion = () => {
    localStorage.setItem('paginacion', '{}');
  }

  useEffect(() => {
    if (location) {
      setRutaActual(`${location?.pathname}${location?.search}`);
    }
  }, [location])

  useEffect(() => {
    if(rutaActual && pagina) {
      let _paginacion = {};
      try {
        _paginacion = JSON.parse(localStorage.getItem('paginacion'))
        if (_paginacion === null){
          _paginacion = {};
        }
      } catch (e) {
        console.log(e)
        _paginacion = {};
      }
      if(!_paginacion[rutaActual]){
        _paginacion[rutaActual] = 1;
        setPagina(1);
        localStorage.setItem('paginacion', JSON.stringify(_paginacion));
        return
      } else if (rutaAnterior !== rutaActual){
        setRutaAnterior(rutaActual);
        return
      }
      _paginacion[rutaActual] = pagina;
      localStorage.setItem('paginacion', JSON.stringify(_paginacion));
    }
  }, [rutaActual, pagina, rutaAnterior])

  useEffect(() => {
    if(rutaActual){
      let _paginacion = {}
      try {
        _paginacion = JSON.parse(localStorage.getItem('paginacion'))
        if (_paginacion === null){
          _paginacion = {};
        }
      } catch (e) {
        console.log(e)
        _paginacion = {};
      }

      let _pagina = 1;
      if(_paginacion[rutaActual]){
        _pagina = parseInt(_paginacion[rutaActual]);
      } 
      if (_pagina) {
        setPagina(_pagina)
      } 
    }
  }, [rutaActual]);

  return React.useMemo(() => {
    return {
      configPagination,
      pagina,
      limite,
      total,
      setPagina,
      setLimite,
      setTotal,
      reiniciarPaginacion
    };
  }, [configPagination, limite, pagina, total]);
}