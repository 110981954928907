import React, { useState, useEffect, useCallback } from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { Form, Row, Col, Button, Input, Radio, Modal, Select, Divider, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { useModel, useModels, useQuery } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';
import { ViewLoading } from '../../../components';
import { rol } from '../../../utilities';

const UsuarioForm = () => {

  const [form] = Form.useForm();
  const history = useHistory();
  const q = useQuery();
  const id = q.get("id");
  const editing = !!id;

  const { Option } = Select;
  const { Title } = Typography

  const [rolValue, setRolvalue] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const {
    model,
    modelLoading,
  } = useModel({
    name: "usuario",
    id: id,
  })

  //Plazas
  const [
    plazas,
    ,
    // eslint-disable-next-line no-unused-vars
    plazasError, plazasPage, refreshPlazas
  ] = useModels({
    name: 'plaza',
    ordenar: 'id-asc',
    limite: -1,
  });

  const breadcrumb = [
    {
      name: "Administración",
      to: "/administracion"
    },
    {
      name: "Usuarios",
      to: "/administracion/usuarios"
    },
    {
      name: editing ? model?.nombre : "Nuevo Usuario",
      to: editing ? `usuario?id=${id}` : "nuevo-usuario"
    }
  ]

  const setFormData = useCallback((data) => {
    form.setFieldsValue({
      nombre: data?.nombre,
      correo: data?.correo,
      // password: data?.password,
      rol: data?.rol,
      direccion: data?.direccion,
      rfc: data?.rfc,
      razonSocial: data?.razonSocial,
      telefono: data?.telefono,
      celular: data?.celular,
      estatus: data?.estatus,
      numeroEmpleado: data?.numeroEmpleado,
      idPlaza: data?.idPlaza,
      puesto: data?.puesto
    })
  }, [form])

  const onFinish = async values => {
    const {
      idPlaza,
      razonSocial,
      nombre,
      password,
      confirmarClave
    } = values

    let plazas = [idPlaza]

    try {
      setSaveLoading(true)
      let _body = {
        ...values,
        plazas,
        razonSocial: rolValue !== 'Cliente' ? nombre : razonSocial,
        tokenRecuperacion: 'token recuperacion',
        correoTmp: 'correo temporal',
        // equipoTecnico: _equipoMuestreo
      }

      if(!editing) {
        if (confirmarClave !== password) {
          Modal.warning({
            title: "Error",
            content: "Las contraseñas no coinciden, intente de nuevo.",
          })
          return
        }
        _body.clave = confirmarClave;
        const res = await StatusResponse.post("usuario", _body)
        if (res?.status === 400 && res?.errores !== null) {
          const newArray = Object.values(res?.errores)
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>
                {
                  newArray.map((m, i) =>
                    <span key={(i + 1)}>- {m}</span>
                  )
                }
              </div>
            )
          })
        }
        else if (res?.status === 400 && res?.errores === null) {
          Modal.error({
            title: res?.mensaje
          })
        }
        else if (res?.status === 200) {
          let secondsToGo = 2
          const modal = Modal.success({
            title: res?.mensaje,
            cancelButtonProps: { style: { display: 'none' } },
            okButtonProps: { style: { display: 'none' } }
          })
          const timer = setInterval(() => { secondsToGo -= 1 }, 100)
          setTimeout(() => {
            clearInterval(timer)
            modal.destroy()
          }, secondsToGo * 1000)
          history.push('/administracion/usuarios')
        }
      } else {
        if (password && confirmarClave) {
          if (confirmarClave !== password) {
            Modal.warning({
              title: "Error",
              content: "Las contraseñas no coinciden. Intente de nuevo."
            });
            return;
          }
          await StatusResponse.post("usuario/cambiar-clave", {id: id, clave: password}) 
        }
        _body.id = id;
        delete _body.password;
        delete _body.confirmarClave;
        const res = await StatusResponse.post("usuario", _body);if(res?.status === 400 && res?.errores !== null){
          const newArray = Object.values(res?.errores)
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>
                {
                  newArray.map((m, i) =>
                    <span key={(i + 1)}>- {m}<br/></span>            
                  )
                }
              </div>
            )
          })
        }else if(res?.status === 400 && res?.errores === null){
          Modal.error({
            title: res?.mensaje
          })
        }else if(res?.status === 200){
          let secondsToGo = 2
          const modal = Modal.success({
            title: res?.mensaje,
            cancelButtonProps: { style: { display: 'none' } },
            okButtonProps: { style: { display: 'none' } }
          })
          const timer = setInterval(() => {secondsToGo -= 1}, 1000)
          setTimeout(() => {
            clearInterval(timer)
            modal.destroy()
          }, secondsToGo * 1000)
          history.push("/administracion/usuarios")
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  useEffect(() => {
    if (editing && model) {
      setFormData(model)
      setRolvalue(model.rol)
    }
  }, [editing, model, setFormData]);

  if (modelLoading) return <ViewLoading />

  return (
    <>
      <DefaultLayout
        breadcrumbItems={breadcrumb}
        title={model ? "Editar Información de " + model?.nombre : "Nuevo Usuario"}
      >
        <Form
          form={form}
          name="form"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='nombre'
                label='Nombre'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='correo'
                label='Correo'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='rol'
                label='Rol'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(v) => {
                    setRolvalue(v);
                  }}
                >
                  {rol.map(item => (
                    <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='direccion'
                label='Dirección'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='rfc'
                label='RFC'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='telefono'
                label='Teléfono'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            {rolValue === "Cliente" && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name='razonSocial'
                  label='Razón Social'
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio.'
                  }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='celular'
                label='Celular'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {(rolValue === "Técnico Campo" || rolValue === "Técnico Laboratorio") && (
              <Col
                xs={{ span: 24 }}
                xm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name='numeroEmpleado'
                  label='Número de Empleado'
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio.'
                  }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
            {rolValue !== 'Cliente' && (
              <Col
                xs={{ span: 24 }}
                xm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
              >
                <Form.Item
                  name='idPlaza'
                  label='Plaza'
                  style={{
                    fontWeight: 'bold'
                  }}
                  rules={[{
                    required: true,
                    message: 'Este campo es obligatorio.'
                  }]}
                >
                  <Select
                    showSearch
                    aria-autocomplete='none'
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {plazas.map(item => (
                      <Option key={item?.id} value={item?.id}>{item?.titulo}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {rolValue !== 'Cliente' && (
              <Col
              xs={{ span: 24 }}
              xm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='puesto'
                label='Puesto'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
            )}
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='estatus'
                label='Estatus'
                style={{
                  fontWeight: 'bold'
                }}
                rules={[{
                  required: true,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Radio.Group>
                  <Radio value='activo'>Activo</Radio>
                  <Radio value='inactivo'>Inactivo</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={10}>
            <Title level={5}>{editing ? "Cambiar Contraseña" : "Crear Contraseña"}</Title>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='password'
                label='Contraseña'
                rules={[{
                  required: !editing ? true : false,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name='confirmarClave'
                label='Confirmar Contraseña'
                rules={[{
                  required: !editing ? true : false,
                  message: 'Este campo es obligatorio.'
                }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={9}>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  htmlType="submit"
                  loading={saveLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DefaultLayout>
    </>
  );
}

export default UsuarioForm;