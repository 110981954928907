const tipo = [
    {
        id: 1,
        name: 'IGUALA'
    },
    {
        id: 2,
        name: 'VISITA'
    }
];

export default tipo;