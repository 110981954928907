import React from "react";
import { renderDateUTC } from "../../../utilities";
import { Form, Input, Row, Col, Select, Button, DatePicker, Table } from "antd";
import { SaveOutlined, FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'moment/locale/es-mx';
import { edad } from "../../../utilities";

const TablaComponente = ({ 
  idMuestras,
  cilindros,
  estado,
  form,
  pdfURL,
  saveLoading,
  concretos,
  guardarEdad,
  laboratorios,
  idProyecto,
  setVisible,
  setCilindroValue,
  guardarCilindro,
  cilindrosLoading
}) => {

  const {TextArea} = Input;
  const {Option} = Select;

  const [concretosValue, setConcretosValue] = React.useState([]);

  const columns = [
    {
      title: 'Folio',
      key: 'cilindro',
      dataIndex: 'cilindro',
      render: (_, value) => value?.folio
    },
    {
      title: 'Fecha Prueba Campo',
      key: 'concreto',
      dataIndex: 'concreto',
      render: (value) => renderDateUTC(value?.horaElaboracion)
    },
    {
      title: 'Fecha Prueba Laboratorio',
      key: 'concreto',
      dataIndex: 'concreto',
      render: (_, value) => (
        <>
          <br />
          <Form
            form={form}
            name={'form'}
          >
            <Form.Item
              name={`fechaPruebaLaboratorio_cilindro_${value?.folio}`}
              initialValue={moment.utc(value?.concreto.horaElaboracion).add(value?.edad, 'days').local().format('DD-MM-YYYY HH:mm:ss')}
            >
              <TextArea
                readOnly
                bordered={false}
                autoSize={{ minRows: 2, maxRows: 2 }}
                maxLength={11}
                value={moment.utc(value?.concreto.horaElaboracion).add(form.getFieldValue('edad_cilindro_' + value?.folio), 'days').local().format('DD-MM-YYYY HH:mm:ss')}
              />
            </Form.Item>
          </Form>
        </>
      )
    },
    {
      title: 'Edad de Prueba',
      key: 'edad',
      dataIndex: 'edad',
      align: 'center',
      render: (_, value) => (
        <>
          <Form
            form={form}
            name='form'
            layout="vertical"
          >
            <Row>
              <Col>
                <Form.Item name={'edad_cilindro_' + value?.folio} initialValue={value?.edad}>
                  <Select
                    style={{ width: '100%' }}
                    optionFilterProp='children'
                    disabled={estado === 'NO REGISTRADO' ? false : true}
                    onChange={() => {
                      document.getElementsByName('edad_cilindro_' + value?.folio);
                      form.setFieldsValue({
                        [`fechaPruebaLaboratorio_cilindro_${value?.folio}`]: moment.utc(value?.concreto.horaElaboracion).add(form.getFieldValue('edad_cilindro_' + value?.folio), 'days').local().format('DD-MM-YYYY HH:mm:ss'),
                        [`edad_cilindro_${value?.folio}`]: form.getFieldValue('edad_cilindro_' + value?.folio)
                      })
                      guardarEdad(value);
                    }}
                  >
                    {edad.map(item => (
                      <Option key={item?.id} value={item?.name}>{item?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )
    },
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      render: (_, item) => (
        <Button
          type='primary'
          key={item}
          onClick={() => {
            setCilindroValue(item);
            setVisible(true);
          }}
        >
          Detalle
        </Button>
      )
    }
  ]

  React.useEffect(() => {
    if(idMuestras.length > 0){
      let _concretos = [];
      for (let i = 0, l = idMuestras.length; i < l; i++) {
        const concreto = concretos.find(_concreto => _concreto.id === idMuestras[i]);
        _concretos.push(concreto)
      }
      setConcretosValue(_concretos)
    }
  }, [concretos, idMuestras])

  if(concretosValue.length < 1 || cilindros.length === 0 || cilindrosLoading) return "No hay muestras";
  
  return (
    concretosValue.map(_concreto => (
    <>
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={10}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <p>Muestra: <b>{_concreto?.folio}</b></p>
          </Col>
          <p>Transportado:</p>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Form.Item name={'fechaTransporte_concreto_' + _concreto?.id}>
              <DatePicker
                locale={locale}
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                /* onChange={() => {
                  document.getElementsByName(`fechaTransporte_concreto_${_concreto?.id}`)
                  if(form.getFieldValue('fechaTransporte_concreto_' + _concreto?.id) !== null && form.getFieldValue(`transporta_concreto_${_concreto?.id}`) !== null){
                    guardarDatosTransporte(_concreto)
                  }
                  form.setFieldsValue({[`fechaTransporte_concreto_${_concreto?.id}`]: form.getFieldValue('fechaTransporte_concreto_'+_concreto?.id)})
                }} */
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
          >
            <p>Transporta:</p>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Form.Item name={'transporta_concreto_' + _concreto?.id} initialValue={_concreto?.transporta ? _concreto?.transporta : null}>
              <Input
                disabled={estado === 'NO REGISTRADO' ? false : true}
                // defaultValue={_concreto?.transporta ? _concreto?.transporta : null}
                /* onBlur={() => {
                  document.getElementsByName(`transporta_concreto_${_concreto?.id}`)
                  if(form.getFieldValue('fechaTransporte_concreto_' + _concreto?.id) !== null && form.getFieldValue(`transporta_concreto_${_concreto?.id}`) !== null){
                    guardarDatosTransporte(_concreto)
                  }
                }} */
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <Form.Item>
              {estado === 'NO REGISTRADO' && (
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  size='large'
                  block
                  htmlType="button"
                  onClick={() => guardarCilindro(_concreto?.id)}
                  loading={saveLoading}
                >
                  Guardar
                </Button>
              )}

              {estado === 'REGISTRADO' && (
                <Button
                  type='primary'
                  icon={<FilePdfOutlined />}
                  size='large'
                  block
                  href={`${pdfURL}registro-laboratorio?idConcreto=${_concreto.id}&idProyecto=${idProyecto}`}
                  target='_blank'
                  //onClick={window.open(`https://oestec.api.edesarrollos.info/pdf/reporte-final?idConcreto=${_concreto.id}&idProyecto=${idProyecto}`,"_blank")}
                  loading={saveLoading}
                >
                </Button>
              )}

              {estado === 'DIMENSIONADO LABORATORIO' && (
                <Button
                  type='primary'
                  icon={<FilePdfOutlined />}
                  size='large'
                  block
                  href={`${pdfURL}registro-laboratorio?idConcreto=${_concreto.id}&idProyecto=${idProyecto}`}
                  target='_blank'
                  //onClick={window.open(`https://oestec.api.edesarrollos.info/pdf/reporte-final?idConcreto=${_concreto.id}&idProyecto=${idProyecto}`,"_blank")}
                  loading={saveLoading}
                >
                </Button>
              )}

              {estado === 'PROBADO LABORATORIO' && (
                <Button
                  type='primary'
                  icon={<FilePdfOutlined />}
                  size='large'
                  block
                  href={`${pdfURL}reporte-final?idConcreto=${_concreto.id}&idProyecto=${idProyecto}`}
                  target='_blank'
                  //onClick={window.open(`https://oestec.api.edesarrollos.info/pdf/reporte-final?idConcreto=${_concreto.id}&idProyecto=${idProyecto}`,"_blank")}
                  loading={saveLoading}
                >
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <p>Laboratorio:</p>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <Form.Item
              name={'idLaboratorio_concreto_' + _concreto?.id}
              style={{
                fontWeight: 'bold'
              }}
              initialValue={1}
            >
              <Select
                showSearch
                placeholder={'Seleccione un Laboratorio'}
                style={{ width: "100%" }}
                optionFilterProp="children"
                disabled={estado === 'NO REGISTRADO' ? false : true}
                // defaultValue={valorLaboratorio(_concreto)}
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={() => {
                  document.getElementsByName(`idLaboratorio_concreto_${_concreto?.id}`)
                }}
              >
                {laboratorios.map(item => (
                  <Option key={item.id} value={item.id}>{item.titulo}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <p>Fecha de Elaboración: <b>{renderDateUTC(_concreto?.horaElaboracion)}</b></p>
          </Col>
        </Row>
      </Form>

      <Table
        dataSource={cilindros.filter(cilindro => cilindro.idConcreto === _concreto.id)}
        columns={columns}
        rowKey={"id"}
        scroll={{ x: 'max-content' }}
      />
    </>
    )
  ))
}

export default TablaComponente