const rol = [
    {	
        id: 0,
        name: 'Administrador'
    },	
    {	
        id: 1,
        name: 'Técnico Campo'
    },	
    {	
        id: 2,
        name: 'Técnico Laboratorio'
    },	
    {	
        id: 3,
        name: 'Coordinador General'
    },	
    {	
        id: 4,
        name: 'Cliente'
    }
];

export default rol;