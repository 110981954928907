import React from 'react';
import { Row } from 'antd';

const Suelo = () => {

    return(
      <Row>
        'Suelos'
      </Row>
    );
}

export default Suelo;