import React from 'react';
import { DefaultLayout } from '../../components/layouts';
import { Card, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

const Inicio = () => {

    const { Meta } = Card;
    const history = useHistory();

    const breadcrumb = [
        {
            name: "Administración",
            to: "/administracion"
        }
    ]

    const cards = [
        { 
            id: 0, 
            titulo: 'Usuarios', 
            path: '/administracion/usuarios', 
            img: '/assets/grupo-de-usuarios.png' 
          },
          { 
            id: 1, 
            titulo: 'Empresas', 
            path: '/administracion/empresas', 
            img: '/assets/empresa.png' 
          },
          { 
            id: 2, 
            titulo: 'Plazas', 
            path: '/administracion/plazas', 
            img: '/assets/plaza.png' 
          },
          { 
            id: 3, 
            titulo: 'Laboratorios', 
            path: '/administracion/laboratorios', 
            img: '/assets/laboratorio.png' 
          },
          { 
            id: 4, 
            titulo: 'Variables', 
            path: '/administracion/variables', 
            img: '/assets/lapiz.png' 
          },
    ]

    return (
        <>
        <DefaultLayout 
            breadcrumbItems={breadcrumb}
            className="site-card-wrapper"
            title={"Administración"}
            >
            <Row gutter={{ xs: 2, sm: 1, md: 2, lg: 10, xxl: 10 }}>
                {cards.map((item) => (
                    <Col
                        key={item.id}
                        className='gutter-row'
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        lg={{ span: 4, offset: 0 }}
                        xxl={{ span: 4, offset: 0 }}
                    >
                        <Card
                            onClick={() => history.push(item.path)}
                            hoverable
                            style={{width: "100%"}}
                            cover={
                                <img style={{padding: 50 }} alt='example' src={item?.img} />
                            }
                        >
                            <Meta style={{ textAlign: 'center'}} title={item?.titulo} />
                        </Card>
                    </Col>
                ))}
            </Row>
        </DefaultLayout>
        </>
    );
}

export default Inicio;