import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionsButton } from '../../../../components';
import { Row, Col, Button, Divider, Table, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { useModels, useQuery, useAuth, usePagination, useSortColumns } from '../../../../hooks';
import StatusResponse from '../../../../services/statusResponse';
import { renderDateUTC } from '../../../../utilities';

const Concreto = () => {

  const { user } = useAuth();
  const history = useHistory();
  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [ copiaConcreto, setCopiaConcreto ] = useState([]);
  const [ columns, setColumns] = useState([]);
  const { ordenarValue, columnas } = useSortColumns({columns, ordenar: 'id-desc'});

  const q = useQuery();
  const id = q.get("idEvento");
  const idP = q.get("idProyecto");

  const requestParams = React.useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: `concreto`,
        extraParams: {
          idEvento: id,
          idProyecto: idP
        },
        ordenar: ordenarValue,
        limite: limite,
        pagina: pagina,
        expand: 'usuarioMuestra'
      }
    }
  }, [id, idP, limite, pagina, ordenarValue])

  const [request, setRequest] = useState({})

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  const modalDelete = React.useCallback((row) => {
    if (!row?.id) return;
    Modal.confirm({
      title: "Eliminar Muestra",
      content: `¿Está seguro de eliminar la muestra "${row?.folio}"?`,
      icon: <DeleteOutlined style={{ color: '#ff0000' }} />,
      okText: 'Eliminar',
      okType: 'default',
      okButtonProps: {
        type: 'danger',
      },
      onCancel: () => { },
      cancelText: 'Cancelar',
      onOk: async () => {
        const res = await StatusResponse.delete('concreto', { id: row?.id })
        return new Promise((resolve, reject) => {
          try {
            if (row.id > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'id-desc',
                  pagina: 1,
                  limite: -1
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el laboratorio",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      }
    });
  }, [refreshModels])

  const columnsContent = React.useMemo(() => [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => {
        if (user?.rol === 'Administrador' || user?.rol === 'Coordinador General') {
          return (
            <ActionsButton
              key={item}
              options={[
                {
                  name: 'Editar',
                  onClick: () => history.push(`muestras/concreto?id=${item?.id}&idEvento=${id}&idProyecto=${idP}`)
                },
                {
                  name: 'Pruebas de Campo',
                  onClick: () => history.push(`muestras/pruebas-de-campo?idConcreto=${item?.id}&idEvento=${id}&idProyecto=${idP}`)
                },
                {
                  name: 'Cilindros',
                  onClick: () => history.push(`muestras/cilindros?idConcreto=${item?.id}&idEvento=${id}&idProyecto=${idP}`)
                },
                {
                  name: 'Eliminar',
                  onClick: () => modalDelete(item),
                  styleProps: {
                    color: '#f5222d'
                  }
                },
              ]}
            />
          );
        } else {
          return (
            <ActionsButton
              key={item}
              options={[
                {
                  name: 'Editar',
                  onClick: () => history.push(`muestras/concreto?id=${item?.id}&idEvento=${id}&idProyecto=${idP}`)
                },
                {
                  name: 'Pruebas de Campo',
                  onClick: () => history.push(`muestras/pruebas-de-campo?idConcreto=${item?.id}&idEvento=${id}&idProyecto=${idP}`)
                },
                {
                  name: 'Cilindros',
                  onClick: () => history.push(`muestras/cilindros?idConcreto=${item?.id}&idEvento=${id}&idProyecto=${idP}`)
                },
                {
                  name: 'Eliminar',
                  onClick: () => modalDelete(item),
                  styleProps: {
                    color: '#f5222d'
                  }
                },
              ]}
            />
          );
        }
      },
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Folio Muestra',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Fecha de Muestreo',
      key: 'muestraHora',
      dataIndex: 'muestraHora',
      render: (_, item) => renderDateUTC(item?.muestraHora)
    },
    {
      title: 'Usuario Muestra',
      key: 'usuarioMuestra',
      dataIndex: 'usuarioMuestra',
      render: (value) => value?.nombre
    },
    {
      title: 'Remisión',
      key: 'remision',
      dataIndex: 'remision'
    },
  ], [history, id, idP, modalDelete, user?.rol]);

  const hacerCopia = React.useCallback(() => {
    setCopiaConcreto(models);
  },[models])

  useEffect(() => {
    setColumns(columnsContent)
  }, [columnsContent])

  useEffect(() => {
    hacerCopia()
  }, [hacerCopia])

  useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [models, requestParams])

  useEffect(() => {
    if(modelsPage) {
      setTotal(modelsPage.total);
      setLimite(modelsPage.limite);
    }
  }, [modelsPage, setLimite, setTotal])

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => {
              history.push(`/eventos/muestras/nuevo-concreto?idEvento=${id}&idProyecto=${idP}`);
            }
            }
          >
            Nuevo
          </Button>
          <Divider />
        </Col>
      </Row>
      <Table
        columns={columnas}
        dataSource={copiaConcreto}
        loading={modelsLoading}
        pagination={configPagination}
        rowKey='id'
        scroll={{ x: 'max-content' }}
        size="small"
      />
    </>
  );
}

export default Concreto;