import React from 'react'
import { DefaultLayout } from '../../../../components/layouts'
import { Row, Col, Tabs } from 'antd'
import { useQuery } from '../../../../hooks'
import { Dimencionamientos, Cabeceo, Ensayos } from './forms'
import moment from 'moment';
import { Link } from 'react-router-dom'

const PruebasDia = () => {

  const q = useQuery();
  const fecha = q.get('fecha');
  const tab = q.get("tab");
  const { TabPane } = Tabs;

  const breadcrumb = [
    {
      name: "Pruebas de laboratorio",
      to: "/pruebas-por-fecha"
    },
    {
      name: `Pruebas del día ${moment(fecha).format('DD-MM-YYYY')}`,
      to: `pruebas-dia?fecha=${fecha}`
    },
  ]

  const formsPruebas = [
    {
      key: 1,
      title: 'Dimensionamientos',
      component: <Dimencionamientos fechaVencimiento={moment(fecha)} />
    },
    {
      key: 2,
      title: 'Cabeceos',
      component: <Cabeceo fechaVencimiento={moment(fecha)} />
    },
    {
      key: 3,
      title: 'Ensayos',
      component: <Ensayos fechaVencimiento={moment(fecha)} />
    },
  ];

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={`Pruebas del día ${moment(fecha).format('DD-MM-YYYY')}`}
    >
      <Row>
        <Col span={24}>
          <Tabs>
            {formsPruebas?.map((item) => (
              <TabPane tab={<Link to={"/pruebas-de-laboratorio/pruebas?fecha=" + fecha + "&tab=" + item?.key}>{item?.title}</Link>} key={item?.key}>
                <React.Fragment key={item.key}>
                  {parseInt(tab) === item?.key ? item?.component : null}
                </React.Fragment>
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </DefaultLayout>
  )
}

export default PruebasDia