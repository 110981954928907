import Dimencionamientos from "./Dimencionamientos";
import Cabeceo from "./Cabeceo";
import Ensayos from "./Ensayo";

const formsPruebas = [
    {
        key: 1,
        title: 'Dimensionamientos',
        component: <Dimencionamientos />
    },
    {
        key: 2,
        title: 'Cabeceos',
        component: <Cabeceo />
    },
    {
        key: 3,
        title: 'Ensayos',
        component: <Ensayos />
    },
];

export default formsPruebas;