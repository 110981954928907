import React from "react";
import { Space, Spin } from "antd";

const AppLoading = (props) => {
  const { text, loading, children: ChildComponents } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Space direction="vertical">
        <Spin
          spinning={loading}
          delay={5}
          size="large"
          tip={text || "Cargando aplicación..."}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {loading ? <div style={{ height: "100vh" }} /> : ChildComponents}
        </Spin>
      </Space>      
    </div>
  );
};

export default AppLoading;