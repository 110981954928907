import React from 'react';
import { Form, Table, Modal } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SimpleTableLayout } from '../../../components/layouts';
import { ActionsButton } from '../../../components';
import { useHistory } from 'react-router-dom';
import { useModels, usePagination } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';
import { Search } from '../../../utilities';

const Laboratorios = () => {

  const history = useHistory();
  const [searchForm] = Form.useForm();
  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [ extraParams, setExtraParams ] = React.useState({});
  const requestParams = React.useMemo(() => {
    if (pagina === null) {
      return{}
    } else {
      return {
        name: 'laboratorio',
        ordenar: 'id-desc',
        extraParams: extraParams,
        limite: limite,
        pagina: pagina
      }
    }
  }, [extraParams, limite, pagina])

  const [request, setRequest] = React.useState({})

  const breadcrumb = [
    {
      name: "Administración",
      to: "/administracion"
    },
    {
      name: "Laboratorios",
      to: "/administracion/laboratorios"
    },
  ]

  const buttonData = [{
    text: 'Laboratorio',
    to: () => history.push('/administracion/laboratorios/nuevo-laboratorio'),
    props: { disabled: false, type: 'primary' },
    icon: <PlusOutlined />,
  }];

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  const columns = [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, row) => (
        <ActionsButton
          options={[
            {
              name: 'Editar',
              onClick: () => history.push(`/administracion/laboratorios/laboratorio?id=${row?.id}`)
            },
            {
              name: 'Eliminar',
              onClick: () => modalDelete(row),
              styleProps: {
                color: '#f5222d'
              }
            },
          ]}
        />
      )
    },
    {
      title: 'Título',
      key: 'titulo',
      dataIndex: 'titulo'
    },
    {
      title: 'Descripción',
      key: 'descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'Clave',
      key: 'claveLaboratorio',
      dataIndex: 'claveLaboratorio'
    },
  ];

  const onSearch = async (search) => {

    const { buscar } = searchForm.getFieldsValue(true);
    let params = {};
    if (buscar){
      params.q = buscar;
    }

    setExtraParams(params);
  };

  const onClear = () => {
    searchForm.resetFields();
    setLimite(10);
    onSearch();
  }

  const modalDelete = (row) => {
    if (!row?.id) return;
    Modal.confirm({
      title: "Eliminar Laboratorio",
      content: `¿Está seguro de eliminar el laboratorio "${row?.titulo}"?`,
      icon: <DeleteOutlined style={{ color: '#ff0000' }} />,
      okText: 'Eliminar',
      okType: 'default',
      okButtonProps: {
        type: 'danger',
      },
      onCancel: () => { },
      cancelText: 'Cancelar',
      onOk: async () => {
        const res = await StatusResponse.delete('laboratorio', { id: row?.id })
        return new Promise((resolve, reject) => {
          try {
            if (row.id > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'id-desc',
                  pagina: 1,
                  limite: -1
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar el laboratorio",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      }
    });
  }

  React.useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams])

  React.useEffect(() => {
    if (modelsPage) {
      setTotal(modelsPage.total);
      setLimite(modelsPage.limite);
    }
  }, [modelsPage, setLimite, setTotal])

  return (
    <>
      <SimpleTableLayout
        title="Laboratorios"
        breadcrumbItems={breadcrumb}
        withSearchButton={false}
        children={
          <>
            <Search
              multipleButtons={buttonData}
              disabledButtons={modelsLoading}
              form={searchForm}
              loading={modelsLoading}
              onClean={onClear}
              onSearch={onSearch}
            />
            <Table
              columns={columns}
              dataSource={models}
              loading={modelsLoading}
              pagination={configPagination}
              rowKey='id'
              scroll={{ x: 'max-content' }}
              size="small"
            />
          </>

        }
      />
    </>
  );
}

export default Laboratorios;