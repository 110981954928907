import React from 'react'
import { Select } from 'antd'

const PlazaSelect = ({
    setPlazaValue,
    plazaValue,
    isDisabled = false,
    isLoading,
    dataPlaza,
    defaultPlaza
}) => {
    const { Option } = Select;

    const handleChange = (value) => {
        setPlazaValue(value)
    }

    return (
        <Select
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={isLoading}
            disabled={isDisabled}
            placeholder="Seleccione una plaza"
            style={{ widt: '100%' }}
            onChange={handleChange}
            value={plazaValue}
            defaultValue={defaultPlaza}
            filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
        >
            {dataPlaza?.map( item => (
                <Option key={item?.id} value={item?.id}>{item?.titulo}</Option>
            ))}
        </Select>
    )
}

export default PlazaSelect