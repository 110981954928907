import React, { useMemo } from 'react';
import { DefaultLayout } from '../../../../components/layouts';
import { Form, Row, Col, Tabs } from 'antd';
import formsMuestras from './muestras';
import { useModels, useQuery } from '../../../../hooks';

const MuestreoClientes = () => {

  const [form_muestreo] = Form.useForm();
  const { TabPane } = Tabs;

  const q = useQuery();
  const idP = q.get("idProyecto");
  const idE = q.get("idEvento")

  const idEventoMemo = useMemo(() => ({ id: idE }), [idE])
  const idProyectoMemo = useMemo(() => ({ id: idP }), [idP])

  //Eventos
  const [
    eventos,
    // eslint-disable-next-line no-unused-vars
    eventosError, eventosPage, refreshEventos
  ] = useModels({
    name: 'evento',
    ordenar: 'id-asc',
    limite: -1,
    extraParams: idEventoMemo
  });

  //Proyectos
  const [
    proyectos,
    // eslint-disable-next-line no-unused-vars
    proyectosError, proyectosPage, refreshProyectos
  ] = useModels({
    name: 'proyecto',
    ordenar: 'id-asc',
    limite: -1,
    extraParams: idProyectoMemo
  });

  const breadcrumb = [
    {
      name: "Proyectos",
      to: "/proyectos"
    },
    {
      name: proyectos[0]?.nombre,
      to: `/proyectos`
    },
    {
      name: "Muestreos",
      to: `/proyectos/eventos/muestras?idEvento=${idE}&idProyecto=${idP}`
    },
  ]

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      name={form_muestreo}
      nameClass={false}
      title={"Muestreos de " + eventos[0]?.titulo}
    >
      <Row gutter={10}>
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            {formsMuestras?.map((item) => (
              <TabPane tab={item.title} key={item.key} className=''>
                <React.Fragment key={item.key}>
                  {item.component}
                </React.Fragment>
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </DefaultLayout>
  );
}

export default MuestreoClientes;