const estados = [
    {
        id: 0,
        name: 'NO REGISTRADO'
    },
    {
        id: 1,
        name: 'REGISTRADO'
    },
    {
        id: 2,
        name: 'DIMENSIONADO LABORATORIO'
    },
    {
        id: 3,
        name: 'PROBADO LABORATORIO'
    },
]

export default estados;