import { Content } from "antd/lib/layout/layout";
import { Col, Row, Button } from "antd";
import {BreadCrumb } from "../";
import { Helmet } from 'react-helmet';

const DefaultLayout = ({
  children,
  breadcrumbItems,
  buttonData,
  title,
  breadcrumbDisable = false,
}) => {
  const styles = {
    padding: {
      marginLeft: 15,
      marginRight: 15,
    },
    content: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      minHeight: 280,
      borderRadius: 5,
    },
    button: {
      display: 'flex',
      alignItems: 'center'
    }
  };

  return (
    <div style={styles.padding}>
      <Helmet><title>{title || "Página sin titulo"}</title></Helmet>

      <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }} style={{ marginTop: 15, marginBottom: 15 }}>
        <Col
          className="gutter-box"
          xs={{ span: 24 }}
          sm={{ span: 18 }}
          md={{ span: 19 }}
          lg={{ span: 20 }}
        >
          {!breadcrumbDisable && <BreadCrumb breadcrumbItems={breadcrumbItems} />}
        </Col>
        <Col
          style={ styles.button }
          className="gutter-box"
          xs={{ span: 24 }}
          sm={{ span: 6 }}
          md={{ span: 5 }}
          lg={{ span: 4 }}
        >
          {Boolean(buttonData) && (
            <Button
              block
              onClick={buttonData.to ? buttonData.to : undefined}
              {...buttonData.props}
            >
              {buttonData.text} {buttonData.icon}
            </Button>
          )}
        </Col>
      </Row>

      <Content className="site-layout-background" style={styles.content}>
        {children}
      </Content>
    </div>
  );
};

export default DefaultLayout;