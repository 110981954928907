import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Table, InputNumber, Button, Modal, Radio, Select, Typography, Descriptions, message } from 'antd';
import { EyeOutlined, SaveOutlined } from '@ant-design/icons'
import { useModels, useQuery, usePagination } from '../../../../hooks';
import moment from 'moment'
import { ViewLoading } from '../../../../components';
import StatusResponse from '../../../../services/statusResponse';

const Dimencionamientos = () => {

  const [form] = Form.useForm();
  const { Column } = Table;
  const { Option } = Select;
  const { Title } = Typography;

  const q = useQuery();
  const idP = q.get("idProyecto");
  
  let _diametroA = { 'folioCilindro': null, 'diametro': null }
  let _diametroB = { 'folioCilindro': null, 'diametro': null }

  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [request, setRequest] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [copiaCilindros, setCopiaCilindros] = useState([]);
  const [visible, setVisible] = useState(false);
  const [cilindroValue, setCilindroValue] = useState(null);

  const idProyectoCilindroParams = React.useMemo(() => ({ idProyecto: idP, estado: 'REGISTRADO' }), [idP]);
  const idPadreParams = React.useMemo(() => ({ idPadre: 6 }), []);
  const idProyectoParams = React.useMemo(() => ({ id: idP }), [idP]);
  const requestParams = React.useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: 'cilindro',
        extraParams: idProyectoCilindroParams,
        expand: 'concreto',
        ordenar: 'id-asc',
        limite: limite,
        pagina: pagina,
      }
    }
  }, [idProyectoCilindroParams, limite, pagina])

  //Cilindros
  const [
    cilindros,
    cilindrosLoading,
    // eslint-disable-next-line no-unused-vars
    cilindrosError,
    cilindrosPage,
    refreshCilindros
  ] = useModels(request);

  //Variables
  const [
    variables,
  ] = useModels({
    name: 'variable',
    extraParams: idPadreParams,
    ordenar: 'id-asc',
    limite: -1,
  });

  // Proyecto
  const [
    proyectos,
  ] = useModels({
    name: 'proyecto',
    extraParams: idProyectoParams,
    ordenar: 'id-asc',
    limite: -1,
  });

  const hacerCopia = React.useCallback(() => {
    setCopiaCilindros(cilindros)
  }, [cilindros]);

  const modalConfirmar = (values) => {
    Modal.confirm({
      title: 'Guardar dimensionamiento',
      icon: null,
      okText: 'Guardar',
      onOk: () => { onFinishDimensiones() },
      cancelText: 'Cancelar',
      onCancel: () => { },
      content: <ContentModal row={values} />,
      closable: true,
      width: 1000
    });
  }

  const ContentModal = ({ row }) => {
    return (
      <div>
        <Descriptions
          bordered
          column={2}
          size='small'
          title='Una vez guardado no podrá editarse la información.'
        >
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='Defecto de Laboratorio/Manipulación'>{valorDefecto()}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='Masa (kg)'>{form.getFieldValue(`masa_${cilindroValue?.id}`)}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='Diametro (cm)'>{form.getFieldValue(`diametroA_${cilindroValue?.id}`)}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='Diametro (cm)'>{form.getFieldValue(`diametroB_${cilindroValue?.id}`)}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='Altura (cm)'>{form.getFieldValue(`alturaA_${cilindroValue?.id}`)}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='Altura (cm)'>{form.getFieldValue(`alturaB_${cilindroValue?.id}`)}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='Área'>{form.getFieldValue(`area_${cilindroValue?.id}`)}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fonrWeight: "bold" }} label='¿Será Cabeceado?'>{form.getFieldValue(`cabeceo_${cilindroValue?.id}`)}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  const valorDefecto = () => {
    const variable = variables.find(_variable => _variable.id === form.getFieldValue(`idDefectoLaboratorio_${cilindroValue?.id}`));
    const defecto = '' + variable?.valor + ' - ' + variable?.nombre;
    return defecto;
  }

  const onFinishDimensiones = async (values) => {
    try {
      setSaveLoading(true)

      let _body = {
        id: cilindroValue?.id,
        alturaA: form.getFieldValue(`alturaA_${cilindroValue?.id}`),
        alturaB: form.getFieldValue(`alturaB_${cilindroValue?.id}`),
        area: form.getFieldValue(`area_${cilindroValue?.id}`),
        cabeceo: form.getFieldValue(`cabeceo_${cilindroValue?.id}`),
        diametroA: form.getFieldValue(`diametroA_${cilindroValue?.id}`),
        diametroB: form.getFieldValue(`diametroB_${cilindroValue?.id}`),
        idDefectoLaboratorio: form.getFieldValue(`idDefectoLaboratorio_${cilindroValue?.id}`),
        masa: form.getFieldValue(`masa_${cilindroValue?.id}`)
      }

      const res = await StatusResponse.post('pruebas-laboratorio/dimensionamiento', _body)
      if (res?.status === 400 && res?.errores !== null) {
        const newArray = Object.values(res?.errores)
        Modal.error({
          title: res?.mensaje,
          content: (
            <div>
              {
                newArray.map((m, i) =>
                  <span key={(i + 1)}>- {m}</span>
                )
              }
            </div>
          )
        })
      }
      else if (res?.status === 400 && res?.errores === null) {
        Modal.error({
          title: res?.mensaje
        })
      }
      else if (res?.status === 200) {
        console.log("estatus correcto");
        setVisible(false)
        refreshCilindros();
        message.success(res?.mensaje, 2);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaveLoading(false)
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning({
      content: 'Verifica que todos los campos esten correctos.',
      style: {
        marginTop: '10vh',
      },
    });
  };

  const calcularArea = (valor, tipoDiametro, row) => {
    let _area = [...copiaCilindros]
    for (let i = 0; i < copiaCilindros.length; i++) {
      if (copiaCilindros[i]?.folio === row?.folio) {
        if (tipoDiametro) {
          _diametroA.folioCilindro = row?.folio;
          _diametroA.diametro = valor
        }
        else {
          _diametroB.folioCilindro = row?.folio;
          _diametroB.diametro = valor;
        }

        if (_area[i]?.diametroA !== null && (_diametroA.folioCilindro === null && _diametroA.diametro === null)) {
          _diametroA.folioCilindro = _area[i]?.folio;
          _diametroA.diametro = _area[i]?.diametroA;
        }

        if (_area[i]?.diametroB !== null && (_diametroB.folioCilindro === null && _diametroB.diametro === null)) {
          _diametroB.folioCilindro = _area[i]?.folio;
          _diametroB.diametro = _area[i]?.diametroB;
        }

        if (_diametroA.folioCilindro === _diametroB.folioCilindro) {
          if (_diametroA.diametro !== undefined &&
            _diametroA.diametro !== null &&
            _diametroB.diametro !== undefined &&
            _diametroB.diametro !== null) {
            let area = parseFloat((_diametroA.diametro + _diametroB.diametro) / 2)

            _area[i].diametroA = _diametroA.diametro
            _area[i].diametroB = _diametroB.diametro
            if (_area[i]?.folio === _diametroA.folioCilindro && _area[i]?.folio === _diametroB.folioCilindro) {
              _area[i].areaNominal = parseFloat((((area * area) * 3.14159265359) / 4).toFixed(2))
            }
          }
        }
      }
    }
    // eslint-disable-next-line array-callback-return
    _area.map((cilindro) => {
      if (cilindro.folio === row.folio && cilindro.areaNominal !== null) {
        form.setFieldsValue({
          [`area_${row.id}`]: cilindro.areaNominal
        })
      }
    })
  }

  useEffect(() => {
    hacerCopia()
  }, [hacerCopia])


  useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams])

  useEffect(() => {
    if(cilindrosPage) {
      setTotal(cilindrosPage.total);
      setLimite(cilindrosPage.limite);
    }
  }, [cilindrosPage, setLimite, setTotal])

  if (cilindrosLoading) return <ViewLoading />

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            dataSource={copiaCilindros}
            loading={cilindrosLoading}
            rowKey='id'
            scroll={{ x: 'max-content' }}
            size="small"
            pagination={configPagination}
          >
            <Column
              title='Num Lab/Fecha'
              key='id'
              dataindex='id'
              render={(_, value) => {
                let _numLab = value?.concreto?.numeroLaboratorio;
                let _fecha = value?.fechaPruebaCampo ? moment(value?.fechaPruebaCampo).format('DD-MM-YYYY') : <span style={{ color: '#c7c3c3' }}>-----</span>;
                return (<>{_numLab}<br />{_fecha}</>)
              }}
            />
            <Column
              title='Folio'
              key='folio'
              dataindex='folio'
              width={81}
              align='center'
              render={(_, value) => value?.folio || <span style={{ color: '#c7c3c3' }}>-----</span>}
            />
            <Column
              title='Edad'
              key='edad'
              dataindex='edad'
              align='center'
              render={(_, value) => value?.edad || <span style={{ color: '#c7c3c3' }}>-----</span>}
            />
            <Column
              title='Ubicación'
              key='id'
              dataindex='id'
              align='center'
              render={(_, value) => {
                let _ubiPileta1 = value?.ubiPileta1 || <span style={{ color: '#c7c3c3' }}>/</span>;
                let _ubiPileta2 = value?.ubiPileta2 || <span style={{ color: '#c7c3c3' }}>/</span>;
                let _ubiPileta3 = value?.ubiPileta3 || <span style={{ color: '#c7c3c3' }}>/</span>;
                return (<>{_ubiPileta1}-{_ubiPileta2}-{_ubiPileta3}</>);
              }}
            />
            <Column
              title='Acciones'
              key='id'
              dataIndex='id'
              width={130}
              align='center'
              render={(_, item) => (
                <>
                  <Button
                    icon={<EyeOutlined />}
                    type='primary'
                    key={item}
                    onClick={() => {
                      setCilindroValue(item);
                      setVisible(true);
                    }}
                    loading={saveLoading}
                  >
                    Detalle
                  </Button>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>

      <Modal
        title={proyectos[0]?.nombre + '. Cilindro ' + cilindroValue?.folio}
        visible={visible}
        okText='Guardar'
        okButtonProps={{
          form: 'form', key: 'submit', htmlType: 'submit', icon: <SaveOutlined />
        }}
        cancelText='Cancelar'
        onCancel={() => setVisible(false)}
        footer={[
          <Form
            form={form}
            name='form'
            layout='vertical'
            onFinishFailed={onFinishFailed}
            onFinish={modalConfirmar}
          >
            <Row gutter={10} justify='end'>
              <Col span={6}>
                <Button
                  block
                  size="large"
                  onClick={() => setVisible(false)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={9}>
                <Form.Item>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    block
                    size="large"
                    htmlType="submit"
                    loading={saveLoading}
                  >
                    Guardar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ]}
      >
        <Form
          form={form}
          name='form'
          layout='vertical'
          onFinish={onFinishDimensiones}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name={`idDefectoLaboratorio_${cilindroValue?.id}`}
                label='Defecto de Laboratorio/Manipulación'
                rules={[{ required: true, message: 'Campo Defecto de Laboratorio/Manipulación no debe estar vacío' }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  placeholder='Seleccionar defecto'
                // onChange={(v) => asignarDefecto(value, v)}
                >
                  {variables.map(item => (
                    <Option key={item?.id} value={item?.id}>{item?.valor} - {item?.nombre}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Title level={5}>{`Diámetro`}</Title>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`diametroA_${cilindroValue?.id}`}
                label='cm'
                rules={[{ required: true, message: 'Valor requerido para calcular área' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onBlur={(e) => {
                    const tipoDiametro = true;
                    calcularArea(parseFloat(e.target.value), tipoDiametro, cilindroValue)
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`diametroB_${cilindroValue?.id}`}
                label='cm'
                rules={[{ required: true, message: 'Valor requerido para calcular área' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onBlur={(e) => {
                    const tipoDiametro = false;
                    calcularArea(parseFloat(e.target.value), tipoDiametro, cilindroValue)
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`area_${cilindroValue?.id}`}
                label='Área (cm²)'
              >
                <InputNumber
                  readOnly
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Title level={5}>{`Altura`}</Title>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`alturaA_${cilindroValue?.id}`}
                label='cm'
                rules={[{ required: true, message: 'Altura no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`alturaB_${cilindroValue?.id}`}
                label='cm'
                rules={[{ required: true, message: 'Altura no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`masa_${cilindroValue?.id}`}
                label='Masa (kg)'
                rules={[{ required: true, message: 'Campo Masa no debe estar vacío' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <Form.Item
                name={`cabeceo_${cilindroValue?.id}`}
                label='¿Será cabeceado?'
                rules={[{ required: true, message: 'Debe seleccionar una opción' }]}
              >
                <Radio.Group>
                  <Radio value={'SI'}>Sí</Radio>
                  <Radio value={'NO'}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Dimencionamientos;