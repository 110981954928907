import React from 'react';
import { useQuery } from '../../hooks';
import NotFound from '../shared/NotFound';

const MuestraQR = () => {

  document.querySelector("meta")?.remove();
  const q = useQuery();
  const idC = q.get("idConcreto");
  const idP = q.get("idProyecto");

  const [ idConcreto, setIdConcreto ] = React.useState('');
  const [ idProyecto, setIdProyecto ] = React.useState('');

  /* const utf8_to_b64 = ( str ) => {
    return window.btoa(unescape(encodeURIComponent( str )));
  } */
  
  const b64_to_utf8 = ( str ) => {
    return decodeURIComponent(escape(window.atob( str )));
  }

  React.useEffect(() => {
    try{
      setIdConcreto(b64_to_utf8(idC));
      setIdProyecto(b64_to_utf8(idP));
    } catch (e) {
      console.log(e)
    }
  }, [idC, idP])

  if(idConcreto === '' || idProyecto === '') return <NotFound />

  return (
    <>
      <helmet><meta name="viewport" content="maximum-scale=1"/></helmet>
      <div style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
        <iframe
          title='Reporte'
          src={`https://oestec.api.edesarrollos.info/pdf/reporte-final?idConcreto=${idConcreto}&idProyecto=${idProyecto}&html=1`}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </>
  )
};

export default MuestraQR