import React, { useState, useMemo } from 'react';
import { DefaultLayout } from '../../../../../components/layouts';
import { useModels, useQuery, useAuth } from '../../../../../hooks';
import { Form, Row, Col, InputNumber, Input, Button, Divider, Space, Modal, Radio, message } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx';
import StatusResponse from '../../../../../services/statusResponse';
import { ViewLoading } from '../../../../../components';
import { renderDateUTC } from '../../../../../utilities';

const PruebasCampo = () => {

    const [form] = Form.useForm();
    const { user } = useAuth();
    const q = useQuery();

    const idCon = q.get('idConcreto');
    const idEv = q.get('idEvento');
    const idP = q.get('idProyecto');

    const [saveLoading, setSaveLoading] = useState(false);
    const [fechaMasaValue, setFechaMasaValue] = useState('');
    const [fechaAireValue, setFechaAireValue] = useState('');
    const [fechaRevenimientoValue, setFechaRevenimientoValue] = useState('');
    const [fechaAguaValue, setFechaAguaValue] = useState('');
    const [fechaTemperaturaValue, setFechaTemperaturaValue] = useState('');

    const idConcretoMemo = useMemo(() => ({ id: idCon }), [idCon])
    const idConcretoPruebasMemo = useMemo(() => ({ idConcreto: idCon }), [idCon])
    const idEventoMemo = useMemo(() => ({ id: idEv }), [idEv])
    const idProyectoMemo = useMemo(() => ({ id: idP }), [idP])

    //Eventos
    const [
        eventos,
    ] = useModels({
        name: 'evento',
        ordenar: 'id-asc',
        limite: -1,
        extraParams: idEventoMemo
    });

    //Proyectos
    const [
        proyectos,
    ] = useModels({
        name: 'proyecto',
        ordenar: 'id-asc',
        limite: -1,
        extraParams: idProyectoMemo
    });

    //Concretos
    const [
        concretos,
    ] = useModels({
        name: 'concreto',
        ordenar: 'id-asc',
        limite: -1,
        extraParams: idConcretoMemo
    });

    //Pruebas
    const [
        models,
        modelsLoading,
        // eslint-disable-next-line no-unused-vars
        modelsError,
        // eslint-disable-next-line no-unused-vars
        modelsPage,
        refreshModels
    ] = useModels({
        name: 'pruebas-campo',
        ordenar: 'id-desc',
        limite: -1,
        extraParams: idConcretoPruebasMemo
    });

    const breadcrumb = [
        {
            name: "Proyectos",
            to: "/proyectos"
        },
        {
            name: proyectos[0]?.nombre,
            to: `/eventos?idProyecto=${idP}`
        },
        {
            name: eventos[0]?.titulo,
            to: `/eventos?idProyecto=${idP}`
        },
        {
            name: concretos[0]?.folio,
            to: `/eventos/muestras?idEvento=${idEv}&idProyecto=${idP}`
        },
        {
            name: 'Pruebas de Campo de ' + concretos[0]?.folio,
            to: `pruebas-de-campo?idConcreto=${idCon}&idEvento=${idEv}&idProyecto=${idP}`
        }
    ]

    const onFinish = async (values) => {
        try {
            setSaveLoading(true)

            let body = {
                ...values,
                idConcreto: concretos[0]?.id,
                idUsuarioMasa: user.id,
                idUsuarioTemperatura: user.id,
                idUsuariRevenimiento: user.id,
                idUsuarioAire: user.id,
                idUsuarioAgua: user.id,
                fechaMasa: fechaMasaValue ? fechaMasaValue : models[0]?.fechaMasa,
                fechaTemperatura: fechaTemperaturaValue ? fechaTemperaturaValue : models[0]?.fechaTemperatura,
                fechaAire: fechaAireValue ? fechaAireValue : models[0]?.fechaAire,
                fechaRevenimiento: fechaRevenimientoValue ? fechaRevenimientoValue : models[0]?.fechaRevenimiento,
                fechaAgua: fechaAguaValue ? fechaAguaValue : models[0]?.fechaAgua,
                tipo: 1
            }

            if (models.length > 0) {
                const prueba = models.find(prueba => prueba.id === models[0]?.id)
                body.id = prueba.id
            }

            const res = await StatusResponse.post('pruebas-campo', body)
            if (res?.status === 400 && res?.errores !== null) {
                const newArray = Object.values(res?.errores)
                Modal.error({
                    title: res?.mensaje,
                    content: (
                        <div>
                            {
                                newArray.map((m, i) =>
                                    <span key={(i + 1)}>- {m}</span>
                                )
                            }
                        </div>
                    )
                })
            }
            else if (res?.status === 400 && res?.errores === null) {
                Modal.error({
                    title: res?.mensaje
                })
            }
            else if (res?.status === 200) {
                refreshModels();
                message.success(res?.mensaje, 2)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setSaveLoading(false);
        }
    }

    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        message.warning({
            content: 'Verifica que todos los campos estén correctos',
            style: {
                marginTop: '10vh',
            },
        });
        console.log(values, errorFields, outOfDate);
    };

    React.useEffect(() => {
        if (models.length > 0) {
            form.setFieldsValue({
                masaUnitaria: models[0]?.masaUnitaria ? models[0]?.masaUnitaria : null,
                porcentajeAire: models[0]?.porcentajeAire ? models[0]?.porcentajeAire : null,
                revenimientoMedido: models[0]?.revenimientoMedido ? models[0]?.revenimientoMedido : null,
                acepto: models[0]?.acepto ? models[0]?.acepto : null,
                temperaturaAmbiente: models[0]?.temperaturaAmbiente ? models[0]?.temperaturaAmbiente : null,
                temperaturaMezcla: models[0]?.temperaturaMezcla ? models[0]?.temperaturaMezcla : null,
                aguaAgregado: models[0]?.aguaAgregado ? models[0]?.aguaAgregado : null,
                fechaMasa: models[0]?.fechaMasa ? renderDateUTC(models[0]?.fechaMasa) : null,
                fechaAire: models[0]?.fechaAire ? renderDateUTC(models[0]?.fechaAire) : null,
                fechaRevenimiento: models[0]?.fechaRevenimiento ? renderDateUTC(models[0]?.fechaRevenimiento) : null,
                fechaTemperatura: models[0]?.fechaTemperatura ? renderDateUTC(models[0]?.fechaTemperatura) : null,
                fechaAgua: models[0]?.fechaAgua ? renderDateUTC(models[0]?.fechaAgua) : null
            })
        }
        form.setFieldsValue({
            revenimientoDiseno: concretos[0]?.revenimientoDiseno,
            reveminientoDisenoMargen: concretos[0]?.reveminientoDisenoMargen
        })
    }, [concretos, form, models])

    if (modelsLoading) return <ViewLoading />

    return (
        <DefaultLayout
            breadcrumbItems={breadcrumb}
            title='Pruebas de Campo'
        >
            <>
                <Form
                    form={form}
                    name='form'
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={10}>
                        <Col span={6}>
                            <Form.Item
                                name='masaUnitaria'
                                label='Masa Unitaria (kg/cm³)'
                                extra="En caso de no ser solicitado escriba 'NM'"
                                rules={[
                                    {
                                        message: "Ingrese un número",
                                        pattern: "^(NM|([0-9]+.?[0-9]*))$",
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    onChange={() => {
                                        setFechaMasaValue(moment().format('YYYY-MM-DD HH:mm:ss'));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} />
                        <Col span={6} />
                        <Col span={6}>
                            <Form.Item name='fechaMasa' label='Fecha de Prueba'>
                                <Input bordered={false} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={10}>
                        <Col span={6}>
                            <Form.Item
                                name='porcentajeAire'
                                label='Porcentaje de Aire'
                                extra="En caso de no ser solicitado escriba 'NM'"
                                rules={[
                                    {
                                        message: "Ingrese un número",
                                        pattern: "^(NM|([0-9]+.?[0-9]*))$",
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                    onChange={() => {
                                        setFechaAireValue(moment().format('YYYY-MM-DD HH:mm:ss'));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} />
                        <Col span={6} />
                        <Col span={6}>
                            <Form.Item name='fechaAire' label='Fecha de Prueba'>
                                <Input bordered={false} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={10}>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 6 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item name="revenimientoDiseno" label="Revenimiento Diseño (cm)">
                                <InputNumber
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 6 }}
                            lg={{ span: 6 }}
                        >
                            <Space direction="horizontal">
                                +/-
                                <Form.Item name="reveminientoDisenoMargen" label="&nbsp;">
                                    <InputNumber
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Space>
                        </Col>
                        <Col span={6} />
                        <Col span={6}>
                            <Form.Item name='fechaRevenimiento' label='Fecha de Prueba'>
                                <Input bordered={false} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={10}>
                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 6 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item name="revenimientoMedido" label="Revenimiento Medido (cm)">
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={() => {
                                        setFechaRevenimientoValue(moment().format('YYYY-MM-DD'));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col>
                            <Form.Item
                                name='acepto'
                                label='¿El cliente aceptó?'
                            >
                                <Radio.Group>
                                    <Radio value={'SI'}>Sí</Radio>
                                    <Radio value={'NO'}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={10}>
                        <Col span={6}>
                            <Form.Item
                                name='aguaAgregado'
                                label='Agua Agregada (L)'
                            >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={() => {
                                        setFechaAguaValue(moment().format('YYYY-MM-DD HH:mm:ss'));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} />
                        <Col span={6} />
                        <Col span={6}>
                            <Form.Item name='fechaAgua' label='Fecha de Prueba'>
                                <Input bordered={false} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={10}>
                        <Col span={5}>
                            <Form.Item name='temperaturaAmbiente' label='Temperatura Ambiente (°C)'>
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name='temperaturaMezcla' label='Temperatura Medida (°C)'>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={() => {
                                        setFechaTemperaturaValue(moment().format('YYYY-MM-DD'));
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} />
                        <Col span={3} />
                        <Col span={6}>
                            <Form.Item name='fechaTemperatura' label='Fecha de Prueba'>
                                <Input bordered={false} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={4}>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    block
                                    size='large'
                                    htmlType='submit'
                                    loading={saveLoading}
                                >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        </DefaultLayout>
    )
}

export default PruebasCampo;