import React, {useState, useEffect, useCallback} from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { Form, Row, Col, Button, Input, Select, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useModel, useModels, useQuery } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';
import { ViewLoading } from '../../../components';

const VariablesForm = () => {

    const [ form ] = Form.useForm();
    const { Option } = Select;
    const history = useHistory();
    const q = useQuery();
    const id = q.get("id");
    const editing = !!id;
    const [saveLoading, setSaveLoading] = useState(false);

    //Variables
    const [
        variables,
        // eslint-disable-next-line no-unused-vars
        variablesLoading, variablesError, variablesPage, refreshVariables
    ] = useModels({
        name: 'variable',
        ordenar: 'id-asc',
        limite: -1,
    });
    
    const {
        model,
        modelLoading,
    } = useModel({
        name: "variable",
        id: id,
    })

    const breadcrumb = [
        {
            name: "Administración",
            to: "/administracion"
        },
        {
            name: "Variables",
            to: "/administracion/variables"
        },
        {
            name: editing ? model?.nombre : "Nueva Variable",
            to: editing ? `variable?id=${id}` : "nueva-variable"
        }
    ]

    const setFormData = useCallback((data) => {
        form.setFieldsValue({
            idPadre: data?.idPadre,
            valor: data?.valor,
            nombre: data?.nombre,
            descripcion: data?.descripcion,
            nombeclatura: data?.nombeclatura
        })
    },[form])

    const onFinish = async values => {

        try {
            setSaveLoading(true)

            let _body = {
                ...values
            }

            if(editing){
                _body.id = id
            }

            const res = await StatusResponse.post("variable", _body)
            if(res?.status === 400 && res?.errores !== null){
                const newArray = Object.values(res?.errores)
                Modal.error({
                    title: res?.mensaje,
                    content:(
                        <div>
                            {
                                newArray.map((m, i) =>
                                    <span key={(i + 1)}>- {m}</span>    
                                )
                            }
                        </div>
                    )
                })
            }
            else if(res?.status === 400 && res?.errores === null){
                Modal.error({
                    title: res?.mensaje
                })
            }
            else if(res?.status === 200){
                let secondsToGo = 2
                const modal = Modal.success({
                    title: res?.mensaje,
                    cancelButtonProps: {style: {display: 'none'}},
                    okButtonProps: {style: {display: 'none'}}
                })
                const timer = setInterval(() => {secondsToGo -= 1}, 100)
                setTimeout(() => {
                    clearInterval(timer)
                    modal.destroy()
                }, secondsToGo * 1000)
                history.push('/administracion/variables')
            }
        }catch(error){
            console.log(error)
        }finally{
            setSaveLoading(false)
        }
    };

    useEffect(() => {
        if(editing && model){
            setFormData(model)
        }
    }, [editing, model, setFormData]);

    if(modelLoading) return <ViewLoading />

    return (
        <>
        <DefaultLayout
            breadcrumbItems={breadcrumb}
            title={model ? "Editar Información de "+model?.nombre : "Nuevo Variable"}
        >
            <Form
                form={form}
                name="form"
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='nombre'
                            label='Nombre'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='valor'
                            label='Valor'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='descripcion'
                            label='Descripción'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='nombeclatura'
                            label='Nomeclatura'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                    >
                        <Form.Item
                            name='idPadre'
                            label='Variable Padre'
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            <Select
                                showSearch
                                aria-autocomplete='none'
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                <Option value={null}>Ninguna</Option>
                                {variables.map(item => (
                                    <Option key={item?.id} value={item?.id}>{item?.nombre}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col span={9}>
                        <Form.Item>
                            <Button
                                type="primary"
                                block
                                size="large"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DefaultLayout>
        </>
    );
}

export default VariablesForm;