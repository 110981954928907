import React, { useState } from 'react';
import { SimpleTableLayout } from '../../components/layouts';
import { Form, Descriptions, Modal, Table } from 'antd';
import 'moment/locale/es-mx';
import { ActionsButton } from '../../components';
import { useHistory } from 'react-router-dom';
import { useModels, usePagination, useSortColumns } from '../../hooks';
import { area } from '../../utilities';
import moment from "moment";
import { Search } from '../../utilities';

const ProyectosCliente = () => {

  const history = useHistory();
  const [searchForm] = Form.useForm();
  const { configPagination, setTotal, limite, setLimite, pagina } = usePagination();
  const [extraParams, setExtraParams] = useState({});
  const [ columns, setColumns ] = useState([]);

  const { ordenarValue, columnas } = useSortColumns({columns, ordenar: 'id-desc'});

  const requestParams = React.useMemo(() => {
    if (pagina === null) {
      return {}
    } else {
      return {
        name: 'proyecto',
        ordenar: ordenarValue,
        limite: limite,
        pagina: pagina,
        expand: 'usuarios,cliente,plaza',
        extraParams: extraParams
      }
    }
  }, [extraParams, limite, ordenarValue, pagina])

  const [request, setRequest] = React.useState({});

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    // eslint-disable-next-line no-unused-vars
    refreshModels
  ] = useModels(request);

  const filterArea = (id, row) => {
    return area?.filter(ai => ai?.id === id)[0]?.name
  }

  const breadcrumb = [
    {
      name: "Proyectos",
      to: "/proyectos"
    },
  ]

  const columnsConten = React.useMemo(() => [
    {
      title: 'Acciones',
      key: 'id',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      render: (_, item) => {
        return (
          <ActionsButton
            key={item}
            options={[
              {
                name: 'Ver detalle',
                onClick: () => modalInfo(item)
              },
              {
                name: 'Muestreos',
                onClick: () => {
                  history.push(`proyectos/muestras?idProyecto=${item?.id}`)
                }
              }
            ]}
          />
        );
      }
    },
    {
      title: 'Folio',
      key: 'folio',
      dataIndex: 'folio'
    },
    {
      title: 'Nombre del Proyecto',
      key: 'nombre',
      dataIndex: 'nombre'
    },
    {
      title: 'Cliente',
      key: 'cliente',
      dataIndex: 'cliente',
      render: (value) => value.nombre
    },
    {
      title: 'Plaza',
      key: 'plaza',
      dataIndex: 'plaza',
      render: (value) => value.titulo
    },
    {
      title: 'Área',
      key: 'area',
      dataIndex: 'area',
      render: (_, row) => (
        filterArea(row.area, row)
      )
    },
  ], [history]);

  const ContentModal = ({ row }) => {
    return (
      <div>
        <Descriptions
          bordered
          column={1}
          size="small"
          title={row?.nombre + ' ' + row?.folio}
        >
          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Dirección">{row?.direccion}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Fecha Inicio">{moment.utc(row?.fechaInicio).local().format("DD-MM-YYYY")}</Descriptions.Item>
          <Descriptions.Item labelStyle={{ fontWeight: "bold" }} label="Fecha Fin">{moment.utc(row?.fechaFin).local().format("DD-MM-YYYY")}</Descriptions.Item>
        </Descriptions>
      </div>
    )
  }

  const modalInfo = (v) => {
    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v} />,
      okText: "Ok",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { primary: true },
      closable: true
    });
  };

  const onSearch = async (search) => {

    const { buscar } = searchForm.getFieldsValue(true);
    let params = {};
    
    if (buscar){
      params.q = buscar;
    }

    setExtraParams(params);
  };

  const onClear = () => {
    searchForm.resetFields();
    setLimite(10);
    onSearch();
  }

  React.useEffect(() => {
    setColumns(columnsConten)
  }, [columnsConten])

  React.useEffect(() => {
    setRequest(requestParams)
    return () => setRequest({})
  }, [requestParams])

  React.useEffect(() => {
    if (modelsPage) {
      setTotal(modelsPage.total);
      setLimite(modelsPage.limite);
    }
  }, [modelsPage, setLimite, setTotal])

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumb}
      title="Proyectos"
      withSearchButton={false}
      children={
        <>
          <Search
            disabledButtons={modelsLoading}
            form={searchForm}
            loading={modelsLoading}
            onClean={onClear}
            onSearch={onSearch}
          />
          <Table
            columns={columnas}
            dataSource={models}
            loading={modelsLoading}
            pagination={configPagination}
            rowKey='id'
            scroll={{ x: 'max-content' }}
            size="small"
          />
        </>
      }
    />
  );
}

export default ProyectosCliente;