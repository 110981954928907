import DefaultLayout from "./DefaultLayout";
import { Input, ConfigProvider, Empty, Col, Row, Button } from "antd";
import {PlusOutlined} from '@ant-design/icons'

const { Search } = Input;

const SimpleTableLayout = ({
  breadcrumbItems,
  buttonData,
  title,
  searchPlaceholder = "Búsqueda",
  searchLoading,
  onSearchClicked,
  children,
  emptyText = "Aún no hay registros.",
  withSearchButton = true,
  searchProps,
}) => {
  return (
    <DefaultLayout
      breadcrumbItems={breadcrumbItems}
      title={title}
    >
      {withSearchButton && (
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 10 }}
            lg={{ span: 8 }}
            xxl={{ span: 6 }}
          >
            {Boolean(buttonData) && (
              <Button
                icon={<PlusOutlined />}
                onClick={buttonData.to ? buttonData.to : undefined}
                type='primary'
                size='large'
              >
                {buttonData.text}
              </Button>
            )}
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 9, offset: 7 }}
            lg={{ span: 7, offset: 9 }}
            xxl={{ span: 5, offset: 11 }}
          >
            <Search
              placeholder={searchPlaceholder}
              enterButton="Buscar"
              size="large"
              style={{ width: "100%", marginBottom: 10 }}
              loading={searchLoading}
              onSearch={onSearchClicked}
              {...searchProps}
            />
          </Col>
        </Row>
      )}

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            style={{ height: 300, paddingTop: "5%" }}
            description={emptyText}
          />
        )}
      >
        {children}
      </ConfigProvider>
    </DefaultLayout>
  );
};

export default SimpleTableLayout;